import React from 'react';
import { Result, Button } from 'antd';

const AccessDeniedPage = () => {
    return (
        <Result
            status="403"
            title="403"
            subTitle="Sorry, you don't have access to this page."
            extra={
                <Button type="primary" href="/">
                    Back Hub
                </Button>
            }
        />
    );
};

export default AccessDeniedPage;
