import { Button, Form, Select, Upload, message, Table, Card, Spin, Modal, Result, Tag, Divider } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import Editor from '../../components/Editor/Editor';
import "react-quill/dist/quill.snow.css";
import { DeleteOutlined, EditOutlined, PlusOutlined, CaretDownOutlined, CheckCircleTwoTone, SaveOutlined } from '@ant-design/icons';
import { useParams } from 'react-router';
import ServiceUsers from '../../services/users';
import ServiceComments from '../../services/comments';
import ServiceFiles from '../../services/files';
import ServiceTickets from '../../services/tickets';
import ServiceActivities from '../../services/activities';
import ServiceWorklogs from '../../services/worklogs'
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from "react-responsive";
import CommentBox from '../../components/CommentBox/CommentBox';
import { Scrollbar } from 'react-scrollbars-custom';
import { useUser } from '../../UserContext';
import Tooltip from 'antd/lib/tooltip';
import { DateTime } from 'luxon';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Dot from "../../assets/rec.png";
import Correct from "../../assets/correct.png";
import Timer from "../../assets/timer.png";
import TaskDetails from '../../components/TaskDetails/TaskDetails';
import { useCallback } from 'react';
import WeekSwitcher from '../../components/WeekSwitcher/WeekSwitcher';
import moment from 'moment';
import CreateModal from '../../components/CreateModal/CreateModal';
import './ShowTicket.css'
import EditTaskModal from '../../components/EditTaskModal/EditTaskModal';
import { v4 as uuidv4 } from 'uuid';

const { Option } = Select;

const statusOptions = [
    { value: "Draft", label: "Draft" },
    { value: "To Do", label: "To Do" },
    { value: "In Progress", label: "In Progress" },
    { value: "In QA", label: "In QA" },
    { value: "In Deployment", label: "In Deployment" },
    { value: "In Review", label: "In Review" },
    { value: "On Hold", label: "On Hold" },
    { value: "Canceled", label: "Canceled" },
    { value: "Done", label: "Done" }
];

const TaskPage = () => {

    const { user } = useUser();
    const { key } = useParams();
    const [task, setTask] = useState({});
    const [activityAssignments, setActivityAssignments] = useState();
    const [credentials, setCredentials] = useState('');
    const [summary, setSummary] = useState('');
    const [documents, setDocuments] = useState('');
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});
    const [fileList, setFileList] = useState([]);
    const [data, setData] = useState([]);
    const [users, setUsers] = useState([]);
    const [isEditorModified, setIsEditorModified] = useState(false);
    const [comments, setComments] = useState([]);
    const navigate = useNavigate();
    const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
    const [showToolbar, setShowToolbar] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isTaskExist, setIsTaskExist] = useState(true);
    const [isUserAssigned, setIsUserAssigned] = useState(true);
    const [timelineLoading, setTimelineLoading] = useState(false);
    const [finishLoading, setFinishLoading] = useState(false);
    const [backLoading, setBackLoading] = useState(false);
    const [returnLoading, setReturnLoading] = useState(false);
    const [isTimelineCompleted, setIsTimelineCompleted] = useState();
    const [height, setHeight] = useState();
    const elementRef = useCallback(node => {
        if (node !== null) {
            console.log('height', node.offsetHeight);
            setHeight(node.offsetHeight)
        }
    }, []);
    const [week, setWeek] = useState([]);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [worklogs, setWorklogs] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [isTempoLoading, setIsTempoLoading] = useState(false);
    const [first, setFirst] = useState();
    const [activeUser, setActiveUser] = useState();
    const [showEditTaskModal, setShowEditTaskModal] = useState(false);

    const grid = 8;

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver && "#D7E9FA",
        borderRadius: "6px",
        padding: grid,
        width: 500
    });

    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: "none",
        margin: `0 0 ${grid}px 0`,

        background: isDragging && "#F8F8F8",

        // styles we need to apply on draggables
        ...draggableStyle
    });

    useEffect(() => {
        if (!user) {
            return;
        }

        const fetchData = async () => {
            try {
                const response = await ServiceTickets.getTask(key);
                console.log('response', response.data);
                if (response.status === 200) {
                    document.title = response.data.task.summary;
                    setTask(response.data.task);
                    setActivityAssignments(response.data.activity_assignments);
                    console.log('assignments', response.data.activity_assignments);
                    setFirst(response.data.activity_assignments.filter(assignemt => assignemt.order === 0)[0].user_id);
                    const activeAssignments = response.data.activity_assignments.filter(assignemt => assignemt.is_active === true);
                    if (activeAssignments.length > 0) {
                        setActiveUser(activeAssignments[0].user_id);
                    }
                    setCredentials(response.data.credentials_description);
                    setSummary(response.data.summary_description);
                    setDocuments(response.data.documents_description);
                    setIsTimelineCompleted(response.data.activity_assignments.every(assignment => assignment.is_completed === true));
                }
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 404:
                            setIsTaskExist(false);
                            break;
                        case 403:
                            setIsUserAssigned(false);
                            break;
                        default:
                            console.error(error);
                    }
                    setIsLoading(false);
                } else {
                    console.error(error);
                }
            }

        };

        const fetchUsers = async () => {
            try {
                const response = await ServiceUsers.getAllUsers();
                setUsers(response.data);
            } catch (error) {
                console.error(error);
            }
        };

        const fetchComments = async () => {
            try {
                const comments = await ServiceComments.getAllComments(key, false);
                setComments(comments.data);
            } catch (error) {
                console.error(error);
            }
        }

        const fetchFiles = async () => {
            try {
                const files = await ServiceFiles.getTaskFiles(key);
                const formattedFiles = files.data.map(file => ({
                    key: file.id,
                    name: file.file_name,
                    size: countFileSize(file),
                    dateAdded: new Date(file.created_at).toLocaleString(),
                }));
                setData(formattedFiles);
                console.log('files', files);
            } catch (error) {
                console.error(error);
            }
        }

        const fetchWorklogs = async () => {
            try {
                const response = await ServiceWorklogs.getTaskWorklogs(key);
                setWorklogs(response.data);
                console.log('worklogs', response.data);
            } catch (error) {
                console.error('Error fetching worklogs:', error);
            }
        }

        const fetchTasks = async () => {
            try {
                const response = await ServiceTickets.getUserTasks();
                setTasks(response.data);
            } catch (error) {
                console.error(error);
            }
        };

        const fetchAll = async () => {
            await fetchData();
            await fetchUsers();
            await fetchComments();
            await fetchFiles();
            await fetchWorklogs()
            setIsLoading(false);
        }

        fetchTasks();
        fetchAll();

    }, [user]);

    const countFileSize = (file) => {
        let fileSize = file.size;
        let sizeString = fileSize + 'B';

        if (fileSize >= 1024) {
            fileSize = (fileSize / (1024 * 1024)).toFixed(2);
            sizeString = fileSize + 'Mb';
        }
        return sizeString;
    }

    const handleCredentialsChange = (value) => {
        setCredentials(value);
    };

    const handleSummaryChange = (value) => {
        setSummary(value);
    };

    const handleDocumentsChange = (value) => {
        setDocuments(value);
    };

    const handleChange = (pagination, filters, sorter) => {
        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };

    const actionButtons = (text, record) => (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <a href={`${process.env.REACT_APP_BACKEND_URL}/api/files/${record.name}`} download>
                    Download
                </a>
                {!(user.role_id === 1) &&
                    <Button type="text" size='small' icon={<DeleteOutlined />} onClick={() => handleRemoveFile(record)}></Button>
                }
            </div>
        </>
    );

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            filteredValue: filteredInfo.name || null,
            onFilter: (value, record) => record.name.toString() === value,
            sorter: (a, b) => a.name.localeCompare(b.name),
            sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
            ellipsis: true,
            width: isMobile ? 60 : 80
        },
        {
            title: "Size",
            dataIndex: "size",
            key: "size",
            width: 40
        },
        {
            title: "Date Added",
            dataIndex: "dateAdded",
            key: "dateAdded",
            width: 70
        },
        {
            dataIndex: '',
            key: 'x',
            render: actionButtons,
            width: 40
        },
    ];

    const handleBeforeUpload = (file) => {
        console.log('')
        const formData = new FormData();
        let fileSize = file.size;
        let sizeString = fileSize + 'B';

        if (fileSize >= 1024) {
            fileSize = (fileSize / (1024 * 1024)).toFixed(2);
            sizeString = fileSize + 'Mb';
        }
        formData.append('file', file);
        formData.append('user_id', user.id);
        formData.append('task_key', key);
        ServiceFiles.addFile(formData)
            .then((response) => {
                const newFile = {
                    key: uuidv4(),
                    name: response.data[0].file_name,
                    size: sizeString,
                    dateAdded: new Date().toLocaleString(),
                    uid: file.uid
                };
                setData((prevData) => [...prevData, newFile]);
                console.log('data', [...data, newFile]);
                setFileList((prevList) => [...prevList, file]);
                message.success('File uploaded successfully!');
            })
            .catch((error) => {
                message.error('Failed to upload file.');
            });
        return false;
    };

    const handleRemoveFile = (file) => {
        console.log('qq', file);
        ServiceFiles.deleteFile(file.name)
            .then((response) => {
                message.success('File deleted successfully!');
                const newData = data.filter((d) => d.key !== file.key);
                console.log('newData', newData);
                setData(newData);
            })
            .catch((error) => {
                message.error('Failed to delete file.');
            });
    };
    ;

    const uploadProps = {
        multiple: true,
        listType: 'picture',
        beforeUpload: handleBeforeUpload,
        onRemove: handleRemoveFile
    };

    const deleteTaskHandler = async () => {
        Modal.confirm({
            title: 'Confirm deletion',
            content: 'By clicking OK, you confirm the deletion of the task and everything related to it.',
            onOk() {
                ServiceTickets.deleteTask(key)
                    .then((response) => {
                        if (response.data) {
                            message.success(response.data.message);
                            navigate('/');
                        }
                    })
            },
            centered: true
        });
    }

    const editorHandler = () => {
        setIsEditorModified(false);
        setShowToolbar('');
        const updatedTask = {
            ...task,
            credentials_description: credentials,
            summary_description: summary,
            documents_description: documents
        }
        ServiceTickets.updateTask(updatedTask)
            .then((response) => {
                console.log('res', response);
                if (response.data) {
                    message.success('Description was updated successfully');
                } else {
                    message.error('Error updating');
                }
            })
    }

    const editTaskHandler = () => {
        setShowEditTaskModal(true);
    }

    const cancelTaskHandler = () => {
        setShowEditTaskModal(false);
    }

    const taskChangeHandler = (updatedTask) => {
        setTask(updatedTask);
    }

    const taskAssignmentsChangeHandler = (assignments) => {
        setActivityAssignments(assignments);
    }

    const cancelHandler = () => {
        setIsEditorModified(false);
        setShowToolbar('');
    }

    const handleContainerClick = () => {
        setShowToolbar('ShowToolbar');
        setIsEditorModified(true);
    };

    const launchTimeline = async () => {
        setTimelineLoading(true);
        const updatedActivity = (await ServiceTickets.launchTimeline(task.key)).data;
        if (updatedActivity.activity) {
            message.success('Timeline is active.');
        }
        const updatedActivities = activityAssignments.map(activity => {
            if (activity.id === updatedActivity.activity.id) {
                setActiveUser(updatedActivity.activity.user_id);
                return { ...activity, is_active: updatedActivity.activity.is_active };
            }
            return activity;
        });
        setActivityAssignments(updatedActivities);
        setTimelineLoading(false);
        setTask({ ...task, status: updatedActivity.status });
    }

    const finishActivity = async (activity) => {
        setFinishLoading(true);

        // Mark the current activity as completed
        const updatedCurrentActivity = {
            ...activity,
            is_active: false,
            is_completed: true,
            is_back: false
        };

        try {
            // Update the current activity and get the result
            const result = await ServiceActivities.updateActivity(updatedCurrentActivity);

            // Create a copy of activity assignments
            let updatedActivities = [...activityAssignments];

            if (result.data.status !== 'Done') {
                // If there is a next activity, update the activities list
                const nextActivity = result.data.activity;
                updatedActivities = updatedActivities.map(record => {
                    if (record.id === nextActivity.id) {
                        setActiveUser(nextActivity.user_id);
                        return nextActivity;
                    } else if (record.id === activity.id) {
                        return updatedCurrentActivity;
                    } else {
                        return record;
                    }
                });
            } else {
                // If the status is 'Completed', mark the current activity as completed
                updatedActivities = updatedActivities.map(record => {
                    if (record.id === activity.id) {
                        return updatedCurrentActivity;
                    }
                    return record;
                });
                setTask({ ...task, status: 'Done' });
                setIsTimelineCompleted(true);
                message.success('Timeline is completed.');
            }

            // Set the updated activities and finish loading
            setActivityAssignments(updatedActivities);
            setTask({ ...task, status: result.data.status });
        } catch (error) {
            console.error('Error updating activity:', error);
        } finally {
            setFinishLoading(false);
        }
    };

    const backActivity = async (activity) => {
        setBackLoading(true);

        const updatedCurrentActivity = {
            ...activity,
            is_active: false,
            is_back: true
        };

        try {
            const result = await ServiceActivities.updateActivity(updatedCurrentActivity);

            let updatedActivities = [...activityAssignments];

            if (result.data.status !== 0) {
                const prevActivity = result.data.activity;
                updatedActivities = updatedActivities.map(record => {
                    if (record.id === prevActivity.id) {
                        setActiveUser(prevActivity.user_id);
                        return prevActivity;
                    } else if (record.id === activity.id) {
                        return updatedCurrentActivity;
                    } else {
                        return record;
                    }
                });
            } else {
                updatedActivities = updatedActivities.map(record => {
                    if (record.id === activity.id) {
                        return updatedCurrentActivity;
                    }
                    return record;
                });
            }

            if (result.data.status !== 0) {
                setActivityAssignments(updatedActivities);
                setTask({ ...task, status: result.data.status });
            }
        } catch (error) {
            console.error('Error updating activity:', error);
        } finally {
            setBackLoading(false);
        }
    };

    const returnToTheLast = async () => {
        setReturnLoading(true);

        try {
            const lastActivity = activityAssignments[activityAssignments.length - 1];
            console.log('last', lastActivity);
            if (lastActivity) {
                // Mark the current activity as not active
                const updatedLastActivity = {
                    ...lastActivity,
                    is_active: true,
                    is_completed: false,
                };

                // Update the current activity and get the result
                const result = await ServiceActivities.toLast(lastActivity);

                let updatedActivities = [...activityAssignments];

                // Update the activities list
                updatedActivities = updatedActivities.map(record => {
                    if (record.id === lastActivity.id) {
                        setActiveUser(lastActivity.user_id);
                        return updatedLastActivity;
                    } else { return record };
                })

                // Set the updated activities and finish loading
                setActivityAssignments(updatedActivities);
                setIsTimelineCompleted(false);
                setTask({ ...task, status: result.data.status });
            }
        } catch (error) {
            console.error('Error returning to the last activity:', error);
        } finally {
            setReturnLoading(false);
        }
    };

    const handleData = (childData) => {
        setWeek(childData);
    };

    function openCreateModal(date) {
        setShowCreateModal(true);;
    }

    function isOpenCreateModalHandler(isOpen) {
        setShowCreateModal(isOpen);
    }

    function addLog(log) {
        setIsTempoLoading(true)
        ServiceWorklogs.addLog(log)
            .then((response) => {
                setWorklogs(prevWorklog => [...prevWorklog, response.data.worklog]);
            })
            .finally(() => {
                setIsTempoLoading(false)
            })
    }

    if (isLoading) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <Spin size="large" />
            </div>
        );
    }

    if (!isTaskExist) {
        return (
            <Result
                status="404"
                title="404"
                subTitle="Sorry, the task not found."
                extra={
                    <Button type="primary" href="/">
                        Go to Hub
                    </Button>
                }
            />
        )
    }

    if (!isUserAssigned) {
        return (
            <Result
                status="403"
                title="403"
                subTitle="Sorry, you don't have access to this page."
                extra={
                    <Button type="primary" href="/">
                        Go to Hub
                    </Button>
                }
            />
        )
    }

    return (
        <>
            <div style={{ overflowY: 'hidden' }}>

                <div className='taskData'>
                    <Scrollbar style={{ height: 'calc(100vh - 100px)', minWidth: '700px' }}>
                        <div className='leftSide'>
                            <div className='taskHeader'>
                                <div className='taskName'>
                                    <Tooltip title={task.summary}>
                                        <div>{task.summary.length > 40 ? task.summary.slice(0, 40) + '...' : task.summary}</div>
                                    </Tooltip>
                                </div>
                                <div>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ display: 'flex' }}>
                                            {user.role_id !== 1 && (
                                                <div style={{ display: 'flex', justifyContent: 'space-between', gap: '4px' }}>
                                                    {isEditorModified && ((user.role_id === 2 && user.id === task.administrator) || user.role_id === 3) && <Button onClick={cancelHandler}>Cancel</Button>}
                                                    {isEditorModified && ((user.role_id === 2 && user.id === task.administrator) || user.role_id === 3) && <Button onClick={editorHandler} icon={<SaveOutlined />}>Save</Button>}
                                                    {((user.role_id === 2 && user.id === task.administrator) || user.role_id === 3) && <Button icon={<EditOutlined />} onClick={editTaskHandler}></Button>}
                                                    {user.role_id === 3 && <Button onClick={deleteTaskHandler} icon={<DeleteOutlined />}></Button>}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ margin: '8px 0 8px 0' }}>
                                <TaskDetails
                                    task={task}
                                />
                            </div>
                            <div>
                                <div
                                    className={`buildEditor${showToolbar}`}
                                    onClick={user.role_id === 3 || user.role_id === 2 ? handleContainerClick : null}
                                >
                                    <Editor
                                        isLoading={isLoading}
                                        fileList={fileList}
                                        credentials={task.credentials_description}
                                        summary={task.summary_description}
                                        documents={task.documents_description}
                                        onCredentialsChange={handleCredentialsChange}
                                        onSummaryChange={handleSummaryChange}
                                        onDocumentsChange={handleDocumentsChange}
                                        user={user}
                                    />
                                </div>

                            </div>
                            <div className='filesTable'>
                                <div className='filesTableHeader'>
                                    <div className='attLabel'>Attachments</div>
                                    {
                                        <div className='uploader'>
                                            <Upload {...uploadProps} showUploadList={false}>
                                                <Button size='small' icon={<PlusOutlined />}></Button>
                                            </Upload>
                                        </div>
                                    }
                                </div>
                                <div className='tableWithFiles'>
                                    <Table dataSource={data} columns={columns} onChange={handleChange} size="small" />
                                </div>
                            </div>
                            <div className='tempo'>
                                <div className='tempoLabel'>Tempo</div>
                                <div className='tempoHeader'>
                                    <div>
                                        <WeekSwitcher onData={handleData} />
                                    </div>
                                    <div>
                                        {task.status !== 'Done' ? (
                                            tasks && tasks.length > 0 ? (
                                                <Button onClick={() => openCreateModal()}>Log Time</Button>
                                            ) : (
                                                <div>No tasks to log</div>
                                            ))
                                            :
                                            <div>This task is done</div>
                                        }
                                    </div>
                                </div>
                                <Divider></Divider>
                                <Spin tip="Loading" spinning={isTempoLoading}>
                                    <div>
                                        {week.map((day, index) => {
                                            const logsForDay = worklogs.filter(log => moment(day.date).isSame(moment(log.created_at), 'date'));
                                            const hasLogsForDay = logsForDay.length > 0;
                                            return (
                                                <div key={index}>
                                                    {hasLogsForDay && <div style={{ fontWeight: '500', margin: '8px 0 8px 0' }}>{day.date}</div>}
                                                    {worklogs.filter((log => { return moment(day.date).isSame(moment(log.created_at), 'date') })).map((log, logIndex) => (

                                                        <div className='logRow' key={logIndex}>
                                                            <div className='logLeftPart'>
                                                                <div className='logIcon'>
                                                                    <CheckCircleTwoTone twoToneColor="#52c41a" />
                                                                </div>
                                                                <div className='logDesc'>{log.comment}</div>
                                                            </div>
                                                            <div style={{ fontStyle: 'italic' }}>{log.label}</div>
                                                            <div className='logRightPart'>
                                                                <div style={{ fontWeight: '500' }}>{log.name}</div>

                                                                <div>{log.time_spent_hours}h</div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </Spin>
                            </div>
                            <div style={{ marginTop: '16px' }}>
                                <CommentBox
                                    user={user}
                                    users={users}
                                    isBuild={false}
                                    id={task.key}
                                    comments={comments}
                                    administrator={task.administrator}
                                    summary={task.summary}
                                />
                            </div>
                        </div>
                    </Scrollbar>
                    <div style={{ marginLeft: '16px', minWidth: '580px' }}>
                        <div>
                            <div style={{ fontSize: '18px', marginBottom: '6px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div>
                                    Timeline
                                </div>
                                {activityAssignments && !(activityAssignments.some((assignemt) => assignemt.is_active === true)) && !(activityAssignments.some((assignemt) => assignemt.is_completed === true)) &&
                                    <div>
                                        {
                                            (user.id === first || user.role_id === 3) ? (
                                                <Button
                                                    size="small"
                                                    style={{ marginRight: '16px' }}
                                                    onClick={launchTimeline}
                                                    loading={timelineLoading}
                                                >
                                                    Launch
                                                </Button>
                                            ) : (user.role_id === 2 && task.administrator === user.id) ? (
                                                <Button
                                                    size="small"
                                                    style={{ marginRight: '16px' }}
                                                    onClick={launchTimeline}
                                                    loading={timelineLoading}
                                                >
                                                    Launch
                                                </Button>
                                            ) : null
                                        }
                                    </div>
                                }
                                {
                                    isTimelineCompleted &&
                                    <div style={{ display: 'flex', gap: '8px' }}>
                                        <div>
                                            <Button size='small' onClick={returnToTheLast}>
                                                Return to the last one
                                            </Button>
                                        </div>
                                        <div>
                                            <Tag color="#87d068">Done</Tag>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div style={{ fontSize: '12px', marginBottom: '6px' }}>Hint: shows a remaining estimate for each activity</div>
                            <Spin tip="Loading" spinning={timelineLoading || finishLoading || backLoading || returnLoading}>

                                <Scrollbar style={{ height: 'calc(92vh - 100px)' }}>
                                    <div ref={elementRef}>
                                        <DragDropContext>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <div style={{ marginRight: '8px' }}>
                                                    {activityAssignments && activityAssignments.map((activity, index) => (
                                                        <div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                            {activity.is_completed ? (
                                                                <img style={{ width: '16px' }} src={Correct} alt='Checked' />
                                                            ) : activity.is_active ?
                                                                <img style={{ width: '20px' }} src={Timer} alt={index} /> :
                                                                <img style={{ width: '14px' }} src={Dot} alt={index} />
                                                            }
                                                            {index !== activityAssignments.length - 1 && (
                                                                <div
                                                                    style={{
                                                                        width: '1px',
                                                                        height: height / activityAssignments.length - 24,
                                                                        backgroundColor: '#DCDCDC',
                                                                    }}
                                                                />
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>

                                                <Droppable droppableId="activityAssignments" className="activityAssignments" style={{ flex: 1 }} >
                                                    {(provided, snapshot) => (
                                                        <div ref={provided.innerRef} {...provided.droppableProps} style={getListStyle(snapshot.isDraggingOver)}>
                                                            {activityAssignments.map((assignment, index) => (
                                                                <Draggable
                                                                    isDragDisabled={true}
                                                                    key={assignment.id}
                                                                    draggableId={String(assignment.id)}
                                                                    index={index}
                                                                >
                                                                    {(provided, snapshot) => (
                                                                        <Card
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            ref={provided.innerRef}
                                                                            style={{
                                                                                ...getItemStyle(snapshot.isDragging, provided.draggableProps.style),
                                                                            }}
                                                                        >
                                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                <div style={{ fontSize: '14px', fontWeight: '600' }}>{assignment.name}</div>
                                                                                {assignment.is_mute && !assignment.is_active && <Tag>Muted</Tag>}
                                                                                {assignment.is_active && (user.id === activeUser || user.role_id === 3) ?
                                                                                    (
                                                                                        <div>
                                                                                            {assignment.is_mute && <Tag>Muted</Tag>}
                                                                                            <Button
                                                                                                size='small'
                                                                                                onClick={() => finishActivity(assignment)}
                                                                                                loading={finishLoading}
                                                                                            >
                                                                                                Complete
                                                                                            </Button>
                                                                                            {assignment.order !== 0 &&
                                                                                                <Button
                                                                                                    style={{ marginLeft: '8px' }}
                                                                                                    size='small'
                                                                                                    onClick={() => backActivity(assignment)}
                                                                                                    loading={backLoading}
                                                                                                >
                                                                                                    Reject
                                                                                                </Button>
                                                                                            }
                                                                                        </div>
                                                                                    ) : (assignment.is_active && user.role_id === 2 && task.administrator === user.id) ? (
                                                                                        <div>
                                                                                            <Button
                                                                                                size='small'
                                                                                                onClick={() => finishActivity(assignment)}
                                                                                                loading={finishLoading}
                                                                                            >
                                                                                                Finish
                                                                                            </Button>
                                                                                            {assignment.order !== 0 &&
                                                                                                <Button
                                                                                                    style={{ marginLeft: '8px' }}
                                                                                                    size='small'
                                                                                                    onClick={() => backActivity(assignment)}
                                                                                                    loading={backLoading}
                                                                                                >
                                                                                                    Back
                                                                                                </Button>
                                                                                            }
                                                                                        </div>
                                                                                    ) : null
                                                                                }
                                                                            </div>
                                                                            <div>
                                                                                <div style={{ fontWeight: '500', marginRight: '8px' }}>{assignment.display_name} (<span style={{ color: 'rgb(0 127 255)', }}>{assignment.role_name}</span>)</div>
                                                                            </div>
                                                                            <div>
                                                                                <span style={{ color: assignment.remaining_estimate > 0 ? 'green' : 'red', fontWeight: '500' }}>{assignment.remaining_estimate}</span> out of <span style={{ fontWeight: '500' }}>{assignment.estimate}</span> hours
                                                                            </div>
                                                                            <Tooltip title={assignment.summary}>
                                                                                <div
                                                                                    style={{
                                                                                        whiteSpace: 'nowrap',
                                                                                        overflow: 'hidden',
                                                                                        textOverflow: 'ellipsis',
                                                                                        maxWidth: '100%',
                                                                                    }}
                                                                                >
                                                                                    {assignment.summary}
                                                                                </div>
                                                                            </Tooltip>
                                                                        </Card>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </div>
                                        </DragDropContext>
                                    </div>
                                </Scrollbar>
                            </Spin>
                        </div>
                    </div>
                </div>
            </div>
            {tasks && tasks.length > 0 &&
                <CreateModal
                    isOpen={showCreateModal}
                    isOpenChange={isOpenCreateModalHandler}
                    tasks={tasks}
                    taskKey={task.key}
                    date={new Date().toISOString()}
                    onData={addLog}
                />
            }
            <EditTaskModal
                isOpen={showEditTaskModal}
                task={task}
                users={users}
                handleEditTaskModalChange={cancelTaskHandler}
                assignments={activityAssignments}
                onTaskChange={taskChangeHandler}
                onAssignmentsChange={taskAssignmentsChangeHandler}
            />
        </>
    );
};

export default TaskPage;