import React, { useEffect, useState } from 'react';
import ServiceUsers from '../../services/users';
import { Spin, Statistic } from 'antd';


const Home = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [activeSessions, setActiveSessions] = useState(0);

    useEffect(() => {
        document.title = 'Hub';
        ServiceUsers.getActiveSessions()
            .then(response => {
                setActiveSessions(response.data);
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [])

    if (isLoading) {
        return <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
            }}
        >
            <Spin size="large" />
        </div>;
    }

    return (
        <div style={{ height: '92vh', overflowY: 'auto', overflowX: 'hidden' }}>
            <div style={{ margin: '16px' }}>
                <Statistic title="Active Sessions" value={activeSessions} />
            </div>
        </div>
    );
}

export default Home;