// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Anuphan:wght@300;400;500&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ant-menu-item:hover a {\n    text-decoration: none !important;\n}\n\n@media screen and (max-width: 768px) {\n    .rightSide {\n        display: none;\n    }\n}\n\n.hover-effect:hover {\n    transform: translateY(-1.5px);\n}", "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI;QACI,aAAa;IACjB;AACJ;;AAEA;IACI,6BAA6B;AACjC","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Anuphan:wght@300;400;500&display=swap');\n\n.ant-menu-item:hover a {\n    text-decoration: none !important;\n}\n\n@media screen and (max-width: 768px) {\n    .rightSide {\n        display: none;\n    }\n}\n\n.hover-effect:hover {\n    transform: translateY(-1.5px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
