import axiosInstance from "../config/axios";

class ServiceWorklogs {

  async addLog(log) {
    return await axiosInstance.post('/worklogs', log);
  }

  async getAllLogs() {
    return await axiosInstance.get('/worklogs/');
  }

  async getWorklogsForDate(date) {
    return await axiosInstance.get(`/worklogs/date/${date}`);
  }

  async getUserWorklogs(id) {
    return await axiosInstance.get(`/worklogs/user/${id}`);
  }

  async getTaskWorklogs(key) {
    return await axiosInstance.get(`/worklogs/${key}/task`);
  }

  async getAllTaskWorklogs(key) {
    return await axiosInstance.get(`/worklogs/${key}/all`);
  }

  async updateLog(log) {
    return await axiosInstance.put(`/worklogs/${log.id}`, log);
  }

  async deleteLog(id) {
    return await axiosInstance.delete(`/worklogs/delete-log/${id}`);
  }

  async search(startDate, endDate) {
    return await axiosInstance.get(`/worklogs/search/${startDate}/${endDate}`, {
      startDate: startDate,
      endDate: endDate
    });
  }

  async searchComment(text) {
    return await axiosInstance.get(`/worklogs/search-comment/${text}`, text);
  }
}

export default new ServiceWorklogs();
