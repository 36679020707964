// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Anuphan:wght@300;400;500&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".editHeader {\n    display: flex;\n    align-items: center;\n    margin-bottom: 12px;\n}\n\n.editTitle {\n    font-weight: 550;\n    font-size: 16px;\n    margin-left: 12px;\n}\n\n.ticketClass {\n    display: flex;\n}\n\n.dateClass {\n    display: flex;\n}\n\n.descClass {\n    display: flex;\n    margin-bottom: 12px;\n}", "",{"version":3,"sources":["webpack://./src/components/EditModal/EditModal.css"],"names":[],"mappings":"AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Anuphan:wght@300;400;500&display=swap');\n\n.editHeader {\n    display: flex;\n    align-items: center;\n    margin-bottom: 12px;\n}\n\n.editTitle {\n    font-weight: 550;\n    font-size: 16px;\n    margin-left: 12px;\n}\n\n.ticketClass {\n    display: flex;\n}\n\n.dateClass {\n    display: flex;\n}\n\n.descClass {\n    display: flex;\n    margin-bottom: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
