import { Row, Col } from 'antd';
import React, { useEffect, useState } from 'react';
import './LoginPage.css'
import Logo from "../../assets/logo-black.png";


const LoginPage = (props) => {

  useEffect(() => {
    document.title = 'Authentication';
  }, []);

  const handleLogin = () => {
    props.authFunc();
  };

  return (
    <div style={{ padding: '20px' }}>
      <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
        <Col span={8} xs={24} sm={24} md={12} lg={8} xl={8}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div>
              <span style={{ fontWeight: '500', fontSize: '24px' }}>TIMS</span>
            </div>
            <div style={{ padding: '8px 0 24px 0' }}>
              <button onClick={handleLogin} type="button" className="login-with-google-btn" >
                Sign in with Google
              </button>
            </div>
            <div>
              <div>
                <img style={{ width: '90px' }} src={Logo} alt='Logo'></img>
              </div>
            </div>
          </div>


        </Col>
      </Row>
    </div>
  );
};

export default LoginPage;