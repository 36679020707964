import React from "react";

const Board = () => {
    return (
        <div style={{ padding: '8px', overflowX: 'auto', height: '100%' }}>
            Soon...
        </div>
    );
}

export default Board;
