import React, { useEffect, useState } from "react";
import { Button, Col, ConfigProvider, DatePicker, Form, Input, InputNumber, Modal, Row, Select, Space } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import 'moment/locale/en-gb';

const { Option } = Select;

const ReportChangeModal = (props) => {

    useEffect(() => {

    }, []);

    const handleCloseModal = () => {
        props.isModalChange(false);
    }

    return (
        <Modal
            open={props.isOpen}
            width={450}
            onCancel={handleCloseModal}
            footer={[]}
            mask={false}
        >
            Hi!
            {/* {props.worklog.display_name} */}
        </Modal>
    );
}

export default ReportChangeModal;