import { Avatar, Button, Form, Input, List, Mentions } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Comment from '@ant-design/compatible/es/comment';
import ServiceComments from '../../services/comments';
import ServiceNotifications from '../../services/notifications';
import { useUser } from '../../UserContext';
import ServiceUsers from '../../services/users'
import ServiceEmail from '../../services/email'

const { TextArea } = Input;

const CommentList = ({ comments }) => {
    return (
        <List
            dataSource={comments}
            header={`${comments.length} ${comments.length > 1 ? 'replies' : 'reply'}`}
            itemLayout="horizontal"
            renderItem={(comment) =>
                <Comment
                    author={comment.display_name}
                    avatar={<Avatar>{getInitials(comment.display_name)}</Avatar>}
                    content={comment.comment_data}
                    datetime={moment(comment.created_date).fromNow()}
                />}
        />
    )
};

const Editor = ({ handleMentionSelect, options, onChange, onSubmit, submitting, value }) => (
    <>
        <Form.Item>
            {/* <TextArea rows={4} onChange={onChange} value={value} /> */}
            <Mentions
                style={{ color: 'blue' }}
                rows={4}
                onChange={(data) => { onChange(data) }}
                onSelect={handleMentionSelect}
                value={value}
                options={options}
            />
        </Form.Item>
        <Form.Item>
            <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
                Add Comment
            </Button>
        </Form.Item>
    </>
);

function getInitials(name) {
    if (!name || typeof name !== 'string') {
        return '';
    }

    return name
        .split(' ')
        .map((word) => word.charAt(0))
        .join('');
}

const CommentBox = (props) => {
    const { user } = useUser();
    const [options, setOptions] = useState([]);
    const [comments, setComments] = useState(props.comments);
    const [submitting, setSubmitting] = useState(false);
    const [value, setValue] = useState('');
    const [isBuild, setIsBuild] = useState();
    const [id, setId] = useState();
    const [admin, setAdmin] = useState();
    const [adminEmail, setAdminEmail] = useState();
    const [senderName, setSenderName] = useState();
    const [summary, setSummary] = useState();
    const [name, setName] = useState();
    const [mention, setMention] = useState();

    useEffect(() => {

        if (!user) {
            return;
        }

        setIsBuild(props.isBuild);
        setId(props.id);
        setAdmin(props.administrator);
        props.isBuild ? setName(props.name) : setSummary(props.summary);
        setOptions(props.users.map(item => ({
            value: item.display_name,
            label: item.display_name,
            id: item.id
        })));
        setAdminEmail(props.users.find(item => { return item.id === props.administrator }).email)
        setSenderName(props.users.find(item => { return item.id === user.id }).display_name)

    }, [props, user]);

    function createCommentForBuild(userId, commentData) {
        const data = {
            userId: userId,
            commentData: commentData,
            id: id,
            isBuild: isBuild
        };
        const title = 'The comment was left on your task';
        ServiceComments.createComment(data)
            .then((response) => {
                ServiceNotifications.sendNotification(
                    {
                        sender: userId,
                        recipient: admin,
                        message: commentData,
                        title: title,
                        isChecked: false,
                        id: id,
                        isBuild: isBuild
                    }
                )
                    .then(() => {
                        console.log('email');
                        const data = {
                            recipientEmail: adminEmail,
                            subject: title,
                            content: `
                            <html>
                                <head>
                                    <title>New Comment Notification</title>
                                    <style>
                                        body {
                                            font-family: Arial, sans-serif;
                                            margin: 0;
                                            padding: 0;
                                            background-color: #522EE7;
                                            font-family: 'Open Sans';
                                            letter-spacing: 0.5px;
                                        }
                                        
                                        .content {
                                            margin: 20px 0;
                                            text-align: center;
                                        }

                                        .footer {
                                            text-align: center;
                                            color: #888;
                                            margin-top: 20px;
                                        }
                                    </style>
                                </head>
                                <body>
                                    <div>
                                        <div class='content'>
                                            <div>
                                                The comment was left by ${senderName}
                                            <div>
                                            <p></p>
                                            <div>
                                                <i>${commentData}</i>
                                            </div>
                                            <p></p>
                                            <div>
                                                <a href="${process.env.REACT_APP_FRONT_URL}/${isBuild ? 'build' : 'task'}/${id}" target="_blank" style="text-decoration: none; color: #007BFF; cursor: pointer;">
                                                    ${isBuild ? name : summary}
                                                </a>
                                            <div>
                                        <div>
                                        <div class="footer">
                                            <br/>
                                            <p>This is an automatic email, please do not reply.</p>
                                            <p>© Twistellar | TIMS</p>
                                        </div>
                                    </div>
                                </body>
                            </html>
                            `
                        }
                        ServiceEmail.sendEmail(data)

                        
                    })

                if (mention) {
                    const title = 'You were mentioned in the comment';
                    ServiceNotifications.sendNotification(
                        {
                            sender: user.id,
                            recipient: mention.id,
                            message: commentData,
                            title: title,
                            isChecked: false,
                            id: id,
                            isBuild: isBuild
                        }
                    )

                    const recipientEmail = props.users.find(item => { return item.id === mention.id}).email;

                    const recipientData = {
                        recipientEmail: recipientEmail,
                        subject: 'You were mentioned in the comment',
                        content: `
                        <html>
                            <head>
                                <title>New Comment Notification</title>
                                <style>
                                    body {
                                        font-family: Arial, sans-serif;
                                        margin: 0;
                                        padding: 0;
                                        background-color: #522EE7;
                                        font-family: 'Open Sans';
                                        letter-spacing: 0.5px;
                                    }
                                    
                                    .content {
                                        margin: 20px 0;
                                        text-align: center;
                                    }

                                    .footer {
                                        text-align: center;
                                        color: #888;
                                        margin-top: 20px;
                                    }
                                </style>
                            </head>
                            <body>
                                <div>
                                    <div class='content'>
                                        <div>
                                            The comment was left by ${senderName}
                                        <div>
                                        <p></p>
                                        <div>
                                            <i>${commentData}</i>
                                        </div>
                                        <p></p>
                                        <div>
                                            <a href="${process.env.REACT_APP_FRONT_URL}/${isBuild ? 'build' : 'task'}/${id}" target="_blank" style="text-decoration: none; color: #007BFF; cursor: pointer;">
                                                ${isBuild ? name : summary}
                                            </a>
                                        <div>
                                    <div>
                                    <div class="footer">
                                        <br/>
                                        <p>This is an automatic email, please do not reply.</p>
                                        <p>© Twistellar | TIMS</p>
                                    </div>
                                </div>
                            </body>
                        </html>
                        `
                    }

                    ServiceEmail.sendEmail(recipientData);
                }

                console.log('response', response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const handleSubmit = () => {
        if (!value) return;
        setSubmitting(true);
        setTimeout(() => {
            setSubmitting(false);
            setValue('');
            const comment = {
                display_name: user.display_name,
                comment_data: <p>{value}</p>,
                datetime: moment().fromNow(),
            };
            setComments([...comments, comment]);
            createCommentForBuild(user.id, value);
        }, 1000);
    };

    const handleChange = (data) => {
        setValue(data);
    };

    const handleMentionSelect = (contentState) => {
        setMention(contentState);

    };

    if (!user) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <div style={{ fontWeight: '500' }}>Comments</div>
            {comments.length > 0 && <CommentList comments={comments} />}
            <Comment
                avatar={<Avatar>{getInitials(user.display_name)}</Avatar>}
                content={<Editor handleMentionSelect={handleMentionSelect} options={options} onChange={handleChange} onSubmit={handleSubmit} submitting={submitting} value={value} />}
            />
        </>
    );
};

export default CommentBox;