import React from 'react';
import { Avatar, Image } from 'antd';
import './TimeLoggingHeader.css';

function TimeLoggingHeader(props) {

    const { display_name } = props.user || JSON.parse(localStorage.getItem('user'));

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className="test">
                <Avatar size={'large'}>{getInitials(display_name)}</Avatar>
            </div>

            <span
                style={{
                    fontSize: '16px',
                    fontWeight: '550',
                    marginLeft: '10px',
                }}
            >
                {display_name}
            </span>
        </div>
    );
}

function getInitials(name) {
    return name
        .split(' ')
        .map(word => word.charAt(0))
        .join('');
}

export default TimeLoggingHeader;