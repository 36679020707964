import React, { useState } from 'react';
import { Table, Button, Popover, Input } from 'antd';
import moment from 'moment';
import {
    CalendarOutlined
} from '@ant-design/icons';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Report.css'
import ServiceBuilds from '../../services/builds';
import ServiceUsers from '../../services/users';
import { useEffect } from 'react';
import ReportChangeModal from '../../components/ReportChangeModal/ReportChangeModal';

const Report = () => {
    const [currentDate, setCurrentDate] = useState(moment());
    const [tableParams, setTableParams] = useState({});
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [choosenWorklog, setChoosenWorklog] = useState();

    const fetchData = async () => {
        setLoading(true);
        try {
            console.log('table params', tableParams);
            const response = await ServiceUsers.getAllUsers();
            console.log('response', response.data);
            const usersData = response.data.map(item => {
                const rowData = {
                    key: item.id,
                    sow_name: 'Test',
                    display_name: item.display_name,
                    hours: 0,
                };
                const endOfMonth = moment(currentDate).endOf('month');

                // Create an object to store worklog data as date => accumulated hours
                const worklogData = {};

                // Fill in worklog data based on item.worklogs (adjust as needed)
                item.worklogs.forEach(worklog => {
                    const worklogDate = moment(worklog.created_at);
                    const worklogDateStr = worklogDate.format('YYYY-MM-DD');

                    // Accumulate worklog hours for the same date
                    if (worklogData[worklogDateStr] === undefined) {
                        worklogData[worklogDateStr] = parseFloat(worklog.time_spent_hours);
                    } else {
                        worklogData[worklogDateStr] += parseFloat(worklog.time_spent_hours);
                    }
                });

                for (let day = 1; day <= endOfMonth.date(); day++) {
                    const currentDateStr = moment(currentDate).date(day).format('YYYY-MM-DD');
                    // Fill in the rowData based on accumulated worklogData or use a default value (e.g., 0)
                    rowData[`day${day}`] = worklogData[currentDateStr] || null;
                }

                rowData.hours = calculateRowTotal(rowData);
                console.log('rowData', rowData);
                return rowData;
            });

            setTableParams({
                ...tableParams,
            });

            setData(usersData);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        document.title = 'Log Report';
        fetchData();
    }, [JSON.stringify(tableParams), currentDate]);

    const handlePrevMonth = () => {
        const prevMonthDate = moment(currentDate).subtract(1, 'month');
        setCurrentDate(prevMonthDate);
        console.log('prevMonthDate', prevMonthDate);
        // fetchData();
    };

    const handleNextMonth = () => {
        const nextMonthDate = moment(currentDate).add(1, 'month');
        setCurrentDate(nextMonthDate);
        // fetchData();
    };

    const handleCellClick = (value, cellIndex) => {
        // handleModalChange(true);
        setChoosenWorklog(value);
    };

    const startOfMonth = moment(currentDate).startOf('month');
    const endOfMonth = moment(currentDate).endOf('month');
    const formattedDateRange = `${startOfMonth.format('DD MMM')} - ${endOfMonth.format('DD MMM, YYYY')}`;

    const isWeekend = (date) => {
        const dayOfWeek = date.format('dd');
        return dayOfWeek === 'Sa' || dayOfWeek === 'Su';
    };

    const dayColumns = [];

    const today = moment();

    for (let day = 1; day <= endOfMonth.date(); day++) {
        const date = moment(startOfMonth).set('date', day);
        const dayOfWeek = date.format('dd');
        const cellClassName = date.isSame(today, 'day') ? 'today-cell' : '';

        dayColumns.push({
            title:
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <div>{day.toString()}</div>
                    <div
                        style={{
                            fontWeight: '400',
                            color: '#1583e2',
                        }}
                    >
                        {dayOfWeek.toUpperCase()}
                    </div>
                </div>,
            dataIndex: `day${day}`,
            key: `day${day}`,
            width: 60,
            align: 'center',
            className: cellClassName,
            onCell: (record, rowIndex) => ({
                onClick: () => {
                    console.log('Clicked on', record, rowIndex);
                    handleCellClick(record, rowIndex);
                },
            }),
            render: (text, record) => {

                return (
                    <div>
                        {text}
                    </div>
                );
            },
        });
    }

    const calculateColumnTotal = (columnName) => {
        return data && data.reduce((total, item) => total + parseFloat(item[columnName] || 0, 10), 0);
    };

    const calculateRowTotal = (rowData) => {
        let total = 0;
        for (let day = 1; day <= endOfMonth.date(); day++) {
            total += parseFloat(rowData[`day${day}`] || 0, 10);
        }
        return total;
    };


    const columns = [
        {
            title: <div style={{ fontWeight: '500' }}>Full Name</div>,
            width: 150,
            dataIndex: 'display_name',
            key: 'display_name',
            fixed: 'left',
        },
        {
            title: <div style={{ fontWeight: '500' }}>Hours</div>,
            width: 70,
            dataIndex: 'hours',
            key: 'hours',
            fixed: 'left',
            align: 'center',
            sorter: (a, b) => parseFloat(a.hours) - parseFloat(b.hours),
            render: (text) => (
                <div>
                    {text !== null ? parseFloat(text).toFixed(2) : null}
                </div>
            ),
        },
        ...dayColumns,
    ];

    const handleModalChange = (value) => {
        setIsModalOpen(value);
    }

    return (
        <div style={{ margin: '8px' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', margin: '16px 0 16px 0' }}>
                <CalendarOutlined />
                <div>{formattedDateRange}</div>
                <Button type='text' onClick={handlePrevMonth}>
                    <FontAwesomeIcon icon={faArrowLeft} style={{
                        cursor: 'pointer'
                    }} />
                </Button>
                <Button type='text' onClick={handleNextMonth}>
                    <FontAwesomeIcon icon={faArrowRight} style={{
                        cursor: 'pointer'
                    }} />
                </Button>
            </div>
            <Table
                columns={columns}
                dataSource={data}
                scroll={{
                    x: 1500,
                    y: 550
                }}
                bordered
                size='small'
                pagination={false}
                summary={() => (
                    <Table.Summary fixed='bottom'>
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={1}>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} colSpan={1} align='center'>
                                <div style={{ fontWeight: '600' }}>{calculateColumnTotal('hours')}</div>
                            </Table.Summary.Cell>
                            {dayColumns.map((dayColumn, index) => (
                                <Table.Summary.Cell
                                    key={`totalDay${index}`}
                                    index={index + 2}
                                    align='center'
                                >
                                    <div style={{ fontWeight: '600' }}>
                                        {calculateColumnTotal(dayColumn.dataIndex)}
                                    </div>
                                </Table.Summary.Cell>
                            ))}
                        </Table.Summary.Row>
                    </Table.Summary>
                )}
                // antd site header height
                sticky={{
                    offsetHeader: 64,
                }}
                loading={loading}
            />
            <ReportChangeModal
                isOpen={isModalOpen}
                isModalChange={handleModalChange}
                worklog={choosenWorklog}
            />
        </div>
    );
};
export default Report;