import React, { createContext, useContext, useEffect, useState } from 'react';
import ServiceAuth from "./services/auth";
import { useNavigate } from 'react-router';

const UserContext = createContext();

export function UserProvider({ children }) {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();


  useEffect(() => {
    ServiceAuth.getUser()
      .then(response => {
        if (response.data.success) {
          console.log('UserContext');
          setUser(response.data.user);
        } else {
          navigate('/login');
        }
      })
  }, [navigate, setUser]);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  return useContext(UserContext);
}
