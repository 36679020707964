import React, { useEffect, useState } from 'react';
import { Spin, Button, Modal, Input, List, Card, Select, message } from 'antd';
import ServiceNotifications from '../../services/notifications';
import { Option } from 'antd/es/mentions';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Importing Quill CSS
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'; // Importing Ant Design Icons
import './Home.css';
import { useUser } from '../../UserContext';


const Home = () => {
    const { user } = useUser();
    const [isLoading, setIsLoading] = useState(true);
    const [news, setNews] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newAnnouncement, setNewAnnouncement] = useState({ header: '', type: '', content: '', user_id: user.id });
    const [editingNews, setEditingNews] = useState(null); // For editing an existing news

    useEffect(() => {
        document.title = 'Hub';
        if (user?.id) {
            setNewAnnouncement((prev) => ({ ...prev, user_id: user.id }));
        }
        fetchNews();
    }, [user]);

    const fetchNews = async () => {
        try {
            const response = await ServiceNotifications.getNews();
            setNews(response.data);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching news:', error);
            message.error('Failed to load news');
        }
    };

    const handleAddNews = async () => {
        if (newAnnouncement.header && newAnnouncement.type && newAnnouncement.content) {
            try {
                console.log('newAnnouncement', newAnnouncement);
                const response = await ServiceNotifications.addNews(newAnnouncement);
                console.log('response', response);
                setNews([...news, response.data]);
                setNewAnnouncement({ header: '', type: '', content: '', user_id: user.id });
                setIsModalOpen(false);
                message.success('News added successfully');
            } catch (error) {
                console.error('Error adding news:', error);
                message.error('Failed to add news');
            }
        } else {
            message.warning('Please fill in all fields.');
        }
    };

    const handleDeleteNews = async (id) => {
        try {
            await ServiceNotifications.deleteNews(id);
            setNews(news.filter(item => item.id !== id));
            message.success('News deleted successfully');
        } catch (error) {
            console.error('Error deleting news:', error);
            message.error('Failed to delete news');
        }
    };

    const handleUpdateNews = async () => {
        if (editingNews.header && editingNews.type && editingNews.content) {
            try {
                const response = await ServiceNotifications.updateNews(editingNews.id, editingNews);
                const updatedNews = news.map(item =>
                    item.id === editingNews.id ? response.data : item
                );
                setNews(updatedNews);
                setEditingNews(null);
                setIsModalOpen(false);
                message.success('News updated successfully');
            } catch (error) {
                console.error('Error updating news:', error);
                message.error('Failed to update news');
            }
        } else {
            message.warning('Please fill in all fields.');
        }
    };

    if (isLoading) {
        return (
            <div className="loading-container">
                <Spin size="large" />
            </div>
        );
    }

    return (
        <div className="home-container">
            <div className="news-section">
                <h3>Company News & Announcements</h3>
                <Button type="primary" onClick={() => { setEditingNews(null); setIsModalOpen(true); }}>
                    Add Article
                </Button>
                <List
                    dataSource={news}
                    renderItem={(item) => (
                        <Card className="news-card" key={item.id}>
                            <div className="news-header">
                                <div className="news-title">{item.header}</div>
                                <div className="news-type">{item.type}</div>
                            </div>
                            <div className="news-content" dangerouslySetInnerHTML={{ __html: item.content }} />
                            <div className="news-date">{new Date(item.date).toLocaleDateString()} | {item.author}</div>
                            <div className="news-buttons" style={{ display: user.display_name === item.author ? 'flex' : 'none' }}>
                                <Button 
                                    onClick={() => { setEditingNews(item); setIsModalOpen(true); }} 
                                    icon={<EditOutlined />}
                                />
                                <Button 
                                    onClick={() => handleDeleteNews(item.id)} 
                                    type="primary" 
                                    danger 
                                    icon={<DeleteOutlined />}
                                />
                            </div>
                        </Card>
                    )}
                />
            </div>

            <Modal
                title={editingNews ? "Edit Article" : "Create Article"}
                open={isModalOpen}
                onOk={editingNews ? handleUpdateNews : handleAddNews}
                onCancel={() => {
                    setIsModalOpen(false);
                    setEditingNews(null);
                    setNewAnnouncement({ header: '', type: '', content: '', user_id: user.id }); // Reset state on cancel
                }}
            >
                <Input
                    value={editingNews ? editingNews.header : newAnnouncement.header}
                    onChange={(e) => {
                        if (editingNews) {
                            setEditingNews({ ...editingNews, header: e.target.value });
                        } else {
                            setNewAnnouncement({ ...newAnnouncement, header: e.target.value });
                        }
                    }}
                    placeholder="Enter title"
                />
                <Select
                    value={editingNews ? editingNews.type : newAnnouncement.type}
                    onChange={(value) => {
                        if (editingNews) {
                            setEditingNews({ ...editingNews, type: value });
                        } else {
                            setNewAnnouncement({ ...newAnnouncement, type: value });
                        }
                    }}
                    placeholder="Select type"
                    style={{ width: '100%', marginTop: 10 }}
                >
                    <Option value="Announcement">Announcement</Option>
                    <Option value="Update">Update</Option>
                    <Option value="Notice">Notice</Option>
                </Select>

                <div style={{ marginTop: 10 }}>
                    <ReactQuill
                        value={editingNews ? editingNews.content : newAnnouncement.content}
                        onChange={(value) => {
                            if (editingNews) {
                                setEditingNews({ ...editingNews, content: value });
                            } else {
                                setNewAnnouncement({ ...newAnnouncement, content: value });
                            }
                        }}
                        placeholder="Enter details"
                    />
                </div>
            </Modal>
        </div>
    );
};

export default Home;
