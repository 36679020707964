import axiosInstance from "../config/axios";

class ServiceFiles {
    async addFile(data) {
        return await axiosInstance.post('/upload', data);
    }

    async getBuildFiles(id) {
        return await axiosInstance.get(`/builds/${id}/files`)
    }

    async getTaskFiles(key) {
        return await axiosInstance.get(`/tasks/${key}/files`)
    }

    async deleteFile(key) {
        return await axiosInstance.delete(`/files/${key}`)
    }
}

export default new ServiceFiles();