import axiosInstance from "../config/axios";

class ServiceActivities {

  async updateActivity(data) {
    return await axiosInstance.put('/activities/', data)
  }

  async toLast(data) {
    return await axiosInstance.put('/activities/last', data)
  }

  async getActivities() {
    return await axiosInstance.get('/activities/');
  }

}

export default new ServiceActivities();