import React, { useEffect, useState, useRef } from "react";
import { Routes, Route, Link, useLocation, Navigate, useNavigate } from "react-router-dom";
import { Avatar, Badge, Button, Card, Dropdown, Input, Layout, Menu, theme, Spin, Tour, FloatButton } from "antd";
import {
  UserOutlined,
  BellOutlined,
  LogoutOutlined,
  HomeOutlined,
  TableOutlined,
  CalendarOutlined,
  ScheduleOutlined,
  BookOutlined,
  SolutionOutlined,
  ExportOutlined,
  FileTextOutlined,
  AppstoreOutlined,
  CustomerServiceOutlined,
  CommentOutlined,
  ArrowUpOutlined
} from "@ant-design/icons";
import Home from "./pages/Home/Home";
import TimeLogging from "./pages/TimeLogging/TimeLogging";
import ShowTicket from "./pages/ShowTicket/ShowTicket";
import WorkDashboard from "./pages/WorkDashboard/WorkDashboard";
import LoginPage from "./pages/LoginPage/LoginPage";
import Users from "./pages/Users/Users";
import Board from "./pages/Board/Board";
import Logo from "./assets/logotypewhite.svg";
import { Content, Header } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import { useMediaQuery } from "react-responsive";
import ServiceAuth from "./services/auth";
import ServiceUsers from "./services/users";
import ServiceNotifications from "./services/notifications";
import comments from "./services/comments";
import Notifications from "./pages/Notifications/Notifications";
import MyWorkPage from "./pages/MyWorkPage/MyWorkPage";
import MyManagedWork from "./pages/MyManagedWork/MyManagedWork";
import { useUser } from './UserContext';
import TimeDashboard from './pages/TimeDashboard/TimeDashboard';
import Report from './pages/Report/Report';
import AccessDeniedPage from "./pages/AccessDenied/AccessDenied";
import BuildPage from "./pages/BuildPage/BuildPage";
import './App.css'
import CTL from "./components/CTL/CTL";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
import Tempo from "./components/Tempo/Tempo";

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

export default function App() {

  const { user, loading } = useUser();
  const navigate = useNavigate();
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false)
  const location = useLocation();
  // const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [showMenu, setShowMenu] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const sidebarRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState();
  const [spinner, setSpinner] = useState(false);
  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);

  const steps = [
    {
      title: 'Notifications',
      description: 'Here you can see all the notifications that come in the process of work.',
      target: () => ref1.current,
    },
    {
      title: 'Log out',
      description: 'By clicking on this button, you can log out of the system.',
      target: () => ref2.current,
    },
    {
      title: 'Profile',
      description: <Spin spinning={spinner}>All user information can be found here.</Spin>,
      target: () => ref3.current,
    },
  ];

  const handleTourUser = () => {
    setSpinner(true);
    ServiceUsers.updateUser({ ...user, is_tour_passed: true })
      .then(() => {
        console.log('hello');
        setSpinner(false);
        setOpen(false);
      })
  }

  useEffect(() => {

    if (user === null) {
      return;
    } else {
      setIsUserLoggedIn(true);
      console.log(!user.is_tour_passed);
      setOpen(!user.is_tour_passed);
      console.log('tour', !user.is_tour_passed);
      console.log(user);
    }

    console.log('location', location);

    ServiceNotifications.fetchNotifications(user.id)
      .then(response => {
        setNotifications(response.data);
        console.log('notifications', response.data);
      })

    const handleClickOutside = (event) => {
      const sidebarElement = sidebarRef.current;
      const isClickInsideSidebar = sidebarElement && sidebarElement.contains(event.target);
      if (!isClickInsideSidebar) {
        setShowMenu(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    setIsLoading(false);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };


  }, [user]);

  const onNotifications = (nots) => {
    setNotifications(nots);
  }

  function getInitials(name) {
    return name
      .split(' ')
      .map(word => word.charAt(0))
      .join('');
  }

  const openGoogleAuth = () => {
    window.open(`${process.env.REACT_APP_BACKEND_URL}/api/auth/google`, "_self");
  };

  const logout = async () => {
    const response = await ServiceAuth.logoutUser();
    if (!response.data.isAuth) {
      setIsUserLoggedIn(false);
      navigate('/login');
    }
  };

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const uncheckedNotifications = notifications.filter(notification => !notification.is_checked);

  const teamMemberItems = [
    getItem(<Link to={`/`}>Hub</Link>, '/', <HomeOutlined />),
    getItem(<Link to={`/time-logging`}>My Time</Link>, '/time-logging', <CalendarOutlined />),
    getItem(<Link to={`/my-work`}>My Work</Link>, '/my-work', <BookOutlined />),
  ];

  const projectManagerItems = [
    getItem(<Link to={`/`}>Hub</Link>, '/', <HomeOutlined />),
    getItem(<Link to={`/time-logging`}>My Time</Link>, '/time-logging', <CalendarOutlined />),
    getItem(<Link to={`/my-work`}>My Work</Link>, '/my-work', <BookOutlined />),
    getItem(<Link to={`/my-managed-work`}>My Managed Work</Link>, '/my-managed-work', <SolutionOutlined />),
    getItem(<Link to={`/time-dashboard`}>Time Dashboard</Link>, '/time-dashboard', <ScheduleOutlined />),
  ];

  const superAdminItems = [
    getItem(<Link to={`/`}>Hub</Link>, '/', <HomeOutlined />),
    getItem(<Link to={`/users`}>Users</Link>, '/users', <UserOutlined />),
    getItem(<Link to={`/time-logging`}>My Time</Link>, '/time-logging', <CalendarOutlined />),
    getItem(<Link to={`/my-work`}>My Work</Link>, '/my-work', <BookOutlined />),
    getItem(<Link to={`/my-managed-work`}>My Managed Work</Link>, '/my-managed-work', <SolutionOutlined />),
    getItem(<Link to={`/time-dashboard`}>Time Dashboard</Link>, '/time-dashboard', <ScheduleOutlined />),
    getItem(<Link to={`/work-dashboard`}>Work Dashboard</Link>, '/work-dashboard', <TableOutlined />),
    getItem(<Link to={`/report`}>Log Report</Link>, '/report', <FileTextOutlined />),
    getItem(<Link to={`/ctl`} target="_blank">CTL</Link>, '/ctl', <ExportOutlined />),
    getItem(<Link to={`/tempo`}>Tempo</Link>, '/tempo', <AppstoreOutlined />),
  ];

  return (

    <Layout style={{ height: "100vh" }}>
      <FloatButton.Group
        trigger="hover"
        type="primary"
        style={{
          insetInlineEnd: 40,
        }}
        icon={<ArrowUpOutlined />}
      >
        <FloatButton />
        <FloatButton icon={<CommentOutlined />} />
      </FloatButton.Group>
      {isUserLoggedIn && !(location.pathname === '/login') &&
        <Header style={{ backgroundColor: 'rgba(80,104,224,1)' }}>
          <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "space-between" }}>
            <div>
              <div style={{ fontSize: '16px', fontWeight: '500', color: 'white', cursor: 'pointer' }} onClick={() => { navigate('/') }}>TIMS</div>
            </div>
            <div style={{ width: '100px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Badge dot={uncheckedNotifications.length > 0}>
                    <BellOutlined
                      ref={ref1}
                      onClick={() => { navigate('/notifications') }}
                      style={{
                        fontSize: 18,
                        color: 'white',
                        cursor: 'pointer',
                        transition: 'transform 0.3s'
                      }}
                      className="hover-effect"
                    />
                  </Badge>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }} >

                  <LogoutOutlined
                    ref={ref2}
                    onClick={logout}
                    style={{
                      fontSize: 18,
                      color: 'white',
                      cursor: 'pointer',
                      transition: 'transform 0.3s'
                    }}
                    className="hover-effect"
                  />
                </div>
                <div>
                  <Avatar
                    ref={ref3}
                    size={32}
                    icon={<UserOutlined />}
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate('/profile')}
                  />
                </div>
              </div>
            </div>
          </div>
        </Header>
      }
      <Layout style={{ background: colorBgContainer }}>
        <Layout>
          {isUserLoggedIn && !(location.pathname === '/login') &&

            (<Sider
              // width={200}
              style={{
                background: colorBgContainer,
                minHeight: "calc(100vh - 64px)",
              }}
              // breakpoint="md"
              // collapsedWidth="0"
              onBreakpoint={(broken) => {
                console.log(broken);
              }}
              collapsible
              collapsed={collapsed}
              onCollapse={(value) => setCollapsed(value)}
            >
              <div className="demo-logo-vertical" />
              <Menu
                theme="light"
                selectedKeys={[location.pathname]}
                style={{ height: "100%" }}
                items={
                  user.role_id === 1 ? teamMemberItems
                    : user.role_id === 2 ? projectManagerItems
                      : superAdminItems
                }
              />

            </Sider>
            )}
          <Layout >
            <Content style={{ background: "white" }}>
              <Routes>
                <Route
                  path="/profile"
                  element={
                    isLoading ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100vh',
                        }}
                      >
                        <Spin size="large" />
                      </div>
                    ) :
                      <ProfilePage />
                  }
                />
                <Route path="/" element={
                  isLoading ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                      }}
                    >
                      <Spin size="large" />
                    </div>
                  ) : <Home />} />
                <Route
                  path="/users"
                  element={
                    isLoading ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100vh',
                        }}
                      >
                        <Spin size="large" />
                      </div>
                    ) : user?.role_id === 3 ? (
                      <Users />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                <Route path="/time-logging" element={<TimeLogging />} />
                <Route path="/task/:key" element={<ShowTicket />} />
                <Route path="/build/:id" element={<BuildPage />} />
                {/* <Route path="/tickets" element={<TicketsPage />} /> */}
                <Route
                  path="/login"
                  element={<LoginPage authFunc={openGoogleAuth} />}
                />
                <Route path="/access-denied" element={<AccessDeniedPage />} />

                <Route
                  path="/board"
                  element={
                    isLoading ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100vh',
                        }}
                      >
                        <Spin size="large" />
                      </div>
                    ) : user?.role_id === 3 ? (
                      <Board />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                <Route
                  path="/notifications"
                  element={
                    isLoading ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100vh',
                        }}
                      >
                        <Spin size="large" />
                      </div>
                    ) : <Notifications notifications={notifications} onNotifications={onNotifications} />
                  }
                />
                <Route
                  path="/work-dashboard"
                  element={
                    isLoading ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100vh',
                        }}
                      >
                        <Spin size="large" />
                      </div>
                    ) : user?.role_id === 3 ? (
                      <WorkDashboard />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                <Route
                  path="/my-work"
                  element={<MyWorkPage />}
                />
                <Route
                  path="/my-managed-work"
                  element={
                    isLoading ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100vh',
                        }}
                      >
                        <Spin size="large" />
                      </div>
                    ) : user?.role_id === 3 || user?.role_id === 2 ? (
                      <MyManagedWork />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                <Route
                  path="/time-dashboard"
                  element={
                    isLoading ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100vh',
                        }}
                      >
                        <Spin size="large" />
                      </div>
                    ) : user?.role_id === 3 || user?.role_id === 2 ? (
                      <TimeDashboard />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                <Route
                  path="/report"
                  element={
                    isLoading ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100vh',
                        }}
                      >
                        <Spin size="large" />
                      </div>
                    ) :
                      <Report />
                  }
                />
                <Route
                  path="/ctl"
                  element={
                    isLoading ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100vh',
                        }}
                      >
                        <Spin size="large" />
                      </div>
                    ) :
                      <CTL />
                  }
                />
                <Route
                  path="/tempo"
                  element={
                    isLoading ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100vh',
                        }}
                      >
                        <Spin size="large" />
                      </div>
                    ) :
                      <Tempo />
                  }
                />
              </Routes>
            </Content>
          </Layout>
        </Layout>
      </Layout>
      {!isLoading && <Tour open={open} onClose={handleTourUser} steps={steps} />}
    </Layout>
  );
}