import React, { useEffect, useState } from 'react';
import { Card, Divider, Space, Tag, Tabs, Table, Progress, Spin, Typography } from 'antd';
import { useUser } from '../../UserContext';
import StickyBox from 'react-sticky-box';
import './Profile.css';
import ServiceTickets from '../../services/tickets';

const { TabPane } = Tabs;
const { Text } = Typography;

const twoColors = {
    '0%': '#108ee9',
    '100%': '#87d068',
};

const Profile = () => {
    const { user } = useUser();
    const [isLoading, setIsLoading] = useState(true);
    const [tasks, setTasks] = useState([]);

    useEffect(() => {
        document.title = 'Profile';
        const fetchData = async () => {
            try {
                if (user && user.id) {
                    const response = await ServiceTickets.getUserTasks();
                    console.log('tasks', response.data);
                    setTasks(response.data);
                }
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [user]);

    const renderTabBar = (props, DefaultTabBar) => (
        <StickyBox offsetTop={64} offsetBottom={20} style={{ zIndex: 1 }}>
            <DefaultTabBar {...props} style={{ background: '#fff' }} />
        </StickyBox>
    );

    const columns = [
        {
            title: 'Summary',
            dataIndex: 'summary',
            key: 'summary',
            width: 200,
        },
        {
            title: 'Priority',
            dataIndex: 'priority',
            key: 'priority',
            render: priority => <Tag color={priority === 'High' ? 'red' : 'blue'}>{priority}</Tag>,
        },
        {
            title: 'Due Date',
            dataIndex: 'due_date',
            key: 'due_date',
            render: date => new Date(date).toLocaleDateString(),
        },
        {
            title: 'Estimate',
            key: 'estimate',
            render: (_, record) => {
                const totalEstimate = record.activities.reduce((sum, activity) => 
                    sum + parseFloat(activity.estimate), 0);
                return totalEstimate;
            },
        },        
        {
            title: 'Logged',
            key: 'logged_hours',
            render: (_, record) => {
                const loggedHours = record.activities.reduce((sum, activity) =>
                    sum + activity.worklogs.reduce((acc, log) => acc + parseFloat(log.time_spent_hours), 0), 0);
                return loggedHours;
            },
        },
        {
            title: 'Remaining',
            key: 'remaining',
            render: (_, record) => {
                const remainingHours = record.activities.reduce((sum, activity) => 
                    sum + parseFloat(activity.remaining_estimate), 0);
                return remainingHours;
            },
        },        
        {
            title: 'Progress',
            key: 'progress',
            render: (_, record) => {
                const totalEstimate = record.activities.reduce((sum, activity) => 
                    sum + parseFloat(activity.estimate), 0);
        
                const loggedHours = record.activities.reduce((sum, activity) =>
                    sum + activity.worklogs.reduce((acc, log) => acc + parseFloat(log.time_spent_hours), 0), 0);
        
                const progress = totalEstimate > 0 ? Math.min((loggedHours / totalEstimate) * 100, 100) : 0;
        
                return <Progress percent={Math.round(progress)} strokeColor={twoColors} />;
            },
            width: 180,
        },        
        {
            title: 'Activities',
            key: 'activities',
            render: (_, record) => (
                <ul>
                    {record.activities.map(activity => (
                        <li key={activity.id}>{activity.summary} ({activity.role_name})</li>
                    ))}
                </ul>
            ),
        }
    ];

    return (
        <div className='profile-container'>
            <div className='profile-left-part'>
                <Card>
                    <div className='profile-avatar'>
                        <img
                            className='profile-avatar-icon'
                            src="https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png"
                            alt="Profile"
                        />
                    </div>
                    <div className='profile-info'>
                        <div className='profile-name'>{user.display_name}</div>
                        <div>{user.role_name}</div>
                    </div>
                    <Divider />
                    <span>Technologies:</span>
                    <div className='profile-technologies'>
                        <Space size={[0, 8]} wrap>
                            <Tag>Salesforce</Tag>
                        </Space>
                    </div>
                </Card>
            </div>

            <div className='profile-right-part'>
                {isLoading ? (
                    <Spin size="large" />
                ) : (
                    <Table columns={columns} dataSource={tasks} rowKey="key" />
                )}
            </div>
        </div>
    );
};

export default Profile;