import { Row, Col } from 'antd';
import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import './LoginPage.css';
import Logo from "../../assets/logo-black.png";

const generateFallingElements = () => {
  return Array.from({ length: 30 }).map((_, i) => (
    <motion.div
      key={i}
      className="falling-element"
      initial={{ y: -50, opacity: 1, x: Math.random() * window.innerWidth }}
      animate={{ y: "100vh", opacity: 0 }}
      transition={{
        duration: Math.random() * 4 + 2,
        delay: Math.random() * 2,
        repeat: Infinity,
        ease: "linear",
      }}
    />
  ));
};

const LoginPage = (props) => {
  useEffect(() => {
    document.title = 'Authentication';
  }, []);

  const handleLogin = () => {
    props.authFunc();
  };

  return (
    <div className="login-container">
      <div className="falling-container">{generateFallingElements()}</div>
      
      <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
        <Col span={8} xs={24} sm={24} md={12} lg={8} xl={8}>
          <motion.div 
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="login-box"
          >
            <motion.span 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: 0.3 }}
              className="login-title"
            >
              TIMS
            </motion.span>
            <motion.button
              onClick={handleLogin}
              type="button"
              className="login-with-google-btn"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              Sign in with Google
            </motion.button>

            <motion.img 
              src={Logo} 
              alt='Logo' 
              className="login-logo"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6, delay: 0.5 }}
            />
          </motion.div>
        </Col>
      </Row>
    </div>
  );
};

export default LoginPage;
