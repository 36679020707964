import { useEffect, useMemo, useState, React } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { Drawer, Table } from "antd";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { data } from './makeData.ts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
// import { jsPDF } from 'jspdf';
// import autoTable from 'jspdf-autotable';
import axiosInstance from "../../config/axios";
import { Tag, Button } from 'antd';
import { DateTime } from 'luxon';
import CTLUsers from '../CTLUsers/CTLUsers.js'
import './CTL.css'

const CTL = () => {

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showLogsPopover, setShowLogsPopover] = useState(false);
  const [logsForDay, setLogsForDay] = useState([]);
  const [drawerTitle, setDrawerTitle] = useState('');
  const currentDate = new Date();
  const [selectedMonth, setSelectedMonth] = useState({
    month: currentDate.getMonth() + 1,
    year: currentDate.getFullYear(),
  });
  const [isCTLUsers, setIsCTLUsers] = useState(false);

  const fetchAllData = async () => {
    setIsLoading(true);
    console.log('month', selectedMonth.month);
    console.log('year', selectedMonth.year);
    const response = await axiosInstance.get(`/all?month=${selectedMonth.month}&year=${selectedMonth.year}`);
    // const response = await axiosInstance.get('/all');
    console.log('data', response.data);
    const tasksInCurrentMonth = response.data.map(task => {
      // Check if task.worklogs exists
      if (task.worklogs) {

        const worklogsInCurrentMonth = task.worklogs.filter(worklog => {
          const worklogDate = new Date(worklog.created_at);
          return (
            worklogDate.getMonth() === selectedMonth.month - 1 &&
            worklogDate.getFullYear() === selectedMonth.year
          );
        });

        const totalLogged = worklogsInCurrentMonth.reduce(
          (acc, worklog) => acc + parseFloat(worklog.time_spent_hours || 0),
          0
        );

        // Create a map to store day-specific worklogs and total time spent
        const dayDataMap = new Map();

        worklogsInCurrentMonth.forEach(worklog => {
          const dayOfMonth = new Date(worklog.created_at).getDate();
          const dayKey = `day_${dayOfMonth}`;

          // Initialize day-specific data if not already present
          if (!dayDataMap.has(dayKey)) {
            dayDataMap.set(dayKey, { worklogs: [], total: 0 });
          }

          // Add worklog data to the corresponding day
          dayDataMap.get(dayKey).worklogs.push(worklog);
          // Accumulate total time spent on the day
          dayDataMap.get(dayKey).total += parseFloat(worklog.time_spent_hours || 0);
        });
        console.log('dayDataMap', dayDataMap);
        // Add day-specific worklogs and total time spent to the task object
        const updatedTask = { ...task, logged: totalLogged };
        dayDataMap.forEach((dayData, dayKey) => {
          updatedTask[dayKey] = dayData.total || 0;
        });

        return updatedTask;
      }

      return task; // Return the task as is if worklogs is undefined or null
    });

    const totalLoggedAcrossTasks = tasksInCurrentMonth.reduce(
      (acc, task) => acc + parseFloat(task.logged || 0),
      0
    );

    setData(tasksInCurrentMonth);
    setIsLoading(false);
  }

  useEffect(() => {
    document.title = 'CTL';
    fetchAllData();
  }, [selectedMonth])

  const totalRemaining = useMemo(
    () => data.reduce((acc, curr) => acc + curr.remaining_budget, 0),
    [data],
  );

  const totalEstimate = useMemo(
    () => data.reduce((acc, curr) => acc + curr.total_estimate, 0),
    [data],
  );

  const totalLogged = useMemo(
    () => data.reduce((acc, curr) => acc + parseFloat(curr.logged || 0), 0),
    [data]
  );

  const handleData = () => {
    setIsCTLUsers(true);
  }

  const handleMonthChange = (change) => {
    setIsLoading(true);
    setSelectedMonth((prevMonth) => {
      let newMonth = prevMonth.month + change;
      let newYear = prevMonth.year;

      if (newMonth > 12) {
        newYear += 1;
        newMonth = 1;
      } else if (newMonth < 1) {
        newYear -= 1;
        newMonth = 12;
      }

      return {
        month: newMonth,
        year: newYear,
      };
    });
    // You may want to fetch data for the selected month when the user changes it
    // fetchAllData();
  };

  // Get the days of the current month
  const daysInMonth = useMemo(() => {
    const firstDayOfMonth = DateTime.local(selectedMonth.year, selectedMonth.month, 1);
    const lastDayOfMonth = firstDayOfMonth.endOf('month');
    return lastDayOfMonth.day;
  }, [selectedMonth.year, selectedMonth.month]);

  const handleDayCellClick = (logsForDay, dayId) => {
    if (logsForDay) {
      const match = dayId.match(/_(\d+)$/);
      let targetDay;
      if (match) {
        const extractedNumber = match[1];
        targetDay = parseInt(extractedNumber, 10);
      } else {
        console.log("No match found");
        return;
      }
      // Get the current month and year
      const currentDate = DateTime.now();
      const currentMonth = currentDate.month;
      const objectsWithTargetDay = logsForDay.filter(log => {
        const logDate = DateTime.fromISO(log.created_at);
        const logDay = logDate.day;
        const logMonth = logDate.month;

        // Check if the log's day and month match the target day and current month
        return logDay === targetDay && logMonth === currentMonth;
      });

      // Set the logs for the day in the state and show the modal
      setLogsForDay(objectsWithTargetDay);
      setShowLogsPopover(true);
      setDrawerTitle(<div style={{ display: "flex", justifyContent: 'space-between' }}><div>Logs</div><div>Day {targetDay}</div></div>);
    }
  }

  const handleChangeBoard = () => {
    setIsCTLUsers(false);
  }

  // Dynamically generate columns for each day of the month
  const dayColumns = useMemo(() => {
    console.log('daysInMonth', daysInMonth);
    return Array.from({ length: daysInMonth }, (_, index) => {
      const currentDate = new Date(selectedMonth.year, selectedMonth.month - 1, index + 1);
      const dayOfWeek = currentDate.toLocaleDateString('en-US', { weekday: 'short' });
      const date = currentDate.getDate();

      console.log('date', date);

      return {
        header: (
          <div>
            <div>{dayOfWeek}</div>
            <div style={{ color: '#1b91d1' }}>{date}</div>
          </div>
        ),
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          cursor: 'pointer',
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
        accessorKey: `day_${index}`,
        enablePinning: false,
        enableColumnResizing: false,
        enableDensityToggle: false,
        enableGrouping: false,
        enableSorting: false,
        enableResizing: false,
        enableColumnActions: false,
        enableColumnFilterModes: false,
        enableColumnFilter: false,
        size: 90,
        Cell: ({ cell }) => (
          <div className="hoverable-cell">
            {cell.getValue()}
          </div>
        ),
        aggregationFn: 'sum',
        AggregatedCell: ({ cell, table }) => (
          <div style={{ display: 'flex', gap: '4px' }}>
            <Box sx={{ display: 'inline', fontWeight: '500' }}>
              {cell.getValue()}
            </Box>
          </div>
        ),
        Footer: ({ table }) => {
          return table.getCoreRowModel().rows.reduce((total, row) => total + parseFloat(row.getValue(`day_${index}`) || 0), 0);
        },
      };
    });
  }, [daysInMonth]);

  const columns = useMemo(
    () => [

      {
        header: 'Build',
        accessorKey: 'build',
        enableClickToCopy: true,
        Cell: ({ cell }) => <div style={{ fontWeight: '400' }}>{cell.getValue()}</div>,
      },
      {
        header: 'Task',
        accessorKey: 'summary',
      },
      // {
      //   header: 'Task Type',
      //   accessorKey: 'task_type',
      // },
      {
        header: 'Status',
        accessorKey: 'status',
        enableGrouping: false,
        Cell: ({ cell }) => <div style={{ fontWeight: '400' }}>{cell.getValue() === 'Done' ? <Tag color="#87d068">{cell.getValue()}</Tag> : <Tag>{cell.getValue()}</Tag>}</div>,
      },
      {
        header: 'Estimate',
        accessorKey: 'total_estimate',
        enableGrouping: false,
        Footer: () => (
          <Stack style={{ fontFamily: 'Inter' }}>
            {totalEstimate}
            <Box color="warning.main"></Box>
          </Stack>
        ),
      },
      {
        header: 'Remaining Budget',
        accessorKey: 'remaining_budget',
        enableGrouping: false,
        aggregationFn: 'sum',
        AggregatedCell: ({ cell, table }) => (
          <>
            <div style={{ display: 'flex', gap: '4px' }}>
              <div>Total Rem. </div>
              {/* {table.getColumn(cell.row.groupingColumnId ?? '').columnDef.header}:{' '} */}
              <Box
                sx={{ color: 'info.main', display: 'inline', fontWeight: '500' }}
              >
                {<span style={{ color: cell.getValue() > 0 ? 'green' : 'red' }}>{cell.getValue()}</span>}
              </Box>
            </div>
          </>
        ),
        Footer: () => (
          <Stack style={{ fontFamily: 'Inter' }}>
            <div>
              {totalRemaining}
              <Box color="warning.main"></Box>
            </div>
          </Stack>
        ),
      },
      {
        header: 'Logged',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
        accessorKey: 'logged',
        enablePinning: false,
        enableColumnResizing: false,
        enableDensityToggle: false,
        enableGrouping: false,
        enableSorting: false,
        enableResizing: false,
        enableColumnActions: false,
        enableColumnFilterModes: false,
        enableColumnFilter: false,
        size: 75,
        Cell: ({ cell }) => <div style={{ fontWeight: '500' }}>{cell.getValue()}</div>,
        Footer: () => (
          <Stack style={{ fontFamily: 'Inter' }}>
            <div>
              {totalLogged}
              <Box color="warning.main"></Box>
            </div>
          </Stack>
        ),
      },
      ...dayColumns,
    ],
    [totalRemaining, totalEstimate, totalLogged, dayColumns],
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableColumnResizing: true,
    enableGrouping: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enablePagination: false,
    enableBottomToolbar: false,
    enableColumnPinning: true,
    enableDensityToggle: false,
    // enableRowSelection: true,
    columnFilterDisplayMode: 'popover',
    initialState: {
      density: 'compact',
      expanded: true,
      // grouping: ['build'],
      // sorting: [{ id: 'state', desc: false }],
    },
    muiTableContainerProps: { sx: { maxHeight: 'calc(100vh - 200px)' } },

    muiTableBodyCellProps: ({ cell }) => ({
      sx: {
        borderRight: '1px solid rgba(224,224,224,1)',
        fontSize: '14px',
        fontFamily: 'Inter',
        // p: '2px 16px'
      },
      onClick: (event) => {
        handleDayCellClick(cell.row.original.worklogs, cell.id);
        console.log(cell);
      },
    }),
    muiTableHeadCellProps: {
      sx: {
        fontFamily: 'Inter',
        // borderRight: '1px solid rgba(224,224,224,1)',
      }
    },
    muiTableFooterCellProps: {
      sx: {
        fontFamily: 'Inter',
        borderRight: '1px solid rgba(224,224,224,1)',
        display: 'flex',
        justifyContent: 'center'
      }
    },
    muiToolbarAlertBannerProps: {
      sx: {
        fontSize: '16px',
        fontFamily: 'Inter',
      },
    },
    muiToolbarAlertBannerChipProps: {
      sx: {
        fontSize: '16px',
        fontFamily: 'Inter',
      },
    },
    muiTablePaperProps: {
      elevation: 0,
    },
    state: {
      isLoading: isLoading
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          flexWrap: 'wrap',
        }}
      >
        <Box>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <div>
              <Button onClick={() => handleMonthChange(-1)}>
                <FontAwesomeIcon icon={faArrowLeft} style={{
                  cursor: 'pointer'
                }} />
              </Button>
            </div>
            <div style={{ fontSize: '16px' }}>
              {DateTime.fromObject(selectedMonth).toLocaleString({ month: 'long', year: 'numeric' })}
            </div>
            <div>
              <Button onClick={() => handleMonthChange(1)}>
                <FontAwesomeIcon icon={faArrowRight} style={{
                  cursor: 'pointer'
                }} />
              </Button>
            </div>
          </div>
        </Box>
        <Button
          variant='text'
          onClick={() =>
            handleData()
          }
        >
          SWITCH TO TASKS/ACTIVITIES
        </Button>
      </Box >
    ),
  });

  const columnsForLogsTable = [
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text) => DateTime.fromISO(text, { zone: Intl.DateTimeFormat().resolvedOptions().timeZone }).toFormat('yyyy-MM-dd'),
      width: 30
    },
    {
      title: 'User',
      dataIndex: 'display_name',
      key: 'display_name',
      // render: (name) => {
      //   <div style={{ fontWeight: '500' }}>{name}</div>
      // },
      width: 70
    },
    {
      title: 'Hours',
      dataIndex: 'time_spent_hours',
      key: 'time_spent_hours',
      width: 30
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      key: 'comment',
      width: 180
    },
    // Add more columns as needed based on your log structure
  ];

  return <>
    {!isCTLUsers ? (
      <div>
        <MaterialReactTable table={table}></MaterialReactTable>
        {logsForDay.length > 0 && (
          <Drawer
            title={drawerTitle}
            placement="right"
            onClose={() => setShowLogsPopover(false)}
            open={showLogsPopover}
            width={750}
          >
            <Table
              dataSource={logsForDay}
              columns={columnsForLogsTable}
              rowKey="id"
              size='small'
              pagination={false} // Disable pagination if not needed
            />
          </Drawer>
        )}
      </div>
    )
      :
      <div>
        <CTLUsers changeBoard={handleChangeBoard} />
      </div>
    }
  </>;
};

export default CTL;