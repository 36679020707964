import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './Editor.css';
import { Tag } from 'antd';

const modules = {
    toolbar: {
        container: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'link'],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['image'],
            ['clean']
        ],
    },
};

const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'link',
    'color', 'background',
    'script', 'align',
    'list', 'bullet',
    'image', 'video',
];

const initValue = `<p><br></p><p><br></p><p><br></p><p><br></p><p><br></p><p><br></p>`;

function Editor(props) {
    const [editorState, setEditorState] = useState({
        credentials: props.credentials || initValue,
        summary: props.summary || initValue,
        documents: props.documents || initValue,
    });
    const [isLoading, setIsLoading] = useState(true);

    // useEffect(() => {
    //     if (props.fileList.length > 0) {
    //         const filesHtml = props.fileList.map(file => `<span style="font-weight: bold; color: green;">${file.name}</span>`).join('<br>');
    //         setEditorState(prevState => ({
    //             ...prevState,
    //             documents: prevState.documents === initValue ? initValue + filesHtml : prevState.documents,
    //         }));
    //     } else {
    //         setEditorState(prevState => ({
    //             ...prevState,
    //             documents: initValue,
    //         }));
    //     }
    // }, [props.fileList]);

    useEffect(() => {
        setIsLoading(props.isLoading);
    }, [props.isLoading]);

    function handleCredentialsChange(value) {
        setEditorState(prevState => ({
            ...prevState,
            credentials: value,
        }));
        props.onCredentialsChange(value);
    }

    function handleSummaryChange(value) {
        setEditorState(prevState => ({
            ...prevState,
            summary: value,
        }));
        props.onSummaryChange(value);
    }

    function handleDocumentsChange(value) {
        setEditorState(prevState => ({
            ...prevState,
            documents: value,
        }));
        props.onDocumentsChange(value);
    }

    return (
        <div>
            {!isLoading &&
                <div style={{ fontFamily: 'Inter' }}>
                    <div>
                        <div className='titleDesc'>Credentials</div>
                        <ReactQuill
                            value={editorState.credentials}
                            onChange={handleCredentialsChange}
                            modules={modules}
                            formats={formats}
                            readOnly={(props.user.role_id === 1)}
                        />
                    </div>

                    <div style={{ marginTop: '8px' }}>
                        <div className='titleDesc'>Summary</div>
                        <ReactQuill
                            value={editorState.summary}
                            onChange={handleSummaryChange}
                            modules={modules}
                            formats={formats}
                            readOnly={(props.user.role_id === 1)}
                        />
                    </div>

                    <div style={{ marginTop: '8px' }}>
                        <div className="titleDesc">Documents</div>
                        <ReactQuill
                            value={editorState.documents}
                            onChange={handleDocumentsChange}
                            modules={modules}
                            formats={formats}
                            readOnly={(props.user.role_id === 1)}
                        />
                    </div>
                </div>}
        </div>
    );
}

export default Editor;