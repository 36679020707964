import React, { useEffect, useState } from 'react';
import { Avatar, ConfigProvider, Dropdown, Menu, Modal, Table } from 'antd';
import { DeleteOutlined, DownOutlined, EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import EditTaskModal from '../EditModal/EditModal';
import ServiceWorklogs from '../../services/worklogs.js';
import ServiceTickets from '../../services/tickets.js';
import dayjs from 'dayjs';

const items = [
    {
        key: '1',
        label: (
            <div>
                Edit
            </div>
        ),
        icon: <EditOutlined />,
    },
    {
        key: '2',
        danger: true,
        label: 'Delete',
        icon: <DeleteOutlined />,
    },
];

const getInitials = (fullName) => {
    return fullName
        .split(' ')
        .map(word => word[0])
        .join('')
        .toUpperCase();
};

const TempoModal = ({ isVisible, content, handleOk, handleCancel, loading, onDataChange }) => {
    const [selectedLog, setSelectedLog] = useState(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [tasks, setTasks] = useState([]);
    const [logDate, setDate] = useState(null);
    const [modalContent, setModalContent] = useState(content || { logs: [] });
    const [modalLoading, setModalLoading] = useState(loading);

    const fetchLogsForDate = async (date) => {
        try {
            const logs = (await ServiceWorklogs.getWorklogsForDate(date)).data;
            setModalContent({ logs });
            setModalLoading(false);
            onDataChange(logs);
        } catch (error) {
            console.error('Error fetching logs:', error);
            setModalContent({ logs: [] });
        }
    };

    useEffect(() => {
        setModalContent(content || { logs: [] });
        const fetchData = async () => {
            try {
                const tasks = await ServiceTickets.getUserTasks();
                setTasks(tasks.data);
            } catch (error) {
                console.error('Error fetching tasks:', error);
            }
        };
        fetchData();
    }, [content]);

    if (!modalContent?.logs) {
        return null;
    }

    const editLog = (log) => {
        setModalLoading(true);
        ServiceWorklogs.updateLog(log)
            .then((response) => {
                console.log('Log updated successfully:', response.data);
            })
            .finally(() => {
                fetchLogsForDate(log.createdDate);
            })
            .catch((error) => {
                console.error('Failed to update log:', error);
            });
    };

    const handleDeleteLog = (id, logDate) => {
        ServiceWorklogs.deleteLog(id)
            .then(() => {
                if (logDate) {
                    fetchLogsForDate(logDate);
                }
            })
            .catch((error) => {
                console.error('Failed to remove log:', error);
            });
    };    

    const handleEdit = (log) => {
        setSelectedLog(log);
        setDate(log.date);
        setIsEditModalOpen(true);
    };

    function isOpenHandler(isOpen) {
        setIsEditModalOpen(isOpen);
    }

    const handleEditModalClose = () => {
        setIsEditModalOpen(false);
        setSelectedLog(null);
    };

    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (text) => <span>{text}</span>,
        },
        {
            title: 'Logged',
            dataIndex: 'time_spent_hours',
            key: 'logged',
            render: (logged) => <span>{logged}h</span>,
        },
        {
            title: 'User',
            dataIndex: 'user',
            key: 'user',
            render: (user) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar style={{ backgroundColor: '#6dbafa' }} size='small'>{getInitials(user)}</Avatar>
                    <span style={{ marginLeft: 8, fontWeight: '700' }}>{user}</span>
                </div>
            ),
        },
        {
            title: 'Task',
            dataIndex: 'task_key',
            key: 'task_key',
            render: (task) => (
                <span>
                    <Link to={`/task/${task}`} style={{ fontWeight: '500' }}>
                        {task}
                    </Link>
                </span>
            ),
        },
        {
            title: 'Activity',
            dataIndex: 'activity',
            key: 'activity',
            render: (activity) => (
                <span>
                    <i>{activity}</i>
                </span>
            ),
        },
        {
            title: 'Description',
            dataIndex: 'comment',
            key: 'comment',
            render: (comment) => (
                <span>
                    {comment.length > 50 ? `${comment.substring(0, 50)}...` : comment}
                </span>
            ),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <Dropdown
                    overlay={
                        <Menu onClick={({ key }) => {
                            if (key === '1') {
                                handleEdit(record);
                            } else if (key === '2') {
                                handleDeleteLog(record.id, record.date);
                            }
                        }}>
                            <Menu.Item key="1" icon={<EditOutlined />}>Edit</Menu.Item>
                            <Menu.Item key="2" icon={<DeleteOutlined />} danger>Delete</Menu.Item>
                        </Menu>
                    }
                    trigger={['click']}
                >
                    <a onClick={(e) => e.preventDefault()}>
                        Options <DownOutlined />
                    </a>
                </Dropdown>
            ),
        },
    ];

    const dataSource = modalContent.logs.map((item, index) => ({
        key: index,
        id: item.id,
        date: item.created_at,
        user: item.display_name,
        task_key: item.task_key,
        activity: item.activity_name,
        activity_id: item.activity_id,
        comment: item.comment,
        time_spent_hours: item.time_spent_hours,
        user_id: item.user_id
    }));

    return (
        <ConfigProvider
            theme={{
                components: {
                    Table: {
                        headerBg: 'transparent'
                    },
                },
            }}
        >
            <Modal
                title={<p>Logs</p>}
                open={isVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                width={1100}
                loading={modalLoading || loading}
            >
                <Table
                    columns={columns}
                    dataSource={dataSource}
                    pagination={false}
                />
            </Modal>

            {isEditModalOpen && selectedLog && (
                <EditTaskModal
                    isOpen={isEditModalOpen}
                    log={selectedLog}
                    isOpenChange={isOpenHandler}
                    date={logDate}
                    onClose={handleEditModalClose}
                    label={'Edit Modal'}
                    isEditModal={true}
                    onEditLog={editLog}
                    tasks={tasks}
                />
            )}
        </ConfigProvider>
    );
};

export default TempoModal;