export type Person = {
    firstName: string;
    lastName: string;
    age: number;
    gender: string;
    state: string;
    salary: number;
  };
  
  export const data: Person[] = [
    {
      firstName: 'Danika',
      lastName: 'Rodriguez',
      age: 57,
      gender: 'Male',
      state: 'Utah',
      salary: 31404,
    },
    {
      firstName: 'Eloisa',
      lastName: 'Kohler',
      age: 31,
      gender: 'Male',
      state: 'Alaska',
      salary: 45801,
    },
    {
      firstName: 'Gunner',
      lastName: 'Rolfson',
      age: 22,
      gender: 'Male',
      state: 'Arizona',
      salary: 54027,
    },
    {
      firstName: 'Andreanne',
      lastName: 'Hamill',
      age: 65,
      gender: 'Female',
      state: 'Hawaii',
      salary: 60975,
    },
    {
      firstName: 'Kali',
      lastName: 'Jones',
      age: 56,
      gender: 'Female',
      state: 'Kentucky',
      salary: 27067,
    },
    {
      firstName: 'Felicia',
      lastName: 'Mitchell',
      age: 34,
      gender: 'Male',
      state: 'South Carolina',
      salary: 48423,
    },
    {
      firstName: 'Philip',
      lastName: 'Koepp',
      age: 32,
      gender: 'Male',
      state: 'Louisiana',
      salary: 36713,
    },
    {
      firstName: 'Aniya',
      lastName: 'Mante',
      age: 41,
      gender: 'Female',
      state: 'Minnesota',
      salary: 77658,
    },
    {
      firstName: 'Nelda',
      lastName: 'Gottlieb',
      age: 41,
      gender: 'Female',
      state: 'Delaware',
      salary: 57634,
    },
    {
      firstName: 'Marty',
      lastName: 'Kihn',
      age: 19,
      gender: 'Male',
      state: 'Delaware',
      salary: 95427,
    },
    {
      firstName: 'Alvis',
      lastName: 'Turcotte',
      age: 53,
      gender: 'Female',
      state: 'Wyoming',
      salary: 14272,
    },
    {
      firstName: 'Kavon',
      lastName: 'Spencer',
      age: 37,
      gender: 'Female',
      state: 'Missouri',
      salary: 79499,
    },
    {
      firstName: 'Winifred',
      lastName: 'Wilderman',
      age: 56,
      gender: 'Female',
      state: 'Iowa',
      salary: 96293,
    },
    {
      firstName: 'Brooklyn',
      lastName: 'Monahan',
      age: 35,
      gender: 'Female',
      state: 'Nevada',
      salary: 13219,
    },
    {
      firstName: 'Santino',
      lastName: 'Brown',
      age: 33,
      gender: 'Male',
      state: 'Mississippi',
      salary: 36361,
    },
    {
      firstName: 'Bianka',
      lastName: 'Yost',
      age: 28,
      gender: 'Female',
      state: 'Wisconsin',
      salary: 89057,
    },
    {
      firstName: 'Herman',
      lastName: 'Herzog',
      age: 63,
      gender: 'Female',
      state: 'South Carolina',
      salary: 28110,
    },
    {
      firstName: 'Earl',
      lastName: 'Gottlieb',
      age: 65,
      gender: 'Female',
      state: 'Florida',
      salary: 31400,
    },
    {
      firstName: 'Valentine',
      lastName: 'Bauch',
      age: 61,
      gender: 'Female',
      state: 'North Carolina',
      salary: 35130,
    },
    {
      firstName: 'Joanny',
      lastName: 'Koss',
      age: 23,
      gender: 'Male',
      state: 'Colorado',
      salary: 90422,
    },
    {
      firstName: 'Aliza',
      lastName: 'Wilkinson',
      age: 25,
      gender: 'Female',
      state: 'Virginia',
      salary: 98417,
    },
    {
      firstName: 'Eldred',
      lastName: 'Collier',
      age: 37,
      gender: 'Male',
      state: 'Mississippi',
      salary: 43741,
    },
    {
      firstName: 'Dorris',
      lastName: 'Koch',
      age: 37,
      gender: 'Male',
      state: 'Delaware',
      salary: 40979,
    },
    {
      firstName: 'Felix',
      lastName: 'Zieme',
      age: 51,
      gender: 'Male',
      state: 'Ohio',
      salary: 55319,
    },
    {
      firstName: 'Salma',
      lastName: 'Treutel',
      age: 32,
      gender: 'Female',
      state: 'Pennsylvania',
      salary: 31722,
    },
    {
      firstName: 'Durward',
      lastName: 'Kris',
      age: 36,
      gender: 'Male',
      state: 'Iowa',
      salary: 28486,
    },
    {
      firstName: 'Osborne',
      lastName: 'Barrows',
      age: 28,
      gender: 'Male',
      state: 'West Virginia',
      salary: 43996,
    },
    {
      firstName: 'Adelia',
      lastName: 'Abbott',
      age: 38,
      gender: 'Male',
      state: 'Oregon',
      salary: 34720,
    },
    {
      firstName: 'Thad',
      lastName: 'Wiegand',
      age: 64,
      gender: 'Female',
      state: 'Alabama',
      salary: 56146,
    },
    {
      firstName: 'Tiana',
      lastName: 'Tromp',
      age: 51,
      gender: 'Male',
      state: 'Arkansas',
      salary: 45636,
    },
    {
      firstName: 'Gwen',
      lastName: 'Bergstrom',
      age: 36,
      gender: 'Female',
      state: 'Wyoming',
      salary: 95145,
    },
    {
      firstName: 'Evalyn',
      lastName: 'Mitchell',
      age: 53,
      gender: 'Female',
      state: 'Illinois',
      salary: 86990,
    },
    {
      firstName: 'Edyth',
      lastName: 'Macejkovic',
      age: 55,
      gender: 'Female',
      state: 'Kentucky',
      salary: 65344,
    },
    {
      firstName: 'Lamar',
      lastName: 'Bechtelar',
      age: 58,
      gender: 'Female',
      state: 'Rhode Island',
      salary: 93753,
    },
    {
      firstName: 'Tillman',
      lastName: 'Jacobs',
      age: 36,
      gender: 'Female',
      state: 'New Mexico',
      salary: 13393,
    },
    {
      firstName: 'Turner',
      lastName: 'Haley',
      age: 56,
      gender: 'Female',
      state: 'Nebraska',
      salary: 93733,
    },
    {
      firstName: 'Omer',
      lastName: 'Funk',
      age: 28,
      gender: 'Female',
      state: 'Iowa',
      salary: 77913,
    },
    {
      firstName: 'Alivia',
      lastName: 'Ledner',
      age: 56,
      gender: 'Male',
      state: 'Alabama',
      salary: 12591,
    },
    {
      firstName: 'Dortha',
      lastName: 'Schmitt',
      age: 43,
      gender: 'Male',
      state: 'Mississippi',
      salary: 80347,
    },
    {
      firstName: 'Ellie',
      lastName: 'Schinner',
      age: 41,
      gender: 'Female',
      state: 'Rhode Island',
      salary: 42232,
    },
    {
      firstName: 'Pansy',
      lastName: 'Reilly',
      age: 55,
      gender: 'Male',
      state: 'Louisiana',
      salary: 87255,
    },
    {
      firstName: 'Alfonzo',
      lastName: 'Abernathy',
      age: 40,
      gender: 'Male',
      state: 'Utah',
      salary: 53374,
    },
    {
      firstName: 'Carmine',
      lastName: 'Orn',
      age: 24,
      gender: 'Female',
      state: 'Georgia',
      salary: 89363,
    },
    {
      firstName: 'Taylor',
      lastName: 'Herzog',
      age: 63,
      gender: 'Male',
      state: 'Florida',
      salary: 81429,
    },
    {
      firstName: 'Ray',
      lastName: 'Little',
      age: 27,
      gender: 'Female',
      state: 'Maine',
      salary: 53736,
    },
    {
      firstName: 'Melyssa',
      lastName: 'Bechtelar',
      age: 56,
      gender: 'Male',
      state: 'New Mexico',
      salary: 85152,
    },
    {
      firstName: 'Coy',
      lastName: 'Schoen',
      age: 32,
      gender: 'Male',
      state: 'Florida',
      salary: 83999,
    },
    {
      firstName: 'Lexi',
      lastName: 'Reinger',
      age: 39,
      gender: 'Female',
      state: 'Virginia',
      salary: 17585,
    },
    {
      firstName: 'Carlee',
      lastName: 'Turcotte',
      age: 25,
      gender: 'Male',
      state: 'Missouri',
      salary: 85236,
    },
    {
      firstName: 'Tamia',
      lastName: 'Stamm',
      age: 47,
      gender: 'Female',
      state: 'Nevada',
      salary: 65037,
    },
    {
      firstName: 'Leola',
      lastName: 'Donnelly',
      age: 30,
      gender: 'Female',
      state: 'Missouri',
      salary: 23897,
    },
    {
      firstName: 'Allison',
      lastName: 'Stanton',
      age: 34,
      gender: 'Male',
      state: 'New Hampshire',
      salary: 34389,
    },
    {
      firstName: 'Neoma',
      lastName: 'Flatley',
      age: 65,
      gender: 'Female',
      state: 'Connecticut',
      salary: 43669,
    },
    {
      firstName: 'Brad',
      lastName: 'Altenwerth',
      age: 18,
      gender: 'Male',
      state: 'Virginia',
      salary: 85599,
    },
    {
      firstName: 'Magdalen',
      lastName: 'Berge',
      age: 58,
      gender: 'Female',
      state: 'Louisiana',
      salary: 65571,
    },
    {
      firstName: 'Kathryn',
      lastName: 'Kirlin',
      age: 48,
      gender: 'Female',
      state: 'New Hampshire',
      salary: 97434,
    },
    {
      firstName: 'Rod',
      lastName: 'Sawayn',
      age: 19,
      gender: 'Female',
      state: 'Nevada',
      salary: 58951,
    },
    {
      firstName: 'Georgette',
      lastName: 'Beatty',
      age: 53,
      gender: 'Male',
      state: 'Kentucky',
      salary: 12203,
    },
    {
      firstName: 'Dianna',
      lastName: 'King',
      age: 23,
      gender: 'Female',
      state: 'Vermont',
      salary: 13515,
    },
    {
      firstName: 'Kellie',
      lastName: 'Parker',
      age: 43,
      gender: 'Trans Male',
      state: 'Louisiana',
      salary: 49457,
    },
    {
      firstName: 'Faustino',
      lastName: 'Ankunding',
      age: 43,
      gender: 'Male',
      state: 'Indiana',
      salary: 49943,
    },
    {
      firstName: 'Hilton',
      lastName: 'Pacocha',
      age: 58,
      gender: 'Male',
      state: 'South Dakota',
      salary: 34874,
    },
    {
      firstName: 'Shanel',
      lastName: 'Schneider',
      age: 33,
      gender: 'Female',
      state: 'Nebraska',
      salary: 50446,
    },
    {
      firstName: 'Wade',
      lastName: 'Kiehn',
      age: 23,
      gender: 'Male',
      state: 'Delaware',
      salary: 29522,
    },
    {
      firstName: 'Austyn',
      lastName: 'Rempel',
      age: 35,
      gender: 'Female',
      state: 'Arkansas',
      salary: 42997,
    },
    {
      firstName: 'Maggie',
      lastName: 'Keebler',
      age: 20,
      gender: 'Female',
      state: 'California',
      salary: 11588,
    },
    {
      firstName: 'Ruby',
      lastName: 'Kertzmann',
      age: 40,
      gender: 'Female',
      state: 'Missouri',
      salary: 45860,
    },
    {
      firstName: 'Lilyan',
      lastName: 'Pfannerstill',
      age: 37,
      gender: 'Cisgender',
      state: 'Washington',
      salary: 37347,
    },
    {
      firstName: 'Fletcher',
      lastName: 'Huels',
      age: 65,
      gender: 'Female',
      state: 'Colorado',
      salary: 11596,
    },
    {
      firstName: 'Danyka',
      lastName: 'Gleason',
      age: 36,
      gender: 'Male',
      state: 'Alabama',
      salary: 71238,
    },
    {
      firstName: 'Tressa',
      lastName: 'McCullough',
      age: 64,
      gender: 'Female',
      state: 'Virginia',
      salary: 47028,
    },
    {
      firstName: 'Heber',
      lastName: 'Bernhard',
      age: 37,
      gender: 'Cisgender',
      state: 'California',
      salary: 11268,
    },
    {
      firstName: 'Damon',
      lastName: 'Barrows',
      age: 20,
      gender: 'Female',
      state: 'West Virginia',
      salary: 22796,
    },
    {
      firstName: 'Willy',
      lastName: 'Koss',
      age: 35,
      gender: 'Female',
      state: 'Colorado',
      salary: 88346,
    },
    {
      firstName: 'Carley',
      lastName: 'Block',
      age: 54,
      gender: 'Female',
      state: 'Michigan',
      salary: 32934,
    },
    {
      firstName: 'Randy',
      lastName: 'Powlowski',
      age: 31,
      gender: 'Female',
      state: 'Mississippi',
      salary: 68186,
    },
    {
      firstName: 'Rubye',
      lastName: 'Weissnat',
      age: 43,
      gender: 'Female',
      state: 'South Carolina',
      salary: 19377,
    },
    {
      firstName: 'Gloria',
      lastName: 'Green',
      age: 54,
      gender: 'Male',
      state: 'Georgia',
      salary: 85559,
    },
    {
      firstName: 'Kian',
      lastName: 'Hand',
      age: 56,
      gender: 'Male',
      state: 'Alaska',
      salary: 81062,
    },
    {
      firstName: 'Adelia',
      lastName: 'Weber',
      age: 29,
      gender: 'Male',
      state: 'Wyoming',
      salary: 62704,
    },
    {
      firstName: 'Clemmie',
      lastName: 'Nienow',
      age: 64,
      gender: 'Male',
      state: 'New Hampshire',
      salary: 84951,
    },
    {
      firstName: 'Buck',
      lastName: 'Ankunding',
      age: 25,
      gender: 'Male',
      state: 'Kentucky',
      salary: 88189,
    },
    {
      firstName: 'Abbey',
      lastName: 'Kulas',
      age: 36,
      gender: 'Female',
      state: 'Oklahoma',
      salary: 62902,
    },
    {
      firstName: 'Godfrey',
      lastName: 'Beahan',
      age: 43,
      gender: 'Female',
      state: 'Georgia',
      salary: 85231,
    },
    {
      firstName: 'Jayde',
      lastName: 'Friesen',
      age: 60,
      gender: 'Male',
      state: 'Minnesota',
      salary: 68939,
    },
    {
      firstName: 'Kaela',
      lastName: 'Bernhard',
      age: 51,
      gender: 'Female',
      state: 'Virginia',
      salary: 61567,
    },
    {
      firstName: 'Jerome',
      lastName: 'Jast',
      age: 22,
      gender: 'Male',
      state: 'Wisconsin',
      salary: 47210,
    },
    {
      firstName: 'Lawrence',
      lastName: 'Steuber',
      age: 39,
      gender: 'Male',
      state: 'Mississippi',
      salary: 99291,
    },
    {
      firstName: 'Deon',
      lastName: 'Romaguera',
      age: 53,
      gender: 'Female',
      state: 'Vermont',
      salary: 98288,
    },
    {
      firstName: 'Jena',
      lastName: "O'Hara",
      age: 41,
      gender: 'Female',
      state: 'Iowa',
      salary: 40063,
    },
    {
      firstName: 'Ima',
      lastName: 'Bartell',
      age: 62,
      gender: 'Female',
      state: 'Rhode Island',
      salary: 72570,
    },
    {
      firstName: 'Antonina',
      lastName: 'McCullough',
      age: 59,
      gender: 'Male',
      state: 'Nebraska',
      salary: 95189,
    },
    {
      firstName: 'Steve',
      lastName: 'Gislason',
      age: 41,
      gender: 'Male',
      state: 'Kentucky',
      salary: 93162,
    },
    {
      firstName: 'Carmela',
      lastName: 'Boyle',
      age: 49,
      gender: 'Male',
      state: 'Virginia',
      salary: 71890,
    },
    {
      firstName: 'Horacio',
      lastName: 'Murphy',
      age: 51,
      gender: 'Female',
      state: 'Vermont',
      salary: 32343,
    },
    {
      firstName: 'Bettie',
      lastName: "O'Hara",
      age: 40,
      gender: 'Female',
      state: 'North Dakota',
      salary: 60492,
    },
    {
      firstName: 'Rene',
      lastName: 'Gottlieb',
      age: 34,
      gender: 'Female',
      state: 'Florida',
      salary: 90192,
    },
    {
      firstName: 'Turner',
      lastName: 'Ward',
      age: 32,
      gender: 'Female',
      state: 'Tennessee',
      salary: 55249,
    },
    {
      firstName: 'Elaina',
      lastName: 'Orn',
      age: 30,
      gender: 'Female',
      state: 'Michigan',
      salary: 72501,
    },
    {
      firstName: 'Nayeli',
      lastName: 'Littel',
      age: 44,
      gender: 'Nonbinary',
      state: 'Maine',
      salary: 53740,
    },
    {
      firstName: 'Velda',
      lastName: 'Kuphal',
      age: 57,
      gender: 'Male',
      state: 'New York',
      salary: 93481,
    },
    {
      firstName: 'Loyce',
      lastName: 'Schmidt',
      age: 29,
      gender: 'Female',
      state: 'Alaska',
      salary: 76295,
    },
    {
      firstName: 'Oswaldo',
      lastName: 'DuBuque',
      age: 30,
      gender: 'Male',
      state: 'Ohio',
      salary: 55034,
    },
    {
      firstName: 'Kiarra',
      lastName: 'Dooley',
      age: 20,
      gender: 'Male',
      state: 'Nebraska',
      salary: 60415,
    },
    {
      firstName: 'Ashleigh',
      lastName: 'Fahey',
      age: 56,
      gender: 'Female',
      state: 'New Mexico',
      salary: 28186,
    },
    {
      firstName: 'Jordyn',
      lastName: 'Weissnat',
      age: 49,
      gender: 'Female',
      state: 'North Dakota',
      salary: 81663,
    },
    {
      firstName: 'Maureen',
      lastName: 'Swift',
      age: 63,
      gender: 'Male',
      state: 'Ohio',
      salary: 53538,
    },
    {
      firstName: 'Cathryn',
      lastName: 'Spinka',
      age: 42,
      gender: 'Female',
      state: 'New Hampshire',
      salary: 14586,
    },
    {
      firstName: 'Xzavier',
      lastName: 'Raynor',
      age: 37,
      gender: 'Male',
      state: 'California',
      salary: 60776,
    },
    {
      firstName: 'Alexander',
      lastName: 'Kris',
      age: 63,
      gender: 'Female',
      state: 'North Carolina',
      salary: 85926,
    },
    {
      firstName: 'Golda',
      lastName: 'McLaughlin',
      age: 41,
      gender: 'Cisgender',
      state: 'Kansas',
      salary: 49250,
    },
    {
      firstName: 'Delbert',
      lastName: 'Jacobson',
      age: 40,
      gender: 'Female',
      state: 'New Hampshire',
      salary: 58482,
    },
    {
      firstName: 'Cordia',
      lastName: 'Hermann',
      age: 45,
      gender: 'Female',
      state: 'Mississippi',
      salary: 29543,
    },
    {
      firstName: 'Brennon',
      lastName: 'Smith',
      age: 39,
      gender: 'Nonbinary',
      state: 'Missouri',
      salary: 65193,
    },
    {
      firstName: 'Kraig',
      lastName: 'Moen',
      age: 59,
      gender: 'Male',
      state: 'West Virginia',
      salary: 52677,
    },
    {
      firstName: 'Lionel',
      lastName: 'Hartmann',
      age: 30,
      gender: 'Nonbinary',
      state: 'Alabama',
      salary: 58743,
    },
    {
      firstName: 'Terrill',
      lastName: 'Feest',
      age: 61,
      gender: 'Female',
      state: 'West Virginia',
      salary: 97637,
    },
    {
      firstName: 'Jaylin',
      lastName: 'Murazik',
      age: 56,
      gender: 'Male',
      state: 'Wyoming',
      salary: 68290,
    },
    {
      firstName: 'Tyrique',
      lastName: 'Emard',
      age: 55,
      gender: 'Male',
      state: 'New Hampshire',
      salary: 23052,
    },
    {
      firstName: 'Shanelle',
      lastName: 'Hilll',
      age: 35,
      gender: 'Male',
      state: 'New Mexico',
      salary: 66504,
    },
    {
      firstName: 'Antwan',
      lastName: 'Zieme',
      age: 21,
      gender: 'Male',
      state: 'Utah',
      salary: 56638,
    },
    {
      firstName: 'Kyler',
      lastName: 'Schmeler',
      age: 19,
      gender: 'Female',
      state: 'Oklahoma',
      salary: 73721,
    },
    {
      firstName: 'Colt',
      lastName: 'Wiegand',
      age: 64,
      gender: 'Male',
      state: 'New York',
      salary: 69024,
    },
    {
      firstName: 'Paul',
      lastName: 'Leannon',
      age: 21,
      gender: 'Male',
      state: 'New York',
      salary: 67575,
    },
    {
      firstName: 'Magnus',
      lastName: 'Ortiz',
      age: 53,
      gender: 'Female',
      state: 'Ohio',
      salary: 18489,
    },
    {
      firstName: 'Kathryn',
      lastName: 'Langworth',
      age: 39,
      gender: 'Male',
      state: 'Utah',
      salary: 25720,
    },
    {
      firstName: 'Zelma',
      lastName: 'Grimes',
      age: 42,
      gender: 'Male',
      state: 'Texas',
      salary: 94083,
    },
    {
      firstName: 'Eddie',
      lastName: 'Paucek',
      age: 40,
      gender: 'Nonbinary',
      state: 'Michigan',
      salary: 96538,
    },
    {
      firstName: 'Tressa',
      lastName: 'Cartwright',
      age: 26,
      gender: 'Female',
      state: 'Maine',
      salary: 38495,
    },
    {
      firstName: 'Dawn',
      lastName: 'Huels',
      age: 57,
      gender: 'Male',
      state: 'New Jersey',
      salary: 41603,
    },
    {
      firstName: 'Pinkie',
      lastName: 'Rau',
      age: 30,
      gender: 'Female',
      state: 'New Jersey',
      salary: 27073,
    },
    {
      firstName: 'Macie',
      lastName: 'Reynolds',
      age: 18,
      gender: 'Male',
      state: 'Massachusetts',
      salary: 86581,
    },
    {
      firstName: 'Bertrand',
      lastName: 'Cummings',
      age: 62,
      gender: 'Female',
      state: 'Illinois',
      salary: 96908,
    },
    {
      firstName: 'Dylan',
      lastName: 'Wolf',
      age: 65,
      gender: 'Male',
      state: 'New York',
      salary: 75557,
    },
    {
      firstName: 'Elsa',
      lastName: 'Lockman',
      age: 36,
      gender: 'Female',
      state: 'Colorado',
      salary: 30586,
    },
    {
      firstName: 'Haylee',
      lastName: 'Price',
      age: 57,
      gender: 'Male',
      state: 'Utah',
      salary: 59047,
    },
    {
      firstName: 'Harold',
      lastName: 'Stracke',
      age: 49,
      gender: 'Female',
      state: 'Indiana',
      salary: 99744,
    },
    {
      firstName: 'Kelly',
      lastName: 'Monahan',
      age: 20,
      gender: 'Male',
      state: 'Indiana',
      salary: 64408,
    },
    {
      firstName: 'Lee',
      lastName: "O'Hara",
      age: 38,
      gender: 'Male',
      state: 'Washington',
      salary: 23136,
    },
    {
      firstName: 'Trevor',
      lastName: 'Beier',
      age: 65,
      gender: 'Female',
      state: 'New York',
      salary: 19235,
    },
    {
      firstName: 'Theron',
      lastName: 'Windler',
      age: 32,
      gender: 'Male',
      state: 'North Dakota',
      salary: 52825,
    },
    {
      firstName: 'Jennings',
      lastName: 'Halvorson',
      age: 34,
      gender: 'Male',
      state: 'New York',
      salary: 24737,
    },
    {
      firstName: 'Charity',
      lastName: 'Kuvalis',
      age: 40,
      gender: 'Male',
      state: 'Mississippi',
      salary: 60813,
    },
    {
      firstName: 'Kaitlyn',
      lastName: 'Maggio',
      age: 23,
      gender: 'Female',
      state: 'Indiana',
      salary: 74616,
    },
    {
      firstName: 'Reymundo',
      lastName: 'Osinski',
      age: 45,
      gender: 'Female',
      state: 'New Mexico',
      salary: 50978,
    },
    {
      firstName: 'Glen',
      lastName: 'Kub',
      age: 47,
      gender: 'Male',
      state: 'Vermont',
      salary: 85742,
    },
    {
      firstName: 'Reinhold',
      lastName: 'Reichel',
      age: 30,
      gender: 'Female',
      state: 'Alabama',
      salary: 30531,
    },
    {
      firstName: 'Theresia',
      lastName: 'Wyman',
      age: 50,
      gender: 'Female',
      state: 'Kentucky',
      salary: 49835,
    },
    {
      firstName: 'Lurline',
      lastName: 'Koepp',
      age: 59,
      gender: 'Female',
      state: 'Alabama',
      salary: 10645,
    },
    {
      firstName: 'Elise',
      lastName: 'Schimmel',
      age: 42,
      gender: 'Male',
      state: 'Illinois',
      salary: 80267,
    },
    {
      firstName: 'Maeve',
      lastName: 'Mante',
      age: 54,
      gender: 'Male',
      state: 'Massachusetts',
      salary: 34713,
    },
    {
      firstName: 'Michale',
      lastName: 'Collier',
      age: 59,
      gender: 'Male',
      state: 'Alaska',
      salary: 75197,
    },
    {
      firstName: 'Adell',
      lastName: 'Champlin',
      age: 37,
      gender: 'Female',
      state: 'Tennessee',
      salary: 80245,
    },
    {
      firstName: 'Cyril',
      lastName: 'Hahn',
      age: 48,
      gender: 'Male',
      state: 'Louisiana',
      salary: 76679,
    },
    {
      firstName: 'Josiah',
      lastName: 'Durgan',
      age: 50,
      gender: 'Female',
      state: 'North Carolina',
      salary: 76309,
    },
    {
      firstName: 'Rene',
      lastName: 'Weber',
      age: 58,
      gender: 'Female',
      state: 'Mississippi',
      salary: 51097,
    },
    {
      firstName: 'Stacey',
      lastName: 'Pouros',
      age: 34,
      gender: 'Male',
      state: 'Minnesota',
      salary: 71566,
    },
    {
      firstName: 'Kody',
      lastName: 'Braun',
      age: 38,
      gender: 'Female',
      state: 'Alabama',
      salary: 63733,
    },
    {
      firstName: 'Maritza',
      lastName: 'Bartell',
      age: 45,
      gender: 'Female',
      state: 'Idaho',
      salary: 14581,
    },
    {
      firstName: 'Abbey',
      lastName: 'Fay',
      age: 30,
      gender: 'Male',
      state: 'New Mexico',
      salary: 21372,
    },
    {
      firstName: 'Duncan',
      lastName: 'Carroll',
      age: 50,
      gender: 'Male',
      state: 'Washington',
      salary: 65996,
    },
    {
      firstName: 'Eldridge',
      lastName: 'Stroman',
      age: 42,
      gender: 'Male',
      state: 'Alaska',
      salary: 59594,
    },
    {
      firstName: 'Roberta',
      lastName: 'Bashirian',
      age: 38,
      gender: 'Male',
      state: 'Nevada',
      salary: 91870,
    },
    {
      firstName: 'Erwin',
      lastName: 'Kihn',
      age: 26,
      gender: 'Nonbinary',
      state: 'Louisiana',
      salary: 48803,
    },
    {
      firstName: 'Alberta',
      lastName: 'Considine',
      age: 37,
      gender: 'Male',
      state: 'Illinois',
      salary: 84906,
    },
    {
      firstName: 'Lexi',
      lastName: "O'Hara",
      age: 29,
      gender: 'Male',
      state: 'Virginia',
      salary: 72684,
    },
    {
      firstName: 'Alvera',
      lastName: 'Balistreri',
      age: 25,
      gender: 'Female',
      state: 'Alaska',
      salary: 79844,
    },
    {
      firstName: 'Kayden',
      lastName: 'Emard',
      age: 35,
      gender: 'Female',
      state: 'Alaska',
      salary: 98252,
    },
    {
      firstName: 'Rahul',
      lastName: 'Greenfelder',
      age: 27,
      gender: 'Female',
      state: 'Wisconsin',
      salary: 63419,
    },
    {
      firstName: 'Caterina',
      lastName: 'Koss',
      age: 52,
      gender: 'Male',
      state: 'Arkansas',
      salary: 58259,
    },
    {
      firstName: 'Eriberto',
      lastName: 'Klein',
      age: 20,
      gender: 'Male',
      state: 'Iowa',
      salary: 73198,
    },
    {
      firstName: 'Lilla',
      lastName: 'Thompson',
      age: 36,
      gender: 'Male',
      state: 'Washington',
      salary: 53856,
    },
    {
      firstName: 'Jonathan',
      lastName: 'Brakus',
      age: 47,
      gender: 'Female',
      state: 'Kansas',
      salary: 45726,
    },
    {
      firstName: 'Darby',
      lastName: 'Frami',
      age: 58,
      gender: 'Female',
      state: 'Texas',
      salary: 68700,
    },
    {
      firstName: 'Damon',
      lastName: 'Barrows',
      age: 22,
      gender: 'Female',
      state: 'Pennsylvania',
      salary: 23156,
    },
    {
      firstName: 'Eliezer',
      lastName: 'Erdman',
      age: 25,
      gender: 'Female',
      state: 'Missouri',
      salary: 48537,
    },
    {
      firstName: 'Marge',
      lastName: 'Windler',
      age: 29,
      gender: 'Female',
      state: 'Georgia',
      salary: 54107,
    },
    {
      firstName: 'Raleigh',
      lastName: 'Lakin',
      age: 48,
      gender: 'Male',
      state: 'Nebraska',
      salary: 13802,
    },
    {
      firstName: 'Freida',
      lastName: 'Barrows',
      age: 54,
      gender: 'Female',
      state: 'North Carolina',
      salary: 32719,
    },
    {
      firstName: 'Allen',
      lastName: 'Gaylord',
      age: 57,
      gender: 'Female',
      state: 'Virginia',
      salary: 41416,
    },
    {
      firstName: 'Blanche',
      lastName: 'Luettgen',
      age: 39,
      gender: 'Male',
      state: 'Missouri',
      salary: 81498,
    },
    {
      firstName: 'Domingo',
      lastName: 'Bauch',
      age: 36,
      gender: 'Female',
      state: 'Alaska',
      salary: 35159,
    },
    {
      firstName: 'Elijah',
      lastName: 'Kiehn',
      age: 43,
      gender: 'Female',
      state: 'Colorado',
      salary: 11772,
    },
    {
      firstName: 'Mozelle',
      lastName: 'Kuvalis',
      age: 44,
      gender: 'Female',
      state: 'Massachusetts',
      salary: 25499,
    },
    {
      firstName: 'Adaline',
      lastName: 'Volkman',
      age: 19,
      gender: 'Female',
      state: 'Louisiana',
      salary: 74049,
    },
    {
      firstName: 'Edward',
      lastName: 'Wisozk',
      age: 22,
      gender: 'Female',
      state: 'Washington',
      salary: 90236,
    },
    {
      firstName: 'Rita',
      lastName: 'Gaylord',
      age: 64,
      gender: 'Female',
      state: 'Georgia',
      salary: 12755,
    },
    {
      firstName: 'Burdette',
      lastName: 'Fisher',
      age: 27,
      gender: 'Female',
      state: 'Connecticut',
      salary: 29371,
    },
    {
      firstName: 'Naomi',
      lastName: 'Ebert',
      age: 52,
      gender: 'Female',
      state: 'Colorado',
      salary: 48525,
    },
    {
      firstName: 'Sid',
      lastName: 'Champlin',
      age: 43,
      gender: 'Female',
      state: 'Pennsylvania',
      salary: 77186,
    },
    {
      firstName: 'Darwin',
      lastName: 'Luettgen',
      age: 57,
      gender: 'Male',
      state: 'Illinois',
      salary: 34401,
    },
    {
      firstName: 'Bonita',
      lastName: 'Klocko',
      age: 43,
      gender: 'Male',
      state: 'Arkansas',
      salary: 85883,
    },
    {
      firstName: 'Tremayne',
      lastName: 'Casper',
      age: 58,
      gender: 'Female',
      state: 'Nebraska',
      salary: 47237,
    },
    {
      firstName: 'Maximillian',
      lastName: "O'Connell",
      age: 27,
      gender: 'Female',
      state: 'New Jersey',
      salary: 33190,
    },
    {
      firstName: 'Nola',
      lastName: 'Bashirian',
      age: 36,
      gender: 'Male',
      state: 'Nebraska',
      salary: 45510,
    },
    {
      firstName: 'Vernon',
      lastName: 'Murray',
      age: 62,
      gender: 'Male',
      state: 'Montana',
      salary: 25464,
    },
    {
      firstName: 'Edwin',
      lastName: 'Rippin',
      age: 32,
      gender: 'Male',
      state: 'Michigan',
      salary: 44083,
    },
    {
      firstName: 'Zola',
      lastName: 'Goodwin',
      age: 20,
      gender: 'Male',
      state: 'Idaho',
      salary: 62729,
    },
    {
      firstName: 'Dangelo',
      lastName: 'Abshire',
      age: 31,
      gender: 'Female',
      state: 'Connecticut',
      salary: 40247,
    },
    {
      firstName: 'Kallie',
      lastName: 'Swaniawski',
      age: 45,
      gender: 'Male',
      state: 'Virginia',
      salary: 35294,
    },
  ];
  