// import { CONFIG } from "../config/index";
import axios from "axios";
axios.defaults.withCredentials = true;

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api`,
  timeout: 10000,
  headers: {
    Accept: "application/json",
    ContentType: "application/json",
  },
});

export default axiosInstance;
