// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Anuphan:wght@300;400;500&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body, html {\n  margin: 0;\n  font-family: 'Anuphan', sans-serif !important;\n  background-color: black;\n}\n\ncode {\n  font-family: 'Anuphan', sans-serif !important;\n}", "",{"version":3,"sources":["webpack://./src/assets/global.css"],"names":[],"mappings":"AAEA;EACE,SAAS;EACT,6CAA6C;EAC7C,uBAAuB;AACzB;;AAEA;EACE,6CAA6C;AAC/C","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Anuphan:wght@300;400;500&display=swap');\n\nbody, html {\n  margin: 0;\n  font-family: 'Anuphan', sans-serif !important;\n  background-color: black;\n}\n\ncode {\n  font-family: 'Anuphan', sans-serif !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
