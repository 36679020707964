import React, { useState, useEffect } from 'react';
import { Table, Pagination, Input, Tag, Spin, Button, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import ServiceBuilds from '../../services/builds';
import Create from '../Create/Create';
import { DateTime } from 'luxon';

const WorkDashboard = () => {
    const [builds, setBuilds] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [searchText, setSearchText] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        document.title = 'Work Dashboard';
        const fetchData = async () => {
            try {
                const response = await ServiceBuilds.getBuilds();
                console.log('builds', response.data);
                setBuilds(response.data);
                setIsLoading(false);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);

    const handlePageChange = (page, pageSize) => {
        setCurrentPage(page);
    };

    const handlePageSizeChange = (current, size) => {
        setPageSize(size);
    };

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const filteredBuilds = builds.filter((build) =>
        build.sow_name.toLowerCase().includes(searchText.toLowerCase())
    );

    const offset = (currentPage - 1) * pageSize;
    const buildsToShow = filteredBuilds.slice(offset, offset + pageSize).map((build) => {
        return { ...build, key: build.key };
    });

    const onChange = (build) => {
        setBuilds(prevBuilds => [...prevBuilds, build]);
    }

    const onChangeTask = (task, selectedBuild) => {
        const renamedTask = {
            ...task,
            name: task.summary,
            budget: task.total_estimate,
        };

        const updatedBuilds = builds.map((build) => {
            if (build.id === task.build_id) {
                if (!build.children) {
                    build.children = [];
                }
                build.children.push(renamedTask);
                if (task.billing !== 'Non-Billable' || selectedBuild.billing === 'Non-Billable') {
                    build.not_allocated_budget = (build.not_allocated_budget - task.total_estimate).toFixed(2);
                }
            }
            return build;
        });

        setBuilds(updatedBuilds);
    };


    const columns = [
        {
            title: 'SoW Name / Summary ',
            dataIndex: 'name',
            key: 'name',
            width: '20%',
            render: (text, record) => {
                // const ticketCreatedAt = new Date(record.created_at);
                // const timeDifference = (currentTime - ticketCreatedAt) / (1000 * 60);

                const truncatedName = text.length > 20 ? `${text.slice(0, 20)}...` : text;

                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div>
                            {record.id ?
                                <Tooltip title={text}>
                                    <Link to={`/build/${record.id}`}>
                                        <span style={{ fontWeight: '500' }}>{truncatedName}</span>
                                    </Link>
                                </Tooltip> :
                                <Tooltip title={text}>
                                    <Link to={`/task/${record.key}`}>
                                        <span style={{ fontWeight: '500' }}>{truncatedName}</span>
                                    </Link>
                                </Tooltip>
                            }
                        </div>
                        {record.billing_type !== undefined && record.billing_type === 'Non-Billable' &&
                            <div>
                                NB Build
                            </div>
                        }
                    </div>
                )
            },
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: '8px' }}>
                    <Input
                        placeholder="Search name"
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => confirm()}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Button
                        type="primary"
                        onClick={() => confirm()}
                        size="small"
                        style={{ width: 90, marginRight: 8 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </div>
            ),
            onFilter: (value, record) => record.name.toLowerCase().includes(value.toLowerCase()),
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: 'Administrator',
            dataIndex: 'display_name',
            key: 'display_name',
            width: '15%',
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: '8px' }}>
                    <Input
                        placeholder="Search Administrator"
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => confirm()}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Button
                        type="primary"
                        onClick={() => confirm()}
                        size="small"
                        style={{ width: 90, marginRight: 8 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </div>
            ),
            onFilter: (value, record) => record.display_name.toLowerCase().includes(value.toLowerCase()),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '10%',
            render: (status) => <div>{status === 'Completed' || status === 'Done' ? <Tag color="#87d068">{status}</Tag> : <Tag>{status}</Tag>}</div>
        },
        {
            title: 'Budget',
            dataIndex: 'budget',
            key: 'budget',
            width: '10%',
            render: (budget, record) =>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
                    <div style={{ fontWeight: '500' }}>{budget}</div>
                    {record.billing === 'Non-Billable' && <div>NB</div>}
                </div>,
            sorter: (a, b) => parseFloat(a.budget) - parseFloat(b.budget),
        },
        {
            title: 'Rem. Budget',
            dataIndex: 'remaining_budget',
            key: 'remaining_budget',
            width: '10%',
            render: (budget) => {
                return {
                    props: {
                        style: { background: budget !== undefined && (budget > 0 ? "rgb(183 249 182)" : "rgb(255 200 188)") }
                    },
                    children:
                        <div>
                            <span style={{ fontWeight: '500' }}>{budget}</span>
                        </div>
                }
            },
            sorter: (a, b) => parseFloat(a.remaining_budget) - parseFloat(b.remaining_budget),
        },
        {
            title: 'Not Allocated',
            dataIndex: 'not_allocated_budget',
            key: 'not_allocated_budget',
            width: '10%',
            render: (budget) => {
                return {
                    props: {
                        style: { background: budget !== undefined && (budget > 0 ? "rgb(183 249 182)" : "rgb(255 200 188)") }
                    },
                    children:
                        <div>
                            <span style={{ fontWeight: '500' }}>{budget}</span>
                        </div>
                }
            },
            sorter: (a, b) => parseFloat(a.not_allocated_budget) - parseFloat(b.not_allocated_budget),
        },
        {
            title: 'Start Date',
            dataIndex: 'start_date',
            key: 'start_date',
            width: '10%',
            render: (text) => DateTime.fromISO(text, { zone: Intl.DateTimeFormat().resolvedOptions().timeZone }).toFormat('yyyy-MM-dd'),
        },
        {
            title: 'Due Date',
            dataIndex: 'due_date',
            key: 'due_date',
            width: '10%',
            render: (text) => DateTime.fromISO(text, { zone: Intl.DateTimeFormat().resolvedOptions().timeZone }).toFormat('yyyy-MM-dd'),
        },
        {
            title: 'Account',
            dataIndex: 'account_name',
            key: 'account_name',
            width: '10%',
            render: (text) => <div style={{ fontWeight: '500' }}>{text}</div>
        }
    ];

    if (isLoading) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <Spin size="large" />
            </div>
        );
    }


    return (
        <div style={{ height: '92vh', overflowY: 'auto', overflowX: 'hidden' }}>
            <div style={{ padding: '20px' }}>
                <div
                    style={{
                        display: 'flex',
                    }}
                >
                    <Input
                        placeholder="Search builds..."
                        style={{ marginBottom: '10px', width: '200px' }}
                        onChange={handleSearch}
                    />
                    <Create
                        onChange={onChange}
                        onChangeTask={onChangeTask}
                        builds={builds}
                    />
                    <Link to={'/board'}>
                        <Button style={{ marginLeft: '14px' }}>
                            <span style={{ fontWeight: '500' }}>Board</span>
                        </Button>
                    </Link>
                </div>
                <Table
                    dataSource={buildsToShow}
                    columns={columns}
                    pagination={false}
                    size='small'
                    loading={loading}
                />
                <Pagination
                    style={{ marginTop: '20px' }}
                    current={currentPage}
                    pageSize={pageSize}
                    total={filteredBuilds.length}
                    onChange={handlePageChange}
                    onShowSizeChange={handlePageSizeChange}
                    showSizeChanger
                    showTotal={(total) => <div>Builds: <span style={{ fontWeight: '500' }}>{total}</span></div>}
                />
            </div>
        </div >
    );
};

export default WorkDashboard;