import axiosInstance from "../config/axios";

class ServiceAuth {
  async getUser() {
    return await axiosInstance.get("/auth/user");
  };

  async logoutUser() {
    return await axiosInstance.get("/auth/logout");
  }
}

export default new ServiceAuth() 