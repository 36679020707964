import React, { useState } from 'react';
import { format, isToday } from 'date-fns';
import { Button, Card, Modal, Progress, Tag, Tooltip, Spin } from 'antd';
import moment from 'moment';
import EditModal from '../EditModal/EditModal';
import CreateModal from '../CreateModal/CreateModal.js';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { EditOutlined, CopyOutlined, DeleteOutlined, PlusOutlined, StarOutlined, StarTwoTone, CrownTwoTone } from '@ant-design/icons';
import './WeekDays.css';
import ServiceWorklogs from '../../services/worklogs';
import ServiceTickets from '../../services/tickets';
import { Scrollbar } from 'react-scrollbars-custom';
import { useUser } from '../../UserContext';
import { useEffect } from 'react';

function WeekDays(props) {
    const { user } = useUser();
    const [showModal, setShowModal] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [logDate, setDate] = useState();
    const [selectedLog, setSelectedLog] = useState({});
    const [label, setLabel] = useState(null);
    const [isEditModal, setIsEditModal] = useState(null);
    const [showFullSummary, setShowFullSummary] = useState(false);
    const [tasks, setTasks] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        const fetchTasks = async () => {
            try {
                const response = await ServiceTickets.getUserTasks();
                setTasks(response.data);
                setIsLoading(false);
            } catch (error) {
                console.error(error);
            }
        };

        fetchTasks();
    }, [])



    function isOpenHandler(isOpen) {
        setShowModal(isOpen);
    }

    function openCreateModal(date) {
        setShowCreateModal(true);
        setDate(new Date(date).toISOString());
    }

    function isOpenCreateModalHandler(isOpen) {
        setShowCreateModal(isOpen);
    }

    const handleCardClick = (date, log) => {
        setLabel('Edit Log');
        setSelectedLog(log);
        setShowModal(true);
        setIsEditModal(true);
        setDate(new Date(date).toISOString());
    };

    function copyHandler(date, task) {
        setLabel('Copy Log');
        setSelectedLog(task);
        setShowModal(true);
        setIsEditModal(false);
        setDate(new Date(date).toISOString());
    }

    function removeElementById(id) {
        Modal.confirm({
            title: 'Confirm deletion',
            content: 'Do you really want to remove a Log?',
            onOk() {
                props.onDeleteLog(id);
            },
            centered: true,
        });
    }

    function addLog(log) {
        setIsLoading(true);
        ServiceWorklogs.addLog(log)
            .then((response) => {
                props.onData(response.data);
            })
            .finally(() => {
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
            });

    }

    function editLog(log) {
        setIsLoading(true);
        ServiceWorklogs.updateLog(log)
            .then((response) => {
                console.log('Log updated successfully:', response.data);
                props.onUpdatedData(response.data);
            })
            .finally(() => {
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Failed to update log:', error);
            });
    }

    function calculateTotalHoursForWeek(logs, weekStartDate) {
        const weekEndDate = new Date(weekStartDate);
        weekEndDate.setDate(weekEndDate.getDate() + 6); // Calculate the end date of the week

        let totalHours = 0;

        logs.forEach((log) => {
            const logDate = new Date(log.created_at);

            if (logDate >= weekStartDate && logDate <= weekEndDate) {
                totalHours += parseFloat(log.time_spent_hours);
            }
        });

        return totalHours;
    }

    const weekStartDate = props.days[0] ? new Date(props.days[0].date) : null;
    const totalHoursForWeek = calculateTotalHoursForWeek(props.logs, weekStartDate);

    return (
        <div style={{ width: '100%', overflowX: 'hidden' }}>
            <div style={{ display: 'flex' }}>
                {props.days.map((day, index) => {
                    const dateObj = new Date(day.date);
                    let totalHours = 0;

                    props.logs.forEach((worklog) => {
                        const dateFormat1 = moment(day.date);
                        const dateFormat2 = moment(worklog.created_at);
                        if (dateFormat1.isSame(dateFormat2, 'day')) {
                            totalHours += parseFloat(worklog.time_spent_hours);
                        }
                    });

                    let percentComplete = (totalHours / 8) * 100;

                    return (
                        <div
                            key={index}
                            style={{
                                alignItems: 'center',
                                width: 'calc(100% / 7)',
                                padding: '5px',
                            }}
                        >
                            <div
                                style={{
                                    position: 'sticky',
                                    top: '0',
                                    zIndex: 1,
                                    background: 'white',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        background: isToday(dateObj) ? 'rgb(80, 104, 224)' : 'transparent',
                                        borderRadius: isToday(dateObj) ? '5px' : '0',
                                        color: isToday(dateObj) ? 'white' : 'black',
                                        paddingRight: isToday(dateObj) ? '5px' : '0',
                                        paddingLeft: isToday(dateObj) ? '5px' : '0',
                                    }}
                                >
                                    <div style={{ display: 'flex', fontSize: '14px', padding: '2px' }}>
                                        <div style={{ alignSelf: 'flex-start', fontWeight: '550' }}>
                                            {format(dateObj, 'EEE')}
                                        </div>
                                        <div style={{ marginLeft: '5px' }}>{format(dateObj, 'dd')}</div>
                                    </div>
                                    <div style={{ fontSize: '14px' }}>{totalHours} of 8h</div>
                                </div>

                                <Progress percent={percentComplete} size="small" showInfo={false} />

                                <Button
                                    variant="secondary"
                                    onClick={() => openCreateModal(new Date(day.date))}
                                    style={{ width: '100%', fontWeight: 550 }}
                                >
                                    <PlusOutlined />
                                </Button>
                            </div>
                        </div>
                    );
                })}
            </div>

            <Spin tip="Loading" spinning={isLoading}>
                <div>
                    <Scrollbar style={{ height: 'calc(100vh - 280px)', overflowX: 'hidden' }}>
                        <div style={{ display: 'flex' }}>
                            {props.days.map((day, index) => {

                                return (
                                    <div
                                        key={index}
                                        style={{
                                            alignItems: 'center',
                                            width: 'calc(100% / 7)',
                                            padding: '5px',
                                        }}

                                    >

                                        <div
                                            style={{
                                                borderRadius: '8px'
                                            }}
                                        >
                                            {props.logs
                                                .filter((worklog) => {
                                                    const dateFormat1 = moment(day.date);
                                                    const dateFormat2 = moment(worklog.created_at);
                                                    return dateFormat1.isSame(dateFormat2, 'day');
                                                })
                                                .map((log) => (
                                                    <Card
                                                        key={log.id}
                                                        style={{
                                                            width: '100%', marginTop: 8
                                                        }}
                                                        actions={[
                                                            <EditOutlined key="edit" onClick={() => handleCardClick(new Date(day.date), log)} />,
                                                            <CopyOutlined key="ellipsis" onClick={() => copyHandler(new Date(day.date), log)} />,
                                                            <DeleteOutlined key="delete" onClick={() => removeElementById(log.id)} />,
                                                        ]}
                                                        hoverable={true}
                                                    >
                                                        <div
                                                            className="task-name"
                                                            style={{ display: 'flex', justifyContent: 'space-between', fontSize: '15px', fontWeight: 550 }}
                                                        >
                                                            <Tooltip title={log.summary}>
                                                                <Link
                                                                    className="task-link"
                                                                    to={`/task/${log.task_key}`}
                                                                    // onMouseEnter={() => setShowFullSummary(true)}
                                                                    // onMouseLeave={() => setShowFullSummary(false)}
                                                                    style={{
                                                                        display: 'block',
                                                                        whiteSpace: 'nowrap',
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                        width: '100%',
                                                                    }}
                                                                >
                                                                    {
                                                                        (log.summary || showFullSummary)
                                                                            ? (log.summary.length > 12 ? log.summary.slice(0, 12) + '...' : log.summary)
                                                                            : ''
                                                                    }
                                                                </Link>
                                                            </Tooltip>
                                                        </div>
                                                        <div style={{ fontWeight: "500", color: '#6FB86E' }}>{log.name}</div>
                                                        <div style={{ marginTop: '3px' }}>
                                                            <div
                                                                style={{
                                                                    display: '-webkit-box',
                                                                    WebkitLineClamp: 2,
                                                                    WebkitBoxOrient: 'vertical',
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    maxHeight: '2.6em',
                                                                    lineHeight: '1.3em',
                                                                }}
                                                            >
                                                                {log.comment === null || !(log.comment.length > 0) ? (
                                                                    <div style={{ fontWeight: '550' }}>
                                                                        No comment.
                                                                        <br />
                                                                        &nbsp;
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        {log.comment}
                                                                        <br />
                                                                        &nbsp;
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div style={{ fontSize: '13.5px', fontWeight: '650' }}>{log.time_spent_hours}h</div>
                                                    </Card>
                                                ))}
                                        </div>
                                    </div>
                                );
                            })}
                            <EditModal
                                isOpen={showModal}
                                log={selectedLog}
                                isOpenChange={isOpenHandler}
                                date={logDate}
                                label={label}
                                isEditModal={isEditModal}
                                onData={addLog}
                                onEditLog={editLog}
                                tasks={tasks}
                            />
                            <CreateModal
                                isOpen={showCreateModal}
                                isOpenChange={isOpenCreateModalHandler}
                                date={logDate}
                                onData={addLog}
                                isWeekDays={true}
                                tasks={tasks}
                            />
                        </div>
                    </Scrollbar >
                    <div>
                        <div>Total Hours for the Week: <span style={{ fontWeight: '500' }}>{totalHoursForWeek}</span> hours</div>
                    </div>
                </div>
            </Spin>
        </div >
    );
}

export default WeekDays;