import axiosInstance from "../config/axios";

class ServiceUsers {
  async getAllUsers(filters) {
    return await axiosInstance.post("/users", filters);
  };

  async getRoles() {
    return await axiosInstance.get("/users/roles");
  }

  async isUserExist() {
    return await axiosInstance.get("/users/check-user");
  };

  async search(searchText) {
    return await axiosInstance.get(`users/search`, {
      params: { searchText },
    })
  }

  async updateUser(user) {
    return await axiosInstance.put('/users', user);
  };

  async applyFilter(field, value) {
    return await axiosInstance.get('/users/filter', {
      params: { field, value },
    });
  }

  async applySorter(order) {
    return await axiosInstance.get('/users/sort', {
      params: { order },
    });
  }

  async getActiveSessions() {
    return await axiosInstance.get("/sessions");
  }
}

export default new ServiceUsers() 