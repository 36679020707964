import React, { useEffect, useState } from 'react';
import { Descriptions, Tag, Collapse } from 'antd';

const { Panel } = Collapse;

const BuildDetails = (props) => {
    const [task, setTask] = useState(props.task || {});

    useEffect(() => {
        setTask(props.task);
    }, [props.task])

    return (
        <div>
            <Collapse size='small' defaultActiveKey={['1']}>
                <Panel
                    header="Task Details"
                    key="1"
                >
                    <div>
                        <Descriptions column={2} size='small'>
                            <Descriptions.Item label="Total Estimate">{task.total_estimate}</Descriptions.Item>
                            <Descriptions.Item label="Administrator">{task.display_name}</Descriptions.Item>
                            <Descriptions.Item label="Remaining Budget">
                                <span style={{ fontWeight: '500', color: task.remaining_budget > 0 ? 'green' : 'red' }}>{task.remaining_budget}</span>
                            </Descriptions.Item>
                            <Descriptions.Item label="Billing Type">{task.billing}</Descriptions.Item>
                            <Descriptions.Item label="Priority">{task.priority}</Descriptions.Item>
                            <Descriptions.Item label="Task Type">{task.task_type}</Descriptions.Item>
                            <Descriptions.Item label="Start Date">{new Date(task.start_date).toLocaleDateString()}</Descriptions.Item>
                            <Descriptions.Item label="Due Date">{new Date(task.due_date).toLocaleDateString()}</Descriptions.Item>
                            <Descriptions.Item label="Status"><Tag>{task.status}</Tag></Descriptions.Item>
                        </Descriptions>
                    </div>
                </Panel>
            </Collapse>
        </div>
    );
};

export default BuildDetails;