import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Modal, Select, message } from "antd";
import { useUser } from '../../UserContext';
import ServiceBuilds from '../../services/builds';

const { Option } = Select;

const EditBuildModal = (props) => {
    const { user, loading } = useUser();

    const [form] = Form.useForm();
    const [build, setBuild] = useState(props.build);
    const [buttonLoading, setButtonLoading] = useState(false);

    useEffect(() => {
        setBuild(props.build);
        console.log('administrator', props.build.administrator);
        form.setFieldsValue({
            sow_name: props.build.sow_name,
            administrator: props.build.administrator
        });
    }, [form]);

    const handleCloseModal = () => {
        props.handleEditBuildModalChange(false);
    };

    const onFinish = (values) => {
        setButtonLoading(true);
        console.log('values', values);


        ServiceBuilds.updateBuild({ ...build, sow_name: values.sow_name, administrator: values.administrator })
            .then((response) => {
                if (response.data) {
                    const user = props.users.find((user) => user.id === values.administrator);
                    const display_name = user ? user.display_name : '';
                    props.onBuildChange({ ...build, sow_name: values.sow_name, administrator: values.administrator, display_name: display_name });
                    props.handleEditBuildModalChange(false);
                    setButtonLoading(false);
                    message.success('Build was updated successfully');
                } else {
                    setButtonLoading(false);
                    message.error('Error updating');
                }
            });

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    function handleSearch(value) { }

    return (
        <Modal
            open={props.isOpen}
            width={450}
            onCancel={handleCloseModal}
            footer={[
                <Button key="cancel" onClick={handleCloseModal}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" loading={buttonLoading} onClick={() => form.submit()}>
                    Submit
                </Button>
            ]}
            title="Build configuration"
            centered
        >
            <div>
                <Form
                    name="basic"
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    form={form}
                >

                    <Col>
                        <Form.Item
                            label="SoW Name"
                            name="sow_name"
                            rules={[{ required: true, message: 'Please enter a SoW' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            label="Administrator"
                            name="administrator"
                            rules={[{ required: true, message: 'Please select an administrator' }]}
                        >
                            <Select
                                showSearch
                                style={{ width: 255 }}
                                placeholder="Search Users"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {props.users && props.users.filter(user => user.role_id !== 1).map(user => (
                                    <Option key={user.id} value={user.id}>
                                        {user.display_name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Form>
            </div>
        </Modal>
    );
}

export default EditBuildModal;