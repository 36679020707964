import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { Table, DatePicker, Layout, Avatar, Select, Tag, Button, ConfigProvider, Modal, Space } from 'antd';
import moment from 'moment';
import './Tempo.css';
import dayjs from 'dayjs';
import {
    BulbTwoTone,
    CalendarTwoTone,
    DownloadOutlined,
    DownOutlined
} from "@ant-design/icons";
// Services
import ServiceUsers from '../../services/users';
import ServiceTasks from '../../services/tickets';
import ServiceBuilds from '../../services/builds';
import ServiceWorklogs from '../../services/worklogs';

import styled from 'styled-components';
import TempoModal from '../TempoModal/TempoModal';
import worklogs from '../../services/worklogs';
import { OpacityRounded } from '@mui/icons-material';

import FilterComponent from '../FilterComponent/FilterComponent';

const { RangePicker } = DatePicker;
const { Content } = Layout;

const currentMonthStart = dayjs().startOf('month');
const currentMonthEnd = dayjs().endOf('month');

const currentWeekStart = dayjs().startOf('week').add(1, 'day');
const currentWeekEnd = dayjs().endOf('week').add(1, 'day');

const rangePresets = [
    {
        label: <span style={{ fontWeight: '700' }}>Current Month</span>,
        value: [currentMonthStart, currentMonthEnd],
    },
    {
        label: <span style={{ fontWeight: '700' }}>Current Week</span>,
        value: [currentWeekStart, currentWeekEnd],
    },
    {
        label: 'Last 7 Days',
        value: [dayjs().add(-6, 'd'), dayjs()],
    },
    {
        label: 'Last 14 Days',
        value: [dayjs().add(-13, 'd'), dayjs()],
    },
    {
        label: 'Last 30 Days',
        value: [dayjs().add(-29, 'd'), dayjs()],
    },
    {
        label: 'Last 90 Days',
        value: [dayjs().add(-89, 'd'), dayjs()],
    },
];

const getCurrentMonthRange = () => {
    const startOfMonth = dayjs().startOf('month').toDate();
    const endOfMonth = dayjs().endOf('month').toDate();
    return [startOfMonth, endOfMonth];
};

const StyledTable = styled((props) => <Table {...props} />)`
    tbody > tr > td.basic-cell:hover,
    tbody > tr > td.weekend-cell:hover {
        cursor: pointer;
        border-bottom: 2px solid #8f8f8f;
    }
`;

const Tempo = () => {
    const [users, setUsers] = useState([]);
    const [builds, setBuilds] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedGroupType, setSelectedGroupType] = useState();
    const [selectedGroup, setSelectedGroup] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [dateArray, setDateArray] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({});
    const [selectedFilters, setSelectedFilters] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [modalLoading, setModalLoading] = useState(false);
    const [dataTrigger, setDataTrigger] = useState(false);
    const [dates, setDates] = useState([null, null]);
    const [isEndDateToday, setIsEndDateToday] = useState(false);
    // const filterRef = useRef();

    const handleFilter = (selectedValues) => {

        const newFilters = {
            userIds: [],
            buildIds: [],
            accountIds: [],
            taskKeys: {}
        };

        selectedValues.forEach(([type, field, value]) => {
            if (type === "users") {
                newFilters.userIds.push(field);
            } else if (type === "builds") {
                newFilters.buildIds.push(field);
            } else if (type === "tasks") {
                if (!newFilters.taskKeys[field]) {
                    newFilters.taskKeys[field] = [];
                }
                newFilters.taskKeys[field].push(value);
            } else if (type === "accounts") {
                newFilters.accountIds.push(field);
            }
        });

        setFilters(newFilters);
    };

    // const clearFilters = () => {
    //     console.log('test', filterRef.current);
    //     if (filterRef.current) {
    //         filterRef.current.clearOptions();
    //     }
    //     setFilters(newFilters);
    // }

    const handlePrevPeriod = () => {
        let newStartDate, newEndDate;
        if (!startDate || !endDate) {
            newStartDate = dayjs().startOf('month');
            newEndDate = dayjs().endOf('month');
        } else {
            newStartDate = dayjs(startDate).subtract(1, 'month').startOf('month');
            newEndDate = dayjs(endDate).subtract(1, 'month').endOf('month');
        }
        setStartDate(newStartDate.toDate());
        setEndDate(newEndDate.toDate());
    };

    const handleNextPeriod = () => {
        let newStartDate, newEndDate;
        if (!startDate || !endDate) {
            newStartDate = dayjs().startOf('month');
            newEndDate = dayjs().endOf('month');
        } else {
            newStartDate = dayjs(startDate).add(1, 'month').startOf('month');
            newEndDate = dayjs(endDate).add(1, 'month').endOf('month');
        }
        setStartDate(newStartDate.toDate());
        setEndDate(newEndDate.toDate());
    };

    const handleExportCSV = () => {

        const start = new Date(startDate);
        start.setHours(0, 0, 0, 0);
        const formattedStart = `${start.getFullYear()}-${String(start.getMonth() + 1).padStart(2, '0')}-${String(start.getDate()).padStart(2, '0')}`;

        const end = new Date(endDate);
        end.setHours(23, 59, 59, 999);
        const formattedEnd = `${end.getFullYear()}-${String(end.getMonth() + 1).padStart(2, '0')}-${String(end.getDate()).padStart(2, '0')}`;

        let transformedData = [];

        const headers = [
            'Build',
            'Task',
            'Logged Hours',
            'Full Name',
            'Date',
            'Comment'
        ];

        const filterWorklog = (log) => {
            const logDate = log.created_at.split('T')[0];
            if (logDate < formattedStart || logDate > formattedEnd) return false;

            if (Object.keys(filters).length !== 0) {
                if (filters.userIds.length && !filters.userIds.includes(log.user_id)) return false;
                if (filters.buildIds.length && !filters.buildIds.includes(log.build_id)) return false;
                if (filters.accountIds.length && !filters.accountIds.includes(log.account_id)) return false;

                if (filters.taskKeys) {
                    const taskFields = {
                        status: log.task_status,
                        priority: log.task_priority,
                        billing: log.billing_type,
                        summary: log.task_summary
                    };

                    for (const [key, value] of Object.entries(filters.taskKeys)) {
                        if (value.length && !value.includes(taskFields[key])) return false;
                    }
                }
            }
            return true;
        };

        transformedData = builds.flatMap(build =>
            build.tasks.flatMap(task =>
                task.worklogs
                    .filter(filterWorklog)
                    .map(log => {
                        // Find the user based on user_id from worklog
                        const user = build.users.find(user => user.user_id === log.user_id);
                        return {
                            Build: build.name, // Set the build name
                            Task: task.name, // Set the task name
                            'Logged Hours': log.time_spent_hours, // Log time spent
                            'Full Name': user ? user.name : 'Unknown', // Get user name
                            Date: log.created_at.split('T')[0], // Log date
                            Comment: log.comment, // Log comment
                        };
                    })
            )
        );

        const csvContent = convertToCSV(transformedData, headers);
        downloadCSV(csvContent);
    };

    const convertToCSV = (data, headers) => {
        const csvRows = [];
        csvRows.push(headers.join(','));
        data.forEach(row => {
            csvRows.push(Object.values(row).map(value => `"${value}"`).join(','));
        });
        return csvRows.join('\n');
    };

    const downloadCSV = (csvContent) => {
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'export.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    };

    const fetchLogsForDate = async (date) => {
        try {
            const logs = await ServiceWorklogs.getWorklogsForDate(date);
            setModalLoading(false);
            return logs.data;
        } catch (error) {
            console.error('Error fetching logs:', error);
            return [];
        }
    };

    const formatDateForApi = (date) => {
        return dayjs(date).format('YYYY-MM-DD');
    };

    const showModal = async (content) => {
        setLoading(true);
        setModalLoading(true);
        setIsModalVisible(true);
        if (content && content.date) {
            try {
                const formattedDate = formatDateForApi(dayjs(content.date, 'DD MMM YYYY'));
                let logs = await fetchLogsForDate(formattedDate);

                if (content.user_id && content.type === 'user') {
                    logs = logs.filter((entry) => {
                        const taskKey = content.task_key ? parseInt(content.task_key) : null;
                        const taskAttribute = content.task_attribute || null;
                        const taskAttributeValue = content.task_attribute_value || null;
                        const userId = content.user_id ? parseInt(content.user_id) : null;
                        const buildId = content.build_id ? parseInt(content.build_id) : null;
                        const worklogId = content.worklog_id ? parseInt(content.worklog_id) : null;
                        const attributeMatches = taskAttribute && taskAttributeValue
                            ? entry[taskAttribute] === taskAttributeValue
                            : true;

                        if (taskKey !== null && buildId !== null) {
                            return entry.build_id === buildId && entry.user_id === userId && attributeMatches;
                        } else if (taskKey !== null) {
                            return entry.user_id === userId && attributeMatches;
                        } else if (buildId !== null) {
                            if (buildId !== null) {
                                if (worklogId !== null) {
                                    return entry.id === worklogId;
                                }
                                return entry.build_id === buildId &&
                                    entry.user_id === userId &&
                                    attributeMatches;
                            }
                            return entry.build_id === buildId && entry.user_id === userId && attributeMatches;
                        } else {
                            return entry.user_id === userId && attributeMatches;
                        }
                    });
                }

                if (content.build_id && content.type === 'build') {
                    logs = logs.filter((entry) => {
                        const buildId = parseInt(content.build_id);
                        const taskKey = content.task_key ? parseInt(content.task_key) : null;
                        const taskAttribute = content.task_attribute || null;
                        const taskAttributeValue = content.task_attribute_value || null;
                        const userId = content.user_id ? parseInt(content.user_id) : null;

                        const attributeMatches = taskAttribute && taskAttributeValue
                            ? entry[taskAttribute] === taskAttributeValue
                            : true;

                        if (taskKey !== null) {
                            return entry.build_id === buildId && attributeMatches;
                        }

                        if (userId !== null) {
                            return entry.build_id === buildId && entry.user_id === userId && attributeMatches;
                        }

                        return entry.build_id === buildId;
                    });
                }


                if (content.account_id && content.type === 'account') {

                    logs = logs.filter((entry) => {
                        const buildId = content.build_id ? parseInt(content.build_id) : null;
                        const buildAttribute = content.build_attribute || null;
                        const buildAttributeValue = content.build_attribute_value || null;
                        const taskKey = content.task_key ? parseInt(content.task_key) : null;
                        const taskAttribute = content.task_attribute || null;
                        const taskAttributeValue = content.task_attribute_value || null;
                        const userId = content.user_id ? parseInt(content.user_id) : null;

                        const taskAttributeMatches = taskAttribute && taskAttributeValue
                            ? entry[taskAttribute] === taskAttributeValue
                            : true;

                        const buildAttributeMatches = buildAttribute && buildAttributeValue
                            ? entry[buildAttribute] === buildAttributeValue
                            : true;

                        if (buildId !== null) {
                            return entry.account_id === parseInt(content.account_id) && buildAttributeMatches;
                        }

                        if (userId !== null) {
                            return entry.user_id === userId && buildAttributeMatches && entry.account_id === content.account_id;
                        }

                        return entry.account_id === parseInt(content.account_id);
                    });
                }
                setModalContent({
                    ...content,
                    logs
                });
                setLoading(false);
            } catch (error) {
                console.error('Error handling modal content:', error);
            }
        } else {
            console.error('Modal content is missing or not properly initialized:', content);
        }
    };

    const handleOk = () => {
        setIsModalVisible(false);
        setModalLoading(false);
        setModalContent(null);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setModalLoading(false);
        setModalContent(null);
    };

    const handleDataChange = (data) => {
        setDataTrigger(data);
    };

    const fetchData = async () => {
        setLoading(true);
        try {
            const [userResponse, buildResponse, accountResponse] = await Promise.all([
                ServiceUsers.getAllUsers(filters),
                ServiceBuilds.getBuilds(filters),
                ServiceBuilds.getAccountsWithBuilds(filters)
            ]);

            // const userData = await Promise.all(userResponse.data.map(async (item) => {
            //     const user = {
            //         id: item.id,
            //         email: item.email,
            //         display_name: item.display_name,
            //         active: item.active,
            //         builds: item.builds,
            //         worklogs: []
            //     };

            //     if (item.worklogs && item.worklogs.length > 0) {
            //         const worklogPromises = item.worklogs.map(async (worklog) => {
            //             const response = await ServiceTasks.getTask(worklog.task_key);
            //             worklog.task = response?.data?.task || null;
            //             return worklog;
            //         });

            //         user.worklogs = await Promise.all(worklogPromises);
            //     }

            //     return user;
            // }));

            const userData = userResponse.data;
            const buildData = buildResponse.data;
            const accountData = accountResponse.data;

            setUsers(userData);
            setBuilds(buildData);
            setAccounts(accountData);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const fetchAndSetData = async () => {
        await fetchData();
        if (startDate && endDate) {
            const selectedGroupValues = selectedGroup && selectedGroup.map(group => group.value);
            displayGroupedData(startDate, endDate, selectedGroupType.value, selectedGroupValues);
        }
    };

    // const defaultValue = 'build';
    // const defaultOption = { value: 'build', label: 'Build' };

    useEffect(() => {
        document.title = 'Tempo';
        if (filters) {
            fetchAndSetData();
        }
    }, [selectedGroupType, selectedGroup, startDate, endDate, selectedFilters, dataTrigger, filters]);

    useEffect(() => {
        if (users.length > 0 || builds.length > 0) {
            if (selectedGroupType?.value) {
                const selectedGroupValues = selectedGroup.map(group => group?.value).filter(Boolean);
                displayGroupedData(startDate, endDate, selectedGroupType.value, selectedGroupValues);
            }
        }
    }, [users, builds, accounts]);

    // useEffect(() => {
    //     handleGroupChange(defaultValue, defaultOption);
    // }, []);

    useEffect(() => {
        if (!startDate || !endDate) {
            setStartDate(dayjs().startOf('month').toDate());
            setEndDate(dayjs().endOf('month').toDate());
        }
    }, []);

    useEffect(() => {
        if (dayjs(endDate).isSame(dayjs(), "day")) {
            console.log('$$$');
            setIsEndDateToday(true);
        }
    }, [endDate]);

    const displayGroupedData = (startDate, endDate, groupType, groupByArray) => {
        const start = moment(startDate);
        const end = moment(endDate);

        const newDateArray = [];
        for (let date = start.clone(); date <= end; date.add(1, 'day')) {
            newDateArray.push(date.format('DD MMM YYYY ddd'));
        }
        setDateArray(newDateArray);
        const filteredData = [];


        // USER GROUPING
        if (groupType === 'user') {
            const uniqueGroupKeys = new Set();
            users.forEach(user => {
                const timeByDate = {};
                newDateArray.forEach(date => {
                    timeByDate[date] = 0;
                });
                user.worklogs.forEach(worklog => {
                    const logDate = moment(worklog.created_at).format('DD MMM YYYY ddd');
                    if (timeByDate[logDate] !== undefined) {
                        timeByDate[logDate] += parseFloat(worklog.time_spent_hours);
                    }
                });

                const userEntry = {
                    type: 'user',
                    name: <div style={{ fontWeight: '700' }}>{user.display_name}</div>,
                    data: newDateArray.map(date => timeByDate[date] ? <span style={{ fontWeight: '700' }}>{timeByDate[date].toFixed(2)}</span> : ''),
                    total: <span style={{ fontWeight: '700' }}>{(Object.values(timeByDate).reduce((a, b) => a + b, 0) || 0).toFixed(2).replace(/^0\.00$/, '')}</span>,
                    user_id: user.id
                };
                filteredData.push(userEntry);
                uniqueGroupKeys.add(user.id);
                const firstItem = groupByArray[0];

                if (groupByArray && groupByArray.length > 0 && ['summary', 'status', 'priority', 'billing'].includes(firstItem)) {
                    const groupedItems = {};

                    user.worklogs.forEach(worklog => {
                        const groupKey = groupByArray.map(groupBy =>
                            groupBy === 'summary' ? worklog.task.summary :
                                groupBy === 'status' ? worklog.task.status :
                                    groupBy === 'priority' ? worklog.task.priority :
                                        worklog.task.billing
                        ).join(' / ');

                        if (!groupedItems[groupKey]) {
                            groupedItems[groupKey] = {
                                key: groupKey,
                                worklogs: [],
                                task_key: worklog.task_key,
                                task_attribute: firstItem
                            };
                        }
                        groupedItems[groupKey].worklogs.push(worklog);
                    });

                    Object.values(groupedItems).forEach(item => {
                        const timeByDate = {};
                        newDateArray.forEach(date => {
                            timeByDate[date] = 0;
                        });

                        item.worklogs.forEach(worklog => {
                            const logDate = moment(worklog.created_at).format('DD MMM YYYY ddd');
                            if (timeByDate[logDate] !== undefined) {
                                timeByDate[logDate] += parseFloat(worklog.time_spent_hours);
                            }
                        });

                        filteredData.push({
                            type: 'user',
                            name: <span style={{ paddingLeft: '16px' }}>{item.key}</span>,
                            data: newDateArray.map(date => timeByDate[date] ? timeByDate[date].toFixed(2) : ''),
                            total: (Object.values(timeByDate).reduce((a, b) => a + b, 0) || 0).toFixed(2).replace(/^0\.00$/, ''),
                            user_id: user.id,
                            task_key: item.task_key,
                            task_attribute: firstItem,
                            task_attribute_value: item.key
                        });
                    });
                } else if (groupByArray.some(item => ['sow_name'].includes(item))) {
                    const groupedItems = {};

                    user.builds && user.builds.forEach(build => {

                        const groupKey = build.id;
                        const sow_name = build.sow_name;

                        if (!groupedItems[groupKey]) {
                            groupedItems[groupKey] = {
                                key: groupKey,
                                sow_name: sow_name,
                                worklogs: [],
                                tasks: [],
                                build_id: groupKey
                            };

                        }
                        groupedItems[groupKey].worklogs = build.worklogs;
                        if (build.tasks) {
                            groupedItems[groupKey].tasks = build.tasks;
                        }
                    });

                    Object.values(groupedItems).forEach(item => {
                        const timeByDate = {};
                        newDateArray.forEach(date => {
                            timeByDate[date] = 0;
                        });

                        item.worklogs && item.worklogs.forEach(worklog => {
                            const logDate = moment(worklog.created_at).format('DD MMM YYYY ddd');
                            if (timeByDate[logDate] !== undefined) {
                                timeByDate[logDate] += parseFloat(worklog.time_spent_hours);
                            }
                        });

                        filteredData.push({
                            type: 'user',
                            name: <span style={{ paddingLeft: '16px' }}>{item.sow_name}</span>,
                            data: newDateArray.map(date => timeByDate[date] ? timeByDate[date].toFixed(2) : ''),
                            total: (Object.values(timeByDate).reduce((a, b) => a + b, 0) || 0).toFixed(2).replace(/^0\.00$/, ''),
                            user_id: user.id,
                            build_id: item.build_id
                        });

                        if (groupByArray.length > 1) {

                            // Build -> Tasks

                            const groupedItems = {};

                            item.tasks && item.tasks.forEach(task => {

                                const groupKey =
                                    (groupByArray[1] === 'summary' ? task.summary :
                                        groupByArray[1] === 'status' ? task.status :
                                            groupByArray[1] === 'priority' ? task.priority :
                                                task.billing);

                                if (!groupedItems[groupKey]) {
                                    groupedItems[groupKey] = {
                                        key: groupKey,
                                        worklogs: [],
                                        user_id: user.id,
                                        build_id: item.build_id,
                                        task_attribute: groupByArray[1]
                                    };
                                }
                                groupedItems[groupKey].worklogs.push(...task.worklogs);
                            });

                            Object.values(groupedItems).forEach(item => {
                                const timeByDate = {};
                                newDateArray.forEach(date => {
                                    timeByDate[date] = 0;
                                });

                                item.worklogs.forEach(worklog => {
                                    const logDate = moment(worklog.created_at).format('DD MMM YYYY ddd');
                                    if (timeByDate[logDate] !== undefined) {
                                        timeByDate[logDate] += parseFloat(worklog.time_spent_hours);
                                    }
                                });

                                filteredData.push({
                                    type: 'user',
                                    name: <span style={{ paddingLeft: '32px' }}>{item.key}</span>,
                                    data: newDateArray.map(date => timeByDate[date] ? timeByDate[date].toFixed(2) : ''),
                                    total: (Object.values(timeByDate).reduce((a, b) => a + b, 0) || 0).toFixed(2).replace(/^0\.00$/, ''),
                                    user_id: item.user_id,
                                    build_id: item.build_id,
                                    task_attribute: item.task_attribute,
                                    task_attribute_value: item.key
                                });

                                const [, , comment] = groupByArray;
                                if (comment && comment.length > 0) {
                                    const groupedWorklogs = {};

                                    item.worklogs.forEach(worklog => {
                                        const groupKey = worklog.id;

                                        if (!groupedWorklogs[groupKey]) {
                                            groupedWorklogs[groupKey] = {
                                                key: groupKey,
                                                worklog_comment: worklog.comment,
                                                worklogs: [],
                                                user_id: item.user_id,
                                                build_id: item.build_id,
                                                task_attribute: groupByArray[1]
                                            };
                                        }
                                        groupedWorklogs[groupKey].worklogs.push(worklog);
                                    });

                                    Object.values(groupedWorklogs).forEach(worklogItem => {
                                        const timeByDate = {};
                                        newDateArray.forEach(date => {
                                            timeByDate[date] = 0;
                                        });

                                        worklogItem.worklogs.forEach(worklog => {
                                            const logDate = moment(worklog.created_at).format('DD MMM YYYY ddd');
                                            if (timeByDate[logDate] !== undefined) {
                                                timeByDate[logDate] += parseFloat(worklog.time_spent_hours);
                                            }
                                        });

                                        filteredData.push({
                                            type: 'user',
                                            name: <span style={{ paddingLeft: '52px' }}><i>{worklogItem.worklog_comment.length > 25 ? worklogItem.worklog_comment.substring(0, 25) + '...' : worklogItem.worklog_comment}</i></span>,
                                            data: newDateArray.map(date => timeByDate[date] ? timeByDate[date].toFixed(2) : ''),
                                            total: (Object.values(timeByDate).reduce((a, b) => a + b, 0) || 0).toFixed(2).replace(/^0\.00$/, ''),
                                            user_id: worklogItem.user_id,
                                            build_id: worklogItem.build_id,
                                            task_attribute: worklogItem.task_attribute,
                                            task_attribute_value: worklogItem.key,
                                            worklog_id: worklogItem.key
                                        });
                                    });
                                }
                            });
                        }
                    });
                }
            });
            setTableData(filteredData);
        }
        // BUILD GROUPING
        else if (groupType === 'build') {
            const uniqueGroupKeys = new Set();

            builds.forEach((build) => {
                const timeByDate = {};
                newDateArray.forEach(date => {
                    timeByDate[date] = 0;
                });

                build.worklogs && build.worklogs.forEach(worklog => {
                    const logDate = moment(worklog.created_at).format('DD MMM YYYY ddd');
                    if (timeByDate[logDate] !== undefined) {
                        timeByDate[logDate] += parseFloat(worklog.time_spent_hours);
                    }
                });

                const buildEntry = {
                    type: 'build',
                    name: groupType === 'build_status' ?
                        <div style={{ fontWeight: '700' }}>
                            <Tag color="processing">{build.status}</Tag>
                        </div> :
                        groupType === 'build_account' ?
                            <div style={{ fontWeight: '700' }}>{build.account_name}</div> :
                            <div style={{ fontWeight: '700' }}>{build.sow_name}</div>,
                    data: newDateArray.map(date => timeByDate[date] ? <span style={{ fontWeight: '700' }}>{timeByDate[date].toFixed(2)}</span> : ''),
                    total: <span style={{ fontWeight: '700' }}>{(Object.values(timeByDate).reduce((a, b) => a + b, 0) || 0).toFixed(2).replace(/^0\.00$/, '')}</span>,
                    build_id: build.id
                };

                filteredData.push(buildEntry);
                uniqueGroupKeys.add(build.id);

                if (groupByArray && groupByArray.length > 0) {

                    // User

                    const groupedItems = [];

                    if (groupByArray[0] === 'user') {
                        build.users.forEach(user => {
                            const groupKey = user.user_id;
                            const name = user.name;

                            if (!groupedItems[groupKey]) {
                                groupedItems[groupKey] = {
                                    key: groupKey,
                                    user_name: name,
                                    worklogs: [],
                                    tasks: []
                                };
                            }

                            user.worklogs.forEach(worklog => {
                                groupedItems[groupKey].worklogs.push(worklog);
                            });

                            user.tasks.forEach(task => {
                                const taskWorklogs = task.worklogs.filter(log => log.user_id === user.user_id);

                                if (taskWorklogs.length > 0) {
                                    groupedItems[groupKey].tasks.push({
                                        ...task,
                                        worklogs: taskWorklogs
                                    });
                                }
                            });
                        });

                        Object.values(groupedItems).forEach(item => {
                            const user_id = item.key;
                            const timeByDate = {};
                            newDateArray.forEach(date => {
                                timeByDate[date] = 0;
                            });

                            item.worklogs.forEach(worklog => {
                                const logDate = moment(worklog.created_at).format('DD MMM YYYY ddd');
                                if (timeByDate[logDate] !== undefined) {
                                    timeByDate[logDate] += parseFloat(worklog.time_spent_hours);
                                }
                            });

                            filteredData.push({
                                type: 'build',
                                name: <span style={{ paddingLeft: '16px' }}>{item.user_name}</span>,
                                data: newDateArray.map(date => timeByDate[date] ? timeByDate[date].toFixed(2) : ''),
                                total: (Object.values(timeByDate).reduce((a, b) => a + b, 0) || 0).toFixed(2).replace(/^0\.00$/, ''),
                                build_id: build.id,
                                user_id: user_id
                            });

                            if (item.tasks.length > 0 && groupByArray.length > 1 && (groupByArray[1] === 'summary' || groupByArray[1] === 'status' || groupByArray[1] === 'priority' || groupByArray[1] === 'billing')) {
                                const groupedItems = {};

                                item.tasks.forEach(task => {
                                    const timeByDate = {};
                                    newDateArray.forEach(date => {
                                        timeByDate[date] = 0;
                                    });

                                    const groupKey =
                                        groupByArray[1] === 'summary' ? task.summary :
                                            groupByArray[1] === 'status' ? task.status :
                                                groupByArray[1] === 'priority' ? task.priority : task.billing;

                                    if (!groupedItems[groupKey]) {
                                        groupedItems[groupKey] = {
                                            key: groupKey,
                                            worklogs: [],
                                            task_attribute: groupByArray[1],
                                        };
                                    }

                                    groupedItems[groupKey].worklogs.push(...task.worklogs);
                                });

                                Object.values(groupedItems).forEach(item => {
                                    const timeByDate = {};
                                    newDateArray.forEach(date => {
                                        timeByDate[date] = 0;
                                    });

                                    item.worklogs.forEach(worklog => {
                                        const logDate = moment(worklog.created_at).format('DD MMM YYYY ddd');
                                        if (timeByDate[logDate] !== undefined) {
                                            timeByDate[logDate] += parseFloat(worklog.time_spent_hours);
                                        }
                                    });

                                    filteredData.push({
                                        type: 'build',
                                        name: <span style={{ paddingLeft: '32px' }}>{item.key}</span>,
                                        data: newDateArray.map(date => timeByDate[date] ? timeByDate[date].toFixed(2) : ''),
                                        total: (Object.values(timeByDate).reduce((a, b) => a + b, 0) || 0).toFixed(2).replace(/^0\.00$/, ''),
                                        build_id: build.id,
                                        task_attribute: item.task_attribute,
                                        task_attribute_value: item.key,
                                        user_id: user_id,
                                    });
                                });
                            }
                        })
                    }
                    else {

                        // Task

                        const groupedItems = {};
                        build.tasks.forEach(task => {
                            const groupKey =
                                groupByArray[0] === 'summary' ? task.summary :
                                    groupByArray[0] === 'status' ? task.status :
                                        groupByArray[0] === 'priority' ? task.priority : task.billing;

                            if (!groupedItems[groupKey]) {
                                groupedItems[groupKey] = {
                                    key: groupKey,
                                    worklogs: [],
                                    task_attribute: groupByArray[0],
                                    task_key: task.key
                                };
                            }
                            groupedItems[groupKey].worklogs.push(task.worklogs);
                        });

                        Object.values(groupedItems).forEach(item => {
                            const timeByDate = {};
                            newDateArray.forEach(date => {
                                timeByDate[date] = 0;
                            });

                            item.worklogs.forEach(worklog => {
                                worklog.forEach(worklog => {
                                    const logDate = moment(worklog.created_at).format('DD MMM YYYY ddd');
                                    if (timeByDate[logDate] !== undefined) {
                                        timeByDate[logDate] += parseFloat(worklog.time_spent_hours);
                                    }
                                })
                            });

                            filteredData.push({
                                type: 'build',
                                name: <span style={{ paddingLeft: '16px' }}>{item.key}</span>,
                                data: newDateArray.map(date => timeByDate[date] ? timeByDate[date].toFixed(2) : ''),
                                total: (Object.values(timeByDate).reduce((a, b) => a + b, 0) || 0).toFixed(2).replace(/^0\.00$/, ''),
                                build_id: build.id,
                                task_key: item.task_key,
                                task_attribute: item.task_attribute,
                                task_attribute_value: item.key,
                            });
                        });
                    }
                }
                setTableData(filteredData);
            }
            )
        }
        // ACCOUNT GROUPING
        else {
            const uniqueGroupKeys = new Set();

            accounts.forEach((account) => {
                const timeByDate = {};
                newDateArray.forEach(date => {
                    timeByDate[date] = 0;
                });

                if (account.builds) {
                    account.builds && account.builds.forEach(build => {
                        if (build.worklogs) {
                            build.worklogs.forEach(worklog => {
                                const logDate = moment(worklog.created_at).format('DD MMM YYYY ddd');
                                if (timeByDate[logDate] !== undefined) {
                                    timeByDate[logDate] += parseFloat(worklog.time_spent_hours);
                                }
                            });
                        }
                    })
                }

                const accountEntry = {
                    type: 'account',
                    name:
                        <div style={{ fontWeight: '700' }}>
                            {account.account_name}
                        </div>,
                    data: newDateArray.map(date => timeByDate[date] ? <span style={{ fontWeight: '700' }}>{timeByDate[date].toFixed(2)}</span> : ''),
                    total: <span style={{ fontWeight: '700' }}>{(Object.values(timeByDate).reduce((a, b) => a + b, 0) || 0).toFixed(2).replace(/^0\.00$/, '')}</span>,
                    account_id: account.id
                };

                filteredData.push(accountEntry);
                uniqueGroupKeys.add(account.id);

                if (groupByArray[0]) {

                    // Build

                    if (groupByArray[0] === 'sow_name' || groupByArray[0] === 'administrator') {
                        const groupedItems = {};
                        account.builds.forEach(build => {
                            const groupKey =
                                groupByArray[0] === 'sow_name' ? build.name :
                                    groupByArray[0] === 'administrator' ? build.administrator_name : build.administrator_name;
                            if (!groupedItems[groupKey]) {
                                groupedItems[groupKey] = {
                                    key: groupKey,
                                    worklogs: [],
                                    build_attribute: groupByArray[0],
                                    build_id: build.id,
                                    build: build
                                };
                            }
                            groupedItems[groupKey].worklogs.push(build.worklogs);
                        });

                        Object.values(groupedItems).forEach(item => {
                            const timeByDate = {};
                            const build_attribute = item.build_attribute;
                            const build_attribute_value = item.key;
                            newDateArray.forEach(date => {
                                timeByDate[date] = 0;
                            });

                            item.worklogs.forEach(worklog => {
                                worklog.forEach(worklog => {
                                    const logDate = moment(worklog.created_at).format('DD MMM YYYY ddd');
                                    if (timeByDate[logDate] !== undefined) {
                                        timeByDate[logDate] += parseFloat(worklog.time_spent_hours);
                                    }
                                })
                            });

                            filteredData.push({
                                type: 'account',
                                name: <span style={{ paddingLeft: '16px' }}>{item.key}</span>,
                                data: newDateArray.map(date => timeByDate[date] ? timeByDate[date].toFixed(2) : ''),
                                total: (Object.values(timeByDate).reduce((a, b) => a + b, 0) || 0).toFixed(2).replace(/^0\.00$/, ''),
                                account_id: account.id,
                                build_id: item.build_id,
                                build_attribute: build_attribute,
                                build_attribute_value: build_attribute_value
                            });


                            // Users

                            if (groupByArray.length > 1) {
                                const groupedItems = {};

                                if (groupByArray[1] === 'user') {
                                    item.build.users.forEach(user => {
                                        const groupKey = user.user_id;

                                        if (!groupedItems[groupKey]) {
                                            groupedItems[groupKey] = {
                                                key: groupKey,
                                                user_name: user.user_name,
                                                worklogs: [],
                                                user_id: user.user_id
                                            };
                                        }

                                        groupedItems[groupKey].worklogs.push(...user.worklogs);
                                    });

                                    Object.values(groupedItems).forEach(item => {
                                        const timeByDate = {};
                                        newDateArray.forEach(date => {
                                            timeByDate[date] = 0;
                                        });

                                        item.worklogs.forEach(worklog => {
                                            const logDate = moment(worklog.created_at).format('DD MMM YYYY ddd');
                                            if (timeByDate[logDate] !== undefined) {
                                                timeByDate[logDate] += parseFloat(worklog.time_spent_hours);
                                            }
                                        });

                                        filteredData.push({
                                            type: 'account',
                                            name: <span style={{ paddingLeft: '32px' }}>{item.user_name}</span>,
                                            data: newDateArray.map(date => timeByDate[date] ? timeByDate[date].toFixed(2) : ''),
                                            total: (Object.values(timeByDate).reduce((a, b) => a + b, 0) || 0).toFixed(2).replace(/^0\.00$/, ''),
                                            account_id: account.id,
                                            user_id: item.user_id,
                                            build_attribute: build_attribute,
                                            build_attribute_value: build_attribute_value
                                        });
                                    });
                                }
                            }
                        });
                    } else if (groupByArray[0] !== 'user') {

                        // Task

                        const groupedItems = {};
                        account.builds && account.builds.forEach(build => {
                            if (build.tasks) {
                                build.tasks.forEach(task => {
                                    const groupKey =
                                        groupByArray[0] === 'summary' ? task.summary :
                                            groupByArray[0] === 'status' ? task.status :
                                                groupByArray[0] === 'priority' ? task.priority : task.billing;

                                    if (!groupedItems[groupKey]) {
                                        groupedItems[groupKey] = {
                                            key: groupKey,
                                            worklogs: []
                                        };
                                    }

                                    if (task.worklogs) {
                                        task.worklogs.forEach(worklog => {
                                            groupedItems[groupKey].worklogs.push(worklog);
                                        });
                                    }
                                });
                            }
                        });

                        Object.values(groupedItems).forEach(item => {
                            const timeByDate = {};
                            newDateArray.forEach(date => {
                                timeByDate[date] = 0;
                            });

                            item.worklogs.forEach(worklog => {
                                const logDate = moment(worklog.created_at).format('DD MMM YYYY ddd');
                                if (timeByDate[logDate] !== undefined) {
                                    timeByDate[logDate] += parseFloat(worklog.time_spent_hours);
                                }
                            });

                            filteredData.push({
                                type: 'grouped',
                                name: <span style={{ paddingLeft: '16px' }}>{item.key}</span>,
                                data: newDateArray.map(date => timeByDate[date] ? timeByDate[date].toFixed(2) : ''),
                                total: (Object.values(timeByDate).reduce((a, b) => a + b, 0) || 0).toFixed(2).replace(/^0\.00$/, '')
                            });
                        });
                    } else {

                        const groupedByUser = {};

                        account.builds && account.builds.forEach(build => {
                            if (build.children) {
                                build.children.forEach(task => {
                                    if (task.worklogs) {
                                        task.worklogs.forEach(worklog => {
                                            const userId = worklog.user_id;
                                            if (!groupedByUser[userId]) {
                                                groupedByUser[userId] = {
                                                    userId: userId,
                                                    worklogs: [],
                                                };
                                            }
                                            groupedByUser[userId].worklogs.push(worklog);
                                        });
                                    }
                                });
                            }
                        });

                        Object.values(groupedByUser).forEach(user => {
                            const timeByDate = {};
                            newDateArray.forEach(date => {
                                timeByDate[date] = 0;
                            });

                            user.worklogs.forEach(worklog => {
                                const logDate = moment(worklog.created_at).format('DD MMM YYYY ddd');
                                if (timeByDate[logDate] !== undefined) {
                                    timeByDate[logDate] += parseFloat(worklog.time_spent_hours);
                                }
                            });

                            filteredData.push({
                                type: 'grouped',
                                name: <span style={{ paddingLeft: '16px' }}>{user.userId}</span>,
                                data: newDateArray.map(date => timeByDate[date] ? timeByDate[date].toFixed(2) : ''),
                                total: (Object.values(timeByDate).reduce((a, b) => a + b, 0) || 0).toFixed(2).replace(/^0\.00$/, '')
                            });

                            // Tasks

                            // const groupedItems = {};

                            // user.tasks && user.tasks.forEach(task => {
                            //     const timeByDate = {};
                            //     newDateArray.forEach(date => {
                            //         timeByDate[date] = 0;
                            //     });

                            //     const groupKey =
                            //         groupByArray[1] === 'summary' ? task.summary :
                            //             groupByArray[1] === 'status' ? task.status :
                            //                 groupByArray[1] === 'priority' ? task.priority : task.billing;

                            //     if (!groupedItems[groupKey]) {
                            //         groupedItems[groupKey] = {
                            //             key: groupKey,
                            //             worklogs: []
                            //         };
                            //     }

                            //     groupedItems[groupKey].worklogs.push(...task.worklogs);
                            // });

                            // Object.values(groupedItems).forEach(item => {
                            //     const timeByDate = {};
                            //     newDateArray.forEach(date => {
                            //         timeByDate[date] = 0;
                            //     });

                            //     item.worklogs.forEach(worklog => {
                            //         const logDate = moment(worklog.created_at).format('DD MMM YYYY ddd');
                            //         if (timeByDate[logDate] !== undefined) {
                            //             timeByDate[logDate] += parseFloat(worklog.time_spent_hours);
                            //         }
                            //     });

                            //     filteredData.push({
                            //         type: 'grouped',
                            //         name: <span style={{ paddingLeft: '32px' }}>{item.key}</span>,
                            //         data: newDateArray.map(date => timeByDate[date] ? timeByDate[date].toFixed(2) : ''),
                            //         total: (Object.values(timeByDate).reduce((a, b) => a + b, 0) || 0).toFixed(2).replace(/^0\.00$/, '')
                            //     });
                            // });

                        });
                    }
                }
                setTableData(filteredData);
            })
        }
    }

    const getColumnTitle = () => {

        if (!selectedGroupType) {
            return 'No Grouping Selected';
        }

        const groupLabels = Array.isArray(selectedGroup)
            ? selectedGroup.map(group => group.label).join(' / ')
            : selectedGroup?.label || '';

        return `${selectedGroupType.label}${groupLabels ? ' / ' + groupLabels : ''}`;
    };


    // const handleTableChange = useCallback(async (pagination, filters, sorter, userData) => {

    //     try {
    //         setLoading(true);
    //         if (!sorter.column) {
    //             let filteredUserData;
    //             if (filters.name && filters.name.length > 0) {
    //                 if (selectedGroupType.value === 'user') {
    //                     filteredUserData = await ServiceUsers.applyFilter(
    //                         selectedGroup[0].value,
    //                         filters.name
    //                     );
    //                     setUsers(filteredUserData.data);
    //                 }

    //                 if (selectedGroupType.value === 'build') {
    //                     filteredUserData = await ServiceBuilds.applyFilter(
    //                         selectedGroup[0].value,
    //                         filters.name
    //                     );
    //                     setBuilds(filteredUserData.data);
    //                 }
    //             }
    //             setSelectedFilters(filters);
    //         } else {
    //             if (selectedGroupType.value === 'user') {
    //                 let sortedUserData = await ServiceUsers.applySorter(sorter.order);
    //                 setUsers(sortedUserData.data);
    //             }

    //             if (selectedGroupType.value === 'build') {
    //                 let sortedBuildData = await ServiceBuilds.applySorter(sorter.order);
    //                 setBuilds(sortedBuildData.data);
    //             }
    //         }
    //         setLoading(false);
    //     } catch (error) {
    //         console.error('Failed to fetch data:', error);
    //     }
    // }, [selectedGroupType, selectedGroup]);

    const columns = useMemo(() => {
        // const dynamicFilterOptions = filters ? filters : [];

        return [
            {
                title: <span style={{ color: '#0047AB' }}>{getColumnTitle()}</span>,
                dataIndex: 'name',
                key: 'name',
                fixed: 'left',
                width: 275,
                align: 'left',
                showSorterTooltip: {
                    target: 'full-header',
                },
                // filters: dynamicFilterOptions,
                filterSearch: true,
                // sorter: true,
            },
            ...dateArray.map((date, idx) => {

                const [day, month, year, dayOfWeek] = date.split(' ');
                const dateMoment = dayjs(date, 'DD MMM YYYY ddd');
                const isWeekend = dateMoment.day() === 6 || dateMoment.day() === 0;
                const isToday = dateMoment.isSame(dayjs(), 'day');

                let className = 'basic-cell';
                if (isWeekend) {
                    className = 'weekend-cell';
                }
                if (isToday) {
                    className = `${className} weekend-today-bordered`.trim();
                }
                            
                return {
                    title: (
                        <div key={`date-${idx}`}>
                            {day === '01' ? <span style={{ color: 'red' }}>{month}</span> : <div style={{ color: day === '01' ? 'red' : 'black' }}>{day}</div>}
                            <div style={{ fontSize: '0.8em', color: '#888' }}>{dayOfWeek}</div>
                        </div>
                    ),
                    className: className,
                    dataIndex: `date-${idx}`,
                    key: `date-${idx}`,
                    width: 50,
                    align: 'center',
                    showSorterTooltip: {
                        target: 'full-header',
                    },
                    render: (text, record) => {
                        const dataForThisDay = record.data && record.data[idx] ? record.data[idx] : null;
                        const dateForThisDay = `${day} ${month} ${year}`;

                        // User
                        const user_id = record.user_id ? record.user_id : null;
                        const task_key = record.task_key ? record.task_key : null;
                        const task_attribute = record.task_attribute ? record.task_attribute : null;
                        const task_attribute_value = record.task_attribute_value ? record.task_attribute_value : null;
                        const build_id = record.build_id ? record.build_id : null;
                        const build_attribute = record.build_attribute ? record.build_attribute : null;
                        const build_attribute_value = record.build_attribute_value ? record.build_attribute_value : null;
                        const account_id = record.account_id ? record.account_id : null;
                        const worklog_id = record.worklog_id ? record.worklog_id : null;

                        return (
                            <div style={{ width: '100%', height: '100%' }} onClick={() => showModal({
                                data: dataForThisDay,
                                date: dateForThisDay,
                                user_id: user_id,
                                task_key: task_key,
                                task_attribute: task_attribute,
                                task_attribute_value: task_attribute_value,
                                build_id: build_id,
                                build_attribute: build_attribute,
                                build_attribute_value: build_attribute_value,
                                type: record.type,
                                account_id: account_id,
                                worklog_id: worklog_id
                            })}>
                                {record.data[idx]}
                            </div>
                        );
                    },
                };
            }),
            {
                title: <span style={{ color: '#0047AB' }}>Total</span>,
                dataIndex: 'total',
                key: 'total',
                fixed: 'right',
                width: 100,
                align: 'center',
                showSorterTooltip: {
                    target: 'full-header',
                },
                render: (text) => <span>{text}</span>,
            },
        ];
    }, [dateArray, getColumnTitle, users]);


    const handleGroupChange = (selectedOption, option) => {
        setSelectedGroupType({ value: selectedOption, label: option.label });
        // setSelectedFilters([]);
        setSelectedGroup([]);
    };

    const handleGroupByChange = (value, option) => {
        setSelectedGroup(value.map((val, index) => ({ value: val, label: option[index].label })));
        if (value.length === 0) {
            setFilters({});
        }
    };

    const options = selectedGroupType && selectedGroupType.value === 'user' ? [
        { value: 'summary', label: 'Summary', type: 'task', desc: <div><b style={{ color: '#3d91d6', paddingRight: '4px' }}>Task</b> <span>Summary</span></div> },
        { value: 'status', label: 'Status', type: 'task', desc: <div><b style={{ color: '#3d91d6', paddingRight: '4px' }}>Task</b> <span>Status</span></div> },
        { value: 'priority', label: 'Priority', type: 'task', desc: <div><b style={{ color: '#3d91d6', paddingRight: '4px' }}>Task</b> <span>Priority</span></div> },
        { value: 'billing', label: 'Billing', type: 'task', desc: <div><b style={{ color: '#3d91d6', paddingRight: '4px' }}>Task</b> <span>Billing</span></div> },
        { value: 'sow_name', label: 'SoW', type: 'build', desc: <div><b style={{ color: '#3d91d6', paddingRight: '4px' }}>Build</b> <span>SoW</span></div> },
    ] : selectedGroupType && selectedGroupType.value === 'build' ? [
        { value: 'summary', label: 'Summary', type: 'task', desc: <div><b style={{ color: '#3d91d6', paddingRight: '4px' }}>Task</b> <span>Summary</span></div> },
        { value: 'status', label: 'Status', type: 'task', desc: <div><b style={{ color: '#3d91d6', paddingRight: '4px' }}>Task</b> <span>Status</span></div> },
        { value: 'priority', label: 'Priority', type: 'task', desc: <div><b style={{ color: '#3d91d6', paddingRight: '4px' }}>Task</b> <span>Priority</span></div> },
        { value: 'billing', label: 'Billing', type: 'task', desc: <div><b style={{ color: '#3d91d6', paddingRight: '4px' }}>Task</b> <span>Billing</span></div> },
        { value: 'user', label: 'Name', type: 'user', desc: <div><b style={{ color: '#3d91d6', paddingRight: '4px' }}>User</b> <span>Name</span></div> }
    ] : selectedGroupType && selectedGroupType.value === 'account' ? [
        { value: 'summary', label: 'Summary', type: 'task', desc: <div><b style={{ color: '#3d91d6', paddingRight: '4px' }}>Task</b> <span>Summary</span></div> },
        { value: 'status', label: 'Status', type: 'task', desc: <div><b style={{ color: '#3d91d6', paddingRight: '4px' }}>Task</b> <span>Status</span></div> },
        { value: 'priority', label: 'Priority', type: 'task', desc: <div><b style={{ color: '#3d91d6', paddingRight: '4px' }}>Task</b> <span>Priority</span></div> },
        { value: 'billing', label: 'Billing', type: 'task', desc: <div><b style={{ color: '#3d91d6', paddingRight: '4px' }}>Task</b> <span>Billing</span></div> },
        { value: 'sow_name', label: 'SoW', type: 'build', desc: <div><b style={{ color: '#3d91d6', paddingRight: '4px' }}>Build</b> <span>SoW</span></div> },
        { value: 'user', label: 'Name', type: 'user', desc: <div><b style={{ color: '#3d91d6', paddingRight: '4px' }}>User</b> <span>Name</span></div> },
    ] : [];

    const additionalValues = ['summary', 'priority', 'billing', 'status'];

    if (selectedGroupType &&
        selectedGroupType.value === 'user' &&
        (selectedGroup[0]?.value === 'sow_name' && additionalValues.includes(selectedGroup[1]?.value))
    ) {
        if (!options.some(o => o.value === 'comment')) {
            options.push({
                value: 'comment',
                label: 'Worklog',
                type: 'worklog',
                desc: <div><b style={{ color: '#3d91d6', paddingRight: '4px' }}>Worklog</b> <span>Comment</span></div>
            });
        }
    }

    const filteredOptions = options.filter((o) => {
        const selectedValue = selectedGroup[0]?.value;
        const selectedSecondValue = selectedGroup[1]?.value;

        if (additionalValues.includes(selectedSecondValue)) {
            return o.value === selectedValue || o.value === selectedSecondValue || o.value === 'comment';
        }

        if (selectedValue) {

            if (selectedGroupType.value === 'user') {
                if (selectedValue === 'sow_name') {
                    return o.value === selectedValue || ['summary', 'priority', 'billing', 'status', 'comment'].includes(o.value);
                }
                return o.value === selectedValue;
            }

            if (selectedGroupType.value === 'build') {
                if (selectedValue === 'user') {
                    return o.value === selectedValue || ['summary', 'priority', 'billing', 'status'].includes(o.value);
                }

                return o.value === selectedValue;
            }

            if (selectedGroupType.value === 'account') {
                if (selectedValue === 'sow_name') {
                    return o.value === selectedValue || o.value === 'user';
                }

                if (selectedValue !== 'sow_name') {
                    return o.value === selectedValue;
                }
            }
        }

        return true;
    });

    return (
        <ConfigProvider
            theme={{
                components: {
                    Table: {
                        borderColor: '#cbcbcb',
                        headerBorderRadius: 0,
                        cellFontSize: 12,
                        cellFontSizeMD: 12,
                        cellFontSizeSM: 12
                    },
                },
            }}
        >
            <div className='tempo'>
                <h1 style={{ fontSize: '24px', fontWeight: 'bold' }}>
                    <CalendarTwoTone twoToneColor="blue" style={{ marginRight: '8px' }} />
                    Tempo Timesheet
                </h1>
                <div className='control-section'>
                    <div>
                        <div className='calendar-switcher'>
                            <div>
                                <Button onClick={handlePrevPeriod}>&lt;</Button>
                            </div>
                            <div>
                                <RangePicker
                                    presets={rangePresets}
                                    value={startDate && endDate ? [dayjs(startDate), dayjs(endDate)] : []}
                                    style={{
                                        width: 250,
                                    }}
                                    onChange={(dates) => {
                                        setStartDate(dates ? dayjs(dates[0]).toDate() : null);
                                        setEndDate(dates ? dayjs(dates[1]).toDate() : null);
                                    }}
                                />
                            </div>
                            <div>
                                <Button onClick={handleNextPeriod}>&gt;</Button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Select
                            showSearch
                            className='select-group'
                            style={{
                                width: 200,
                            }}
                            size='medium'
                            onChange={handleGroupChange}
                            placeholder="Inital Grouping"
                            // defaultValue='build'
                            options={[
                                {
                                    label: <span>User</span>,
                                    title: 'User',
                                    options: [
                                        { value: 'user', label: 'User' },
                                    ]
                                },
                                {
                                    label: <span>Build</span>,
                                    title: 'Build',
                                    options: [
                                        { value: 'build', label: 'Build' },
                                    ]
                                },
                                {
                                    label: <span>Account</span>,
                                    title: 'Account',
                                    options: [
                                        { value: 'account', label: 'Account' },
                                    ]
                                },
                            ]}
                        />
                    </div>
                    {selectedGroupType && (
                        <div>
                            <Select
                                showSearch
                                maxCount={3}
                                mode="multiple"
                                placeholder="Additional Grouping"
                                style={{
                                    width: '280px',
                                }}
                                value={selectedGroup.map(item => item.value)}
                                onChange={handleGroupByChange}
                                options={filteredOptions}
                                filterOption={(input, option) => {
                                    return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }}
                                optionRender={(option) => (
                                    <Space>
                                        {option.data.desc}
                                    </Space>
                                )}
                            />
                        </div>
                    )}
                    {/* <div>
                        <Button type="dashed" onClick={clearFilters}>
                            Clear Filters
                        </Button>
                    </div> */}
                    <FilterComponent onFilter={handleFilter} selectedGroup={selectedGroup} />
                    <div>
                        <Button type="primary" shape="round" icon={<DownloadOutlined />} onClick={handleExportCSV}>
                            CSV
                        </Button>
                    </div>
                </div>
                <div className='hint'>
                    <span>Tempo v.1.0.0</span>
                </div>

                <StyledTable
                    columns={columns}
                    dataSource={tableData}
                    pagination={false}
                    scroll={{ x: 'max-content', y: '55vh' }}
                    bordered
                    size='small'
                    loading={loading}
                    // onChange={handleTableChange}
                    summary={() => (
                        <Table.Summary fixed>

                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0}><span style={{ fontWeight: '700' }}>Total</span></Table.Summary.Cell>
                                {dateArray && dateArray.map((_, idx) => {
                                    const sum = tableData.reduce((acc, row) => {
                                        return acc + (parseFloat(row.data[idx].props ? row.data[idx].props.children : '') || 0);
                                    }, 0);

                                    return (
                                        <Table.Summary.Cell key={`summary-date-${idx}`} index={idx + 1} align='center'>
                                            <span style={{ fontWeight: '700' }}>{sum > 0 ? sum.toFixed(2) : ''}</span>
                                        </Table.Summary.Cell>
                                    );
                                })}
                                <Table.Summary.Cell key="summary-total" index={dateArray.length + 1} align='center'>
                                    <span style={{ fontWeight: '700' }}>
                                        {tableData.reduce((acc, row) => acc + (parseFloat(row.total.props ? row.total.props.children : 0) || 0), 0) > 0 ?
                                            tableData.reduce((acc, row) => acc + (parseFloat(row.total.props ? row.total.props.children : 0) || 0), 0).toFixed(2) : ''}
                                    </span>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </Table.Summary>
                    )
                    }
                />
                <TempoModal
                    isVisible={isModalVisible}
                    content={modalContent}
                    handleOk={handleOk}
                    handleCancel={handleCancel}
                    loading={modalLoading}
                    onDataChange={handleDataChange}
                />
            </div >
        </ConfigProvider>

    );
};

export default Tempo;