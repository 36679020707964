import { Col, Form, Input, InputNumber, Modal, Row, Select, Button } from "antd";
import { useEffect, useState } from "react";

const { v4: uuidv4 } = require('uuid');

const ActivityAssignmentAddModal = (props) => {
    const [form] = Form.useForm();
    const [activities, setActivities] = useState([]);
    const [selectedActivity, setSelectedActivity] = useState();

    useEffect(() => {
        setActivities(props.activities);
    }, [props.activities]);

    const onCancel = () => {
        props.onClose();
        form.resetFields(["name", "estimate", "summary"]);
    };

    const handleSelectChange = (value) => {
        const selectedActivity = props.activities.find((activity) => activity.id === value);
        console.log('selectedActivity', selectedActivity);
        setSelectedActivity(selectedActivity);
        form.setFieldsValue({
            name: selectedActivity.name,
            summary: selectedActivity.default_summary,
        });
    };

    const onFinish = (values) => {
        // Your existing logic for handling validated fields
        values.activity_id = selectedActivity.id;
        values.role_name = selectedActivity.role_name;
        values.role = selectedActivity.default_role;
        values.remaining_estimate = values.estimate;
        values.not_allocated_budget = values.estimate;
        values.order = props.index;
        values.status = selectedActivity.status;
        values.id = uuidv4();
        console.log('values', values);
        props.onAdd(values);
        form.resetFields(["name", "estimate", "summary"]);
        props.onClose();
    };

    return (
        <Modal
            visible={props.open}
            title="Add Activity Assignment"
            onCancel={onCancel}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    Cancel
                </Button>,
                <Button key="add" type="primary" onClick={() => form.submit()}>
                    Add
                </Button>,
            ]}
        >
            <Form
                form={form}
                onFinish={onFinish}
                layout="vertical"
                name="form_in_modal"
            >
                <Row gutter={4}>
                    <Col span={12}>
                        <Form.Item
                            name="name"
                            label="Name"
                            rules={[
                                { required: true, message: 'Please select an activity' }
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Select a name..."
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={handleSelectChange}
                            >
                                {activities.map((activity) => {
                                    return <Select.Option key={activity.id} value={activity.id}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <div>{activity.name}</div>
                                            <div style={{ fontWeight: '500' }}>{activity.role_name}</div>
                                        </div>
                                    </Select.Option>
                                })}
                            </Select>
                        </Form.Item>

                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="estimate"
                            label="Estimate (h)"
                            required={true}
                            rules={[
                                {
                                    validator: (rule, value) => {

                                        if (value && value <= 0) {
                                            return Promise.reject('Duration must be a positive number');
                                        }
                                        if (value && !/^\d+(\.\d{1,2})?$/.test(value)) {
                                            return Promise.reject('Duration must be a number with up to 2 decimal places');
                                        }                                        
                                        return Promise.resolve();
                                    },
                                },
                                { required: true, message: 'Please enter an estimate' }
                            ]}
                        >
                            <InputNumber
                                step={0.25}
                                maxLength={8}
                                parser={(value) => value.replace(',', '.')}
                            />
                        </Form.Item>
                    </Col>

                </Row>

                <Row>
                    <Col span={24}>
                        <Form.Item name="summary" label="Summary">
                            <Input.TextArea
                                rows={3}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default ActivityAssignmentAddModal;