// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Anuphan:wght@300;400;500&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".issue-modal .modal-content {\n  overflow: auto;\n  overflow-x: hidden;\n  max-height: calc(100vh - 200px);\n}\n\n.editor {\n  margin-bottom: 12px;\n}\n\n.editor div {\n  font-family: 'Anuphan' !important;\n}\n\n/* .arrow-container {\n  margin-left: 8px;\n}\n\n.data {\n  display: flex;\n  align-items: center;\n}\n\n.container {\n  display: grid;\n  grid-template-columns: repeat(5, 1fr);\n  gap: 10px;\n}\n\n.box {\n  border: 3px solid #666;\n  background-color: #ddd;\n  border-radius: .5em;\n  padding: 10px;\n  cursor: move;\n} */\n\n.datepicker-icon {\n  font-size: 125%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.activities div {\n  margin-bottom: 8px;\n}", "",{"version":3,"sources":["webpack://./src/pages/Create/Create.css"],"names":[],"mappings":"AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,+BAA+B;AACjC;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,iCAAiC;AACnC;;AAEA;;;;;;;;;;;;;;;;;;;;;GAqBG;;AAEH;EACE,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Anuphan:wght@300;400;500&display=swap');\n\n.issue-modal .modal-content {\n  overflow: auto;\n  overflow-x: hidden;\n  max-height: calc(100vh - 200px);\n}\n\n.editor {\n  margin-bottom: 12px;\n}\n\n.editor div {\n  font-family: 'Anuphan' !important;\n}\n\n/* .arrow-container {\n  margin-left: 8px;\n}\n\n.data {\n  display: flex;\n  align-items: center;\n}\n\n.container {\n  display: grid;\n  grid-template-columns: repeat(5, 1fr);\n  gap: 10px;\n}\n\n.box {\n  border: 3px solid #666;\n  background-color: #ddd;\n  border-radius: .5em;\n  padding: 10px;\n  cursor: move;\n} */\n\n.datepicker-icon {\n  font-size: 125%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.activities div {\n  margin-bottom: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
