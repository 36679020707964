import { useEffect } from 'react';
import Profile from "../../components/Profile/Profile";

const ProfilePage = () => {
    useEffect(() => {
        document.title = 'Profile';
    })
    return (
        <div>
            <Profile />
        </div>
    )
}

export default ProfilePage;