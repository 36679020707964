import axiosInstance from "../config/axios";

class ServiceNotifications {
  async sendNotification(data) {
    return await axiosInstance.post('/notifications', data);
  }

  async fetchNotifications(currentUser) {
    console.log('currentUser', currentUser);
    return await axiosInstance.get(`/notifications/${currentUser}`);
  }

  async updateNotification(notId, data) {
    return await axiosInstance.put(`/update-notification/${notId}`, data);
  }

  async markAllAsRead() {
    return await axiosInstance.put(`/mark-all`);
  }

  async deleteRead() {
    return await axiosInstance.delete('/');
  }

  async addNews(newsData) {
    return await axiosInstance.post('/news', newsData);
  }

  async getNews() {
    return await axiosInstance.get('/news');
  }

  async updateNews(newsId, updatedData) {
    return await axiosInstance.put(`/news/${newsId}`, updatedData);
  }

  async deleteNews(newsId) {
    return await axiosInstance.delete(`/news/${newsId}`);
  }
}

export default new ServiceNotifications();