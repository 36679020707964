import React, { useEffect, useState } from "react";
import { Button, Modal, DatePicker, Select, Input, Form, Col, Row, Upload, ConfigProvider, Typography, Card, Tooltip, Steps, Radio, Space, Switch, message, InputNumber, Tag, Checkbox, Table } from "antd";
import Editor from "../../components/Editor/Editor";
import "react-quill/dist/quill.snow.css";
import { BugTwoTone, BulbTwoTone, DeleteOutlined, DoubleLeftOutlined, DoubleRightOutlined, DownSquareTwoTone, EditOutlined, FireTwoTone, LeftOutlined, MinusSquareTwoTone, PlusOutlined, ProjectTwoTone, RightOutlined, ToolTwoTone, UpSquareTwoTone } from '@ant-design/icons';
import './Create.css';
import moment from 'moment';
import ServiceUsers from '../../services/users';
import ServiceTickets from '../../services/tickets';
import ServiceFiles from '../../services/files';
import ServiceBuilds from '../../services/builds';
import ServiceActivities from '../../services/activities';
import { useUser } from '../../UserContext';
import Dot from "../../assets/rec.png";
// import Edit from "../../assets/pencil.png";
// import Delete from "../../assets/delete.png";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ActivityAssignmentAddModal from '../../components/ActivityAssignmentAddModal/ActivityAssignmentAddModal'
import { DateTime } from 'luxon';

const { v4: uuidv4, validate } = require('uuid');

const { Option } = Select;

const { Text } = Typography;

const { RangePicker } = DatePicker;

const taskOptions = [
  { value: "Bugfix", label: "Bugfix", icon: <BugTwoTone twoToneColor="#a0db8e" /> },
  { value: "Investigate", label: "Investigate", icon: <ToolTwoTone twoToneColor="#545756" /> },
  { value: "Audit", label: "Audit", icon: <ProjectTwoTone twoToneColor="#4e599c" /> },
  { value: "Feature", label: "Feature", icon: <FireTwoTone twoToneColor="#fa5b3d" /> },
];

const priorityOptions = [
  { value: "Low", label: "Low", icon: <DownSquareTwoTone twoToneColor="#4f42b5" /> },
  { value: "Medium", label: "Medium", icon: <MinusSquareTwoTone twoToneColor="#e4d00a" /> },
  { value: "High", label: "High", icon: <UpSquareTwoTone twoToneColor="#e9314c" /> },
];

const grid = 8;

const getListStyle = isDraggingOver => ({
  background: isDraggingOver && "#D7E9FA",
  borderRadius: "6px",
  padding: grid,
  width: 500
});

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  margin: `0 0 ${grid}px 0`,

  background: isDragging && "#F8F8F8",

  // styles we need to apply on draggables
  ...draggableStyle
});

function Create(props) {
  const [form] = Form.useForm();
  const { user } = useUser();
  const [show, setShow] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [selectedDate, setSelectedDate] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [users, setUsers] = useState([]);
  const [credentials, setCredentials] = useState('');
  const [summary, setSummary] = useState('');
  const [documents, setDocuments] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [current, setCurrent] = useState(0);
  const [selectedOption, setSelectedOption] = useState(1);
  const [isAccountNew, setIsAccountNew] = useState(false);
  const [accountData, setAccountData] = useState({});
  const [selectedAccountValue, setSelectedAccountValue] = useState(undefined); //for select in the account page
  const [selectedBuildValue, setSelectedBuildValue] = useState(undefined); //for select in the build page (task)
  const [countries, setCountries] = useState([]);
  const [activityAssignments, setActivityAssignments] = useState([]);
  const [selectedAssignment, setSelectedAssignment] = useState([]);
  const [open, setOpen] = useState(false);
  const [activities, setActivities] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [isNotAllocatedBudgetCorrect, setIsNotAllocatedBudgetCorrect] = useState(true);
  const [buildStartDate, setBuildStartDate] = useState();
  const [buildDueDate, setBuildDueDate] = useState();
  const [cards, setCards] = useState();
  const [isBuildNB, setIsBuildNB] = useState(false);
  const [billingType, setBillingType] = useState();
  const [commentModalVisible, setCommentModalVisible] = useState(false);
  const [currentFile, setCurrentFile] = useState(null);
  const [comment, setComment] = useState("");

  const billingBuildOptions = [
    { value: "Project-Based", label: "Project-Based" },
    { value: "Time & Material", label: "Time & Material" },
    { value: "Non-Billable", label: "Non-Billable" },
    { value: "Vacation & Sickness", label: "Vacation & Sickness" },
    { value: "Training", label: "Training" },
    { value: "Ad Hoc", label: "Ad Hoc" }
  ];

  const billingTaskOptionsTimeMaterial = selectedBuildValue && selectedBuildValue.billing_type === "Time & Material"
    ? [
      ...(isBuildNB
        ? []  // If isBuildNB is true, exclude the first option
        : [
          { value: "Time & Material", label: "Time & Material" },
          { value: "Project-Based", label: "Project-Based" }
        ]
      ),
      { value: "Non-Billable", label: "Non-Billable" },
    ]
    : [];

  const billingTaskOptionsProjectBased = selectedBuildValue && selectedBuildValue.billing_type === "Project-Based"
    ? [
      ...(isBuildNB
        ? []  // If isBuildNB is true, exclude the first option
        : [
          { value: "Project-Based", label: "Project-Based" }
        ]
      ),
      { value: "Non-Billable", label: "Non-Billable" },
    ]
    : [];


  const billingTaskOptionsVacationSickness = selectedBuildValue && selectedBuildValue.billing_type === "Vacation & Sickness"
    ? [{ value: "Vacation & Sickness", label: "Vacation & Sickness" }]
    : [];

  const billingTaskOptionsTraining = selectedBuildValue && selectedBuildValue.billing_type === "Training"
    ? [{ value: "Training", label: "Training" }]
    : [];

  const billingTaskOptionsNonBillable = selectedBuildValue && selectedBuildValue.billing_type === "Non-Billable"
    ? [{ value: "Non-Billable", label: "Non-Billable" }]
    : [];

  const billingTaskOptionsAdHoc = selectedBuildValue && selectedBuildValue.billing_type === "Ad Hoc"
    ? [
      ...(isBuildNB
        ? []  // If isBuildNB is true, exclude the first option
        : [
          { value: "Ad Hoc", label: "Ad Hoc" },
          { value: "Project-Based", label: "Project-Based" },]
      ),
      { value: "Non-Billable", label: "Non-Billable" },
    ]
    : [];

  const billingTaskOptions = [
    ...billingTaskOptionsTimeMaterial,
    ...billingTaskOptionsProjectBased,
    ...billingTaskOptionsVacationSickness,
    ...billingTaskOptionsTraining,
    ...billingTaskOptionsNonBillable,
    ...billingTaskOptionsAdHoc
  ];

  useEffect(() => {

    const fetchAccounts = async () => {
      try {
        const response = await ServiceBuilds.getAccounts();
        setAccounts(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchUsers = async () => {
      try {
        const response = await ServiceUsers.getAllUsers();
        setUsers(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchActivities = async () => {
      try {
        if (!activityAssignments.length) {
          const response = await ServiceActivities.getActivities();
          setActivities(response.data);
          response.data.forEach((activity, index) => {
            // Create an assignment object for each activity
            const assignment = {
              activity_id: activity.id,
              estimate: 0,
              summary: activity.default_summary,
              role_name: activity.role_name,
              role: activity.default_role,
              remaining_estimate: 0,
              not_allocated_budget: 0,
              name: activity.name,
              order: index,
              status: activity.status,
              id: uuidv4(),
              assignee: null,
              display_name: null
            };

            activityAssignments.push(assignment);

          });

          setActivityAssignments(activityAssignments);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const fetchCards = async () => {
      try {
        const response = await ServiceBuilds.getCards();
        setCards(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchAll = async () => {
      await fetchAccounts();
      await fetchUsers();
      await fetchActivities();
      await fetchCards();
    }

    fetchAll();

    user.role_id !== 3 && setSelectedOption(2);
    fetch('https://restcountries.com/v2/all')
      .then((response) => response.json())
      .then((data) => {
        setCountries(data);
      })
      .catch((error) => console.error('Error fetching data:', error));

  }, []);

  const statusOptions = selectedOption === 2
    ? [
      // { value: "Draft", label: "Draft" },
      { value: "To Do", label: "To Do" },
      // { value: "In Progress", label: "In Progress" },
      // { value: "In QA", label: "In QA" },
      // { value: "In Deployment", label: "In Deployment" },
      // { value: "In Review", label: "In Review" },
      // { value: "On Hold", label: "On Hold" },
      // { value: "Canceled", label: "Canceled" },
      // { value: "Done", label: "Done" }
    ]
    : [
      // { value: "Draft", label: "Draft" },
      { value: "Ready", label: "Ready" },
      { value: "Pending", label: "Pending" },
      // { value: "In Progress", label: "In Progress" },
      // { value: "Closed", label: "Closed" }
    ];

  const handleCredentialsChange = (value) => {
    setCredentials(value);
  };

  const handleSummaryChange = (value) => {
    setSummary(value);
  };

  const handleDocumentsChange = (value) => {
    setDocuments(value);
  };

  const handleClose = () => {
    setShow(false);
    setIsAccountNew(false);
    setCurrent(0);
    setAccountData([]);
    setSelectedAccountValue(undefined);
    setSelectedBuildValue(undefined);
    setFileList([]);
    setCredentials('');
    setSummary('');
    setDocuments('');
    setIsBuildNB(false);
    setActivityAssignments(prevActivityAssignments => {
      const newActivityAssignments = activities.map((activity, index) => {
        return {
          activity_id: activity.id,
          estimate: 0,
          summary: activity.default_summary,
          role_name: activity.role_name,
          role: activity.default_role,
          remaining_estimate: 0,
          not_allocated_budget: 0,
          name: activity.name,
          order: index,
          status: activity.status,
          id: uuidv4()
        };
      });

      return newActivityAssignments;
    });
    setIsNotAllocatedBudgetCorrect(true);
    setBuildStartDate();
    setBuildDueDate();
    form.resetFields();
  }

  const handleShow = () => setShow(true);

  function handleSearch(value) {
  }

  function handleSelect(value) {
  }

  const handleBeforeUpload = (file) => {
    setCurrentFile(file);
    setComment("");
    setCommentModalVisible(true);
    return false; // Prevent automatic upload
  };

  const handleAddFileWithComment = () => {
    if (currentFile) {
      setFileList((prevList) => {
        const fileExists = prevList.some((file) => file.uid === currentFile.uid);
  
        if (fileExists) {
          return prevList.map((file) =>
            file.uid === currentFile.uid ? { ...file, comment } : file
          );
        } else {
          return [
            ...prevList,
            {
              uid: currentFile.uid,
              name: currentFile.name || "Unnamed File", 
              comment,
              file: currentFile,
            },
          ];
        }
      });
    }
  
    setCommentModalVisible(false); 
  };

  const handleRemoveFile = (file) => {
    Modal.confirm({
      title: 'Confirm deletion',
      content: 'Are you sure you want to delete this file?',
      onOk() {
        const newFileList = fileList.filter((f) => f !== file);
        setFileList(newFileList);
      },
      centered: true
    });
  };

  const handleEditComment = (file) => {
    setCurrentFile(file);
    setComment(file.comment || "");
    setCommentModalVisible(true);
  };

  const columns = [
    {
      title: "File Name",
      dataIndex: "name",
      key: "name",
      render: (text) => (
        <div>
          {text && text.length > 30 ? `${text.slice(0, 30)}...` : text}
        </div>
      ),
      width: 200
    },    
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
      render: (text, record) => (
        <div>
          <p>{text || "No comment"}</p>
          <a onClick={() => handleEditComment(record)}>Edit Comment</a>
        </div>
      ),
      width: 300
    },
  ];

  const handleRemoveActivity = (assignment) => {
    Modal.confirm({
      title: 'Confirm deletion',
      content: 'Are you sure you want to delete this activity?',
      onOk() {
        const newActivityList = activityAssignments.filter((item) => item.id !== assignment.id);
        newActivityList.forEach((item, index) => {
          item.order = index;
        });

        setActivityAssignments(newActivityList);
      },
      centered: true
    });
  };

  const handleChangeActivity = (assignment) => {
    setSelectedAssignment(assignment);
    setOpen(true);
  }

  const handleMuteActivity = (assignment) => {
    const newActivityList = activityAssignments.map((item) => {
      if (item.id === assignment.id) {
        const { assignee, ...rest } = item;
        return { ...rest, is_mute: true, estimate: 0, remaining_estimate: 0, display_name: null };
      }
      return item;
    });

    setActivityAssignments(newActivityList);
  }

  const handleUnmuteActivity = (assignment) => {
    const newActivityList = activityAssignments.map((item) => {
      if (item.id === assignment.id) {
        return { ...item, is_mute: false, estimate: 0, remaining_estimate: 0 };
      }
      return item;
    });

    setActivityAssignments(newActivityList);
  }

  const handleAddActivity = () => {
    setOpenAddModal(true);
  }

  const addActivityAssignment = (values) => {
    setActivityAssignments([...activityAssignments, values]);
  }

  const onClose = () => {
    setOpen(false);
  }

  const onCloseAddModal = () => {
    setOpenAddModal(false);
  }

  const onAssignmentSubmit = (activityAssignment) => {
    const updatedActivityAssignments = [...activityAssignments];
    const assignmentToUpdate = {
      ...updatedActivityAssignments[activityAssignment.order],
      order: activityAssignment.order,
      estimate: activityAssignment.estimate,
      remaining_estimate: activityAssignment.estimate,
      // not_allocated_budget: activityAssignment.estimate,
      summary: activityAssignment.summary,
      assignee: activityAssignment.assignee,
      display_name: users.find((user) => user.id === activityAssignment.assignee)?.display_name || null
    };

    if (selectedOption === 1) {
      assignmentToUpdate.not_allocated_budget = activityAssignment.estimate;
    }

    updatedActivityAssignments[activityAssignment.order] = assignmentToUpdate;
    setActivityAssignments(updatedActivityAssignments);
  }

  const labelRenderer = ({ label, icon }) => (
    <div>
      <span style={{ marginRight: 8 }}>{icon}</span>
      <span>{label}</span>
    </div>
  );

  const onFinish = async (values) => {
    if (selectedOption === 2) {
      if (!activityAssignments.every(assignment => assignment.hasOwnProperty('assignee') || assignment.is_mute === true)) {
        message.error('It is necessary to fill in active assignees');
        return;
      }
    }
    if (selectedOption === 1) {
      if (!activityAssignments.every(assignment => assignment.estimate > 0)) {
        message.error('It is necessary to fill in all estimates');
        return;
      }
    }
    if (activityAssignments.length === 0) {
      message.error('It is necessary to add at least one activity');
      return;
    }
    setSubmitting(true);
    const account_id = selectedOption === 1 && isAccountNew ?
      await ServiceBuilds.createAccount(accountData) :
      selectedAccountValue;
    const updatedValues =
    {
      ...values,
      accountId: selectedOption === 1 && isAccountNew ? account_id.data : account_id,
      buildId: selectedOption === 2 ? (selectedBuildValue && selectedBuildValue.id) : null,
      credentialsDesc: credentials,
      summaryDesc: summary,
      documentsDesc: documents,
      activityAssignments: activityAssignments
    };
    try {
      const response = selectedOption === 1 ?
        await ServiceBuilds.createBuild(updatedValues) :
        await ServiceTickets.createTask(updatedValues)
      selectedOption === 1 && props.onChange(response.data);
      selectedOption === 2 && props.onChangeTask(response.data, selectedBuildValue);
      console.log("Files to upload:", fileList);
      for (const file of fileList) {
        const formData = new FormData();
        formData.append('file', file.file); 
        formData.append('user_id', user.id);
        formData.append('comment', file.comment)
        selectedOption === 1 ? formData.append('build_id', response.data.id) : formData.append('task_key', response.data.key)
        try {
          console.log('formData', formData);
          await ServiceFiles.addFile(formData);
          console.log('File uploaded successfully:', file.name);
        } catch (error) {
          console.error('Failed to upload file:', file.name, error);
        }
      }
      handleClose();
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    message.error("Check the fields");
  };

  const assignToMeHandler = () => {
    form.setFieldsValue({
      administrator: user.id
    });
  };

  function handleChange(event) {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    form.resetFields();
    setIsAccountNew(false);
    setAccountData([]);
    setSelectedAccountValue(undefined);
    setSelectedBuildValue(undefined);
    setFileList([]);
    setCredentials('');
    setSummary('');
    setDocuments('');
    setActivityAssignments([]);
    setIsBuildNB(false);
    setActivityAssignments(prevActivityAssignments => {
      const newActivityAssignments = activities.map((activity, index) => {
        return {
          activity_id: activity.id,
          estimate: 0,
          summary: activity.default_summary,
          role_name: activity.role_name,
          role: activity.default_role,
          remaining_estimate: 0,
          not_allocated_budget: 0,
          name: activity.name,
          order: index,
          status: activity.status,
          id: uuidv4()
        };
      });

      return newActivityAssignments;
    });
    setIsNotAllocatedBudgetCorrect(true);
    setBuildStartDate();
    setBuildDueDate();
  }

  const filterOption = (input, option) => {
    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }

  //Switch
  const createAccountHandler = (value) => {
    setIsAccountNew(value);
    value ? setSelectedAccountValue(undefined) : setAccountData([]);
  }

  const handleInputAccountChange = (e) => {
    const { name, value } = e.target;
    setAccountData({
      ...accountData,
      [name]: value,
    });
  };

  const handleCountryAccountChange = (country) => {
    setAccountData({
      ...accountData,
      country: country,
    });
  };

  const handleCardAccountChange = (card) => {
    setAccountData({
      ...accountData,
      rate_card: card,
    });
  };

  const handleSelectAccountChange = (value) => {
    setSelectedAccountValue(value);
  };

  const handleSelectBuildChange = (value) => {
    form.resetFields(["billing"]);
    const selectedBuild = props.builds.find((build) => build.id === value);
    const notAllocatedBudget = selectedBuild.not_allocated_budget;

    setBuildStartDate(selectedBuild.start_date);
    setBuildDueDate(selectedBuild.due_date);
    setIsNotAllocatedBudgetCorrect(!(parseInt(notAllocatedBudget) <= 0));

    ServiceBuilds.getActivityAssignments(value)
      .then((response) => {

        const updatedAssignments = response.data.map((assignment) => ({
          ...assignment,
          remaining_estimate: assignment.not_allocated_budget,
          estimate: assignment.not_allocated_budget,
        }));

        setActivityAssignments(updatedAssignments);
      });

    form.setFieldValue("administrator", selectedBuild.administrator);
    setSelectedBuildValue(selectedBuild);
  }


  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(activityAssignments);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    items.forEach((assignment, index) => {
      assignment.order = index;
    });

    setActivityAssignments(items);
  };

  const onCheckboxChange = (e) => {
    setIsBuildNB(e.target.checked);
  };

  const handleBilling = (value) => {
    setBillingType(value);
  }

  const steps = [
    {
      title: 'Subject',
      content:
        <div>
          <div style={{ display: 'flex', margin: '8px 0 8px 0' }}>
            <div>
              <div style={{ marginBottom: '8px' }}>What do you want to create?</div>
              <div>
                <Radio.Group onChange={handleChange} value={selectedOption}>
                  <Space direction="vertical">
                    {user.role_id === 3 && <Radio value={1}>Build</Radio>}
                    <Radio value={2}>Task</Radio>
                  </Space>
                </Radio.Group>
              </div>
            </div>
          </div>
        </div>
    },
    selectedOption === 1 ? (
      {
        title: 'Account',
        content: (
          <div style={{ margin: '8px 0 8px 0' }}>
            <div>Here you can select an existing account or create a new one.</div>
            <div>New? <Switch size="small" onChange={(value) => createAccountHandler(value)} checked={isAccountNew} /></div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '8px' }}>
              <div>
                <Select
                  showSearch
                  value={selectedAccountValue}
                  onChange={handleSelectAccountChange}
                  style={{ width: 175 }}
                  placeholder="Select an account"
                  optionFilterProp="children"
                  filterOption={filterOption}
                  disabled={isAccountNew}
                >
                  {accounts.map((account) => (
                    <Select.Option key={account.id} value={account.id}>
                      {account.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>

              <div style={{ margin: '8px' }}>or</div>
              <Card
                size="small"
                title="Account Creation"
                style={{
                  width: 300,
                }}
              >
                <div>
                  <label>Country:</label>
                  <Select
                    showSearch
                    name="country"
                    disabled={!isAccountNew}
                    value={accountData.country}
                    onChange={handleCountryAccountChange}
                    style={{ width: 266 }}
                    optionFilterProp="children"
                    filterOption={filterOption}
                    placeholder="Select..."
                  >
                    {countries.map((counrty) => (
                      <Select.Option key={counrty.name} value={counrty.name}>
                        {counrty.name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                <div>
                  <label>Name (required):</label>
                  <Input
                    name="name"
                    onChange={handleInputAccountChange}
                    value={accountData.name}
                    disabled={!isAccountNew}
                    placeholder="Name"
                  />
                </div>
                <div>
                  <label>Decision Maker Name:</label>
                  <Input
                    name="decision_maker_name"
                    onChange={handleInputAccountChange}
                    value={accountData.decision_maker_name}
                    disabled={!isAccountNew}
                    placeholder="Decision Maker Name"
                  />
                </div>
                <div>
                  <label>Decision Maker Role:</label>
                  <Input
                    name="decision_maker_role"
                    onChange={handleInputAccountChange}
                    value={accountData.decision_maker_role}
                    disabled={!isAccountNew}
                    placeholder="Decision Maker Role"
                  />
                </div>
                <div>
                  <label>Decision Maker Email:</label>
                  <Input
                    name="decision_maker_email"
                    onChange={handleInputAccountChange}
                    value={accountData.decision_maker_email}
                    disabled={!isAccountNew}
                    placeholder="Decision Maker Email"
                  />
                </div>
                <div>
                  <label>Rate Card:</label>
                  <Select
                    disabled={!isAccountNew}
                    value={accountData.rate_card}
                    onChange={handleCardAccountChange}
                    style={{ width: 266 }}
                    placeholder="Rate Card"
                  >
                    {cards && cards.map((card) => (
                      <Select.Option key={card.id} value={card.id}>
                        {card.name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                <div>
                  <label>SF ID:</label>
                  <Input
                    name="sf_id"
                    onChange={handleInputAccountChange}
                    value={accountData.sf_id}
                    disabled={!isAccountNew}
                    placeholder="SF ID"
                  />
                </div>
              </Card>
            </div>
          </div>
        ),
      }) : (
      {
        title: 'Build',
        content: (
          <div style={{ marginTop: '8px' }}>
            <div style={{ margin: '8px 0 8px 0' }}>Choose a build.</div>
            <div>
              <Select
                showSearch
                onChange={handleSelectBuildChange}
                style={{ width: 200 }}
                placeholder="Select a build"
                value={selectedBuildValue && selectedBuildValue.id}
                optionFilterProp="children"
                filterOption={filterOption}
              >
                {props.builds.map((build) => (
                  <Select.Option key={build.id} value={build.id}>
                    {build.sow_name}
                  </Select.Option>
                ))}
              </Select>
            </div>
            {selectedBuildValue && selectedBuildValue.billing_type !== 'Non-Billable' &&
              <div style={{ marginTop: '4px' }}>
                <Checkbox onChange={onCheckboxChange}>Is Non-Billable?</Checkbox>
              </div>
            }
          </div>
        ),
      }),
    {
      title: 'Creation',
      content:
        <div style={{ marginTop: '16px' }}>
          <Form
            id="myForm"
            className="modal-content"
            layout="vertical"
            autoComplete="off"
            form={form}
            style={{
              paddingRight: '16px'
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >

            <Row gutter={8}>
              <Col span={8}>
                <Form.Item
                  name="status"
                  label="Status"
                  initialValue={selectedOption === 1 ? 'Ready' : 'To Do'}
                >
                  <Select
                    options={statusOptions}
                    placeholder="Select..."
                  />
                </Form.Item>
              </Col>
              {selectedOption === 2 && (
                <>
                  <Col span={8}>
                    <Form.Item
                      name="taskType"
                      label="Task Type"
                      initialValue="Bugfix"
                      rules={[{ required: true, message: 'Please select a task type' }]}
                    >
                      <Select
                        options={taskOptions.map(({ label, value, icon }) => ({
                          label: labelRenderer({ label, icon }),
                          value,
                        }))}
                        placeholder="Select..."
                        onSelect={handleSelect}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="priority"
                      label="Priority"
                      required
                      rules={[
                        {
                          validator: (_, value) => {
                            if (!value) {
                              return Promise.reject(new Error('Please choose a priority'));
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <Select
                        optionFilterProp="children"
                        options={priorityOptions.map(({ label, value, icon }) => ({
                          label: labelRenderer({ label, icon }),
                          value,
                        }))}
                        placeholder="Select..."
                      />
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>
            <Form.Item
              name={selectedOption === 2 ? "summary" : "name"}
              label={selectedOption === 2 ? "Summary" : "SoW Name"}
              rules={[{ required: true, message: selectedOption === 2 ? "Please enter Summary" : "Please enter SoW Name" }]}
            >
              <Input />
            </Form.Item>
            <Row gutter={8}>
              <Col span={8}>
                <Form.Item
                  name="billing"
                  label="Billing"
                  required
                  rules={[
                    {
                      validator: (_, value) => {
                        if (!value) {
                          return Promise.reject(new Error('Please choose a billing type'));
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Select
                    onChange={handleBilling}
                    options={selectedOption === 1 ? billingBuildOptions : billingTaskOptions}
                    placeholder="Select..."
                  />
                </Form.Item>
              </Col>
            </Row>
            {selectedOption === 1 &&
              <Row gutter={8}>
                <Col span={8}>
                  <Form.Item
                    name="productOwnerName"
                    label="Product Owner Name"
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="productOwnerRole"
                    label="Product Owner Role"
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="productOwnerEmail"
                    label="Product Owner Email"
                    rules={[
                      {
                        type: 'email',
                        message: 'Please enter the correct email',
                      },
                      {
                        required: true,
                        message: 'Please enter an email',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            }

            <div className="editor">
              <Editor
                user={user}
                fileList={fileList}
                onCredentialsChange={handleCredentialsChange}
                onSummaryChange={handleSummaryChange}
                onDocumentsChange={handleDocumentsChange}
                credentials={credentials}
                summary={summary}
                documents={documents}
              />
            </div>

            {/* <FileUploader /> */}

            <Form.Item valuePropName="fileList">
              <Upload
                listType="picture-card"
                beforeUpload={handleBeforeUpload}
                onRemove={handleRemoveFile}
                multiple={true}
                fileList={fileList}
              >
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              </Upload>
            </Form.Item>
            <div style={{ marginBottom: '8px' }}>
              <Table
                columns={columns}
                dataSource={fileList}
                pagination={false}
              />

              <Modal
                title="Add/Edit Comment"
                open={commentModalVisible}
                onOk={handleAddFileWithComment}
                onCancel={() => setCommentModalVisible(false)}
              >
                <Input.TextArea
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  placeholder="Add a comment..."
                />
              </Modal>
            </div>
            <div style={{ display: 'flex', marginBottom: '8px' }}>
              <Form.Item
                name="administrator"
                label="Administrator"
                required
                style={{ marginBottom: '4px' }}
                rules={[
                  {
                    validator: (_, value) => {
                      if (!value) {
                        return Promise.reject(new Error('Please choose an administrator'));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Select
                  showSearch
                  onSearch={handleSearch}
                  onSelect={handleSelect}
                  style={{ width: 255 }}
                  placeholder="Search Users"
                  disabled={selectedOption === 2 ? true : false}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {users.filter(user => user.role_id !== 1).map(user => (
                    <Option value={user.id}>
                      {user.display_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              {selectedOption === 1 && <Button style={{ margin: '30px 0 0 8px' }} onClick={assignToMeHandler}>Assign to me</Button>}
            </div>
            <div>
              <div style={{ display: 'flex', marginBottom: '8px' }}>
                <div>
                  <div>Timeline</div>
                  {selectedOption === 2 && <div style={{ fontSize: '12px' }}>Hint: by default, the maximum available estimate is set</div>}
                </div>
                {selectedOption === 1 &&
                  <div style={{ marginLeft: '8px' }}>
                    <Button size="small" onClick={() => handleAddActivity()}>
                      Add new activity
                    </Button>
                  </div>
                }
              </div>
              <div>
                <DragDropContext onDragEnd={onDragEnd}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ marginRight: '8px', marginTop: '8px' }}>
                      {Array(activityAssignments.length).fill().map((_, index) => (
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <img style={{ width: '12px' }} src={Dot} alt={index} />
                          {index !== activityAssignments.length - 1 && (
                            <div
                              style={{
                                width: '1px',
                                height: '96px',
                                backgroundColor: '#DCDCDC',
                              }}
                            >
                            </div>
                          )}
                        </div>

                      ))}
                    </div>


                    <Droppable droppableId="activityAssignments" className="activityAssignments" style={{ flex: 1 }}>
                      {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.droppableProps} style={getListStyle(snapshot.isDraggingOver)}>
                          {activityAssignments.map((assignment, index) => (
                            <Draggable
                              isDragDisabled={selectedOption === 2}
                              key={assignment.id}
                              draggableId={String(assignment.id)}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <Card
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style,
                                  )}
                                >
                                  <div style={{
                                    display: 'flex', justifyContent: 'space-between',
                                  }}>
                                    <div style={{ fontSize: '14px', fontWeight: '600' }}>{assignment.name}</div>
                                    <div style={{ display: 'flex' }}>
                                      <div style={{ display: 'flex', gap: '6px' }}>
                                        {!assignment.is_mute &&
                                          <Button
                                            size="small"
                                            onClick={() => handleChangeActivity(assignment)}
                                            icon={<EditOutlined />}
                                          >
                                          </Button>
                                        }
                                        {selectedOption === 2 &&
                                          <div>
                                            {!assignment.is_mute ?
                                              <Button
                                                size="small"
                                                onClick={() => handleMuteActivity(assignment)}
                                              >
                                                Mute
                                              </Button>
                                              :
                                              <Button
                                                size="small"
                                                onClick={() => handleUnmuteActivity(assignment)}
                                              >
                                                Unmute
                                              </Button>
                                            }
                                          </div>
                                        }
                                      </div>
                                      {selectedOption === 1 &&

                                        <div style={{ marginLeft: '4px' }}>
                                          {/* <img
                                          style={{ width: '14px', cursor: 'pointer' }}
                                          src={Delete}
                                          alt='Delete'
                                          onClick={() => handleRemoveActivity(assignment)}
                                        /> */}
                                          <Button
                                            size="small"
                                            onClick={() => handleRemoveActivity(assignment)}
                                            icon={<DeleteOutlined />}
                                          >
                                          </Button>
                                        </div>
                                      }
                                    </div>
                                  </div>
                                  <div style={{ display: 'flex' }}>
                                    <div style={{ fontWeight: '500', color: '#437EB9', marginRight: '8px', width: '80px' }}>{assignment.role_name}</div>
                                    <div style={{ width: '60px' }}>
                                      {selectedOption === 1 ?
                                        <div>
                                          {assignment.estimate}h
                                        </div>
                                        :
                                        <div >
                                          <span style={{ color: assignment.estimate > 0 ? 'green' : 'red' }}>
                                            {assignment.estimate}h
                                          </span>
                                        </div>
                                      }
                                    </div>
                                    <div style={{ marginLeft: '8px', fontWeight: '500' }}>{assignment.display_name}</div>
                                  </div>
                                  <Tooltip title={assignment.summary}>
                                    <div
                                      style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        maxWidth: '100%',
                                      }}
                                    >
                                      {assignment.summary}
                                    </div>
                                  </Tooltip>
                                </Card>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                </DragDropContext>

              </div>
            </div>

            <div style={{
              display: 'flex',
              alignItems: 'center'
            }}>
              <Row gutter={8}>
                <Col>
                  <Form.Item
                    label="Start Date and Due Date"
                    name="dates"
                    rules={[
                      { required: true, message: 'Please pick dates' },
                      {
                        validator: (rule, value) => {
                          if (value && value.length === 2 && selectedOption === 2) {
                            const startDate = new Date(value[0]);
                            const endDate = new Date(value[1]);

                            startDate.setHours(0, 0, 0, 0);
                            endDate.setHours(0, 0, 0, 0);

                            const minDate = new Date(buildStartDate);
                            const maxDate = new Date(buildDueDate);

                            minDate.setHours(0, 0, 0, 0);
                            maxDate.setHours(0, 0, 0, 0);

                            if (startDate < minDate || endDate > maxDate) {
                              return Promise.reject(`Select dates in the specified range: from ${DateTime.fromISO(buildStartDate).toFormat('yyyy-MM-dd')} to ${DateTime.fromISO(buildDueDate).toFormat('yyyy-MM-dd')}`);
                            }
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <RangePicker />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Form >
        </div >,
    },
  ];

  const next = () => {
    setCurrent(current + 1);
    if (current === 1 && selectedOption === 1) {
      if (isAccountNew) {
        handleAccountSubmit();
      } else if (!selectedAccountValue && !isAccountNew) {
        setCurrent(1);
        message.error('Choose the account');
        return;
      }
    }

    if (current === 1 && selectedOption === 2 && !selectedBuildValue) {
      setCurrent(1);
      message.error('Choose the build');
      return;
    }

    if (current === 1 && selectedOption === 2 && !isNotAllocatedBudgetCorrect) {
      if (!isBuildNB || (selectedBuildValue.billing_type === 'Vacation & Sickness' || selectedBuildValue.billing_type === 'Training')) {
        setCurrent(1);
        message.error('This build cannot be selected. Its entire budget has been used up');
        return;
      }
    }

  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const handleAccountSubmit = (e) => {

    if (!accountData.name) {
      setCurrent(1);
      message.error('Name is required.')
      return;
    }

    if (accountData.decision_maker_email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(accountData.decision_maker_email)) {
      setCurrent(1);
      message.warning('Email is incorrect.');
      return;
    }

    if (accountData.sf_id && accountData.sf_id.length !== 18) {
      setCurrent(1);
      message.warning('SF ID should consists of exactly 18 symbols.')
      return;
    }

  };



  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: 'Inter'
          },
        }}
      >

        <div style={{ marginLeft: '18px' }}>
          <Button onClick={handleShow}>
            {user.role_id === 3 ? <span>Create Build/Task</span> : <span>Create Task</span>}
          </Button>
        </div>
        <Modal
          title={
            <div>Create {selectedOption === 1 ? 'Build' : 'Task'}</div>
          }
          open={show}
          width={800}
          onCancel={handleClose}
          {...props}
          className="issue-modal"
          centered
          onOk={() => {
          }}
          footer={[
            <div
              style={{
                marginTop: 24,
              }}
            >
              {current > 0 && (
                <Button
                  onClick={() => prev()}
                  style={{
                    marginRight: 8
                  }}
                >
                  Previous
                </Button>
              )}
              {current < steps.length - 1 && (
                <Button type="primary" onClick={() => next()}>
                  Next
                </Button>
              )}
              {current === steps.length - 1 && (
                <Button type="primary" onClick={() => form.submit()} loading={submitting}>
                  Done
                </Button>
              )}

            </div>
          ]}
        >

          <>
            <Steps current={current} items={items} />
            <div>{steps[current].content}</div>
          </>


        </Modal>
        <ActivityAssignmentEditModal
          open={open}
          onClose={onClose}
          assignment={selectedAssignment}
          onAssignmentSubmit={onAssignmentSubmit}
          selectedOption={selectedOption}
          users={users}
          billingType={billingType}
          build={selectedBuildValue}
        />
        <ActivityAssignmentAddModal
          open={openAddModal}
          onClose={onCloseAddModal}
          activities={activities}
          onAdd={addActivityAssignment}
          index={activityAssignments.length}
        />
      </ConfigProvider>
    </>
  );
}

const ActivityAssignmentEditModal = (props) => {
  const [form] = Form.useForm();
  // const formRef = React.useRef(null);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    form.setFieldsValue({
      name: props.assignment.name,
      role: props.assignment.role,
      summary: props.assignment.summary,
      estimate: parseFloat(props.assignment.estimate),
      assignee: props.assignment.assignee
    })
    ServiceUsers.getRoles()
      .then(response => {
        setRoles(response.data);
      })
  }, [form, props])

  const onCancel = () => {
    form.resetFields(['assignee']);
    props.onClose();
  }

  return (
    <Modal
      open={props.open}
      title="Edit Activity Assignment"
      okText="Save"
      width={550}
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            values.order = props.assignment.order;
            values.role_name = roles.find((role) => role.id === values.role)?.name;
            props.onAssignmentSubmit(values);
            props.onClose();
            form.resetFields(['assignee']);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
          <div>
            <div>
              Name
            </div>
            <div style={{ fontWeight: '500' }}>
              {props.assignment.name}
            </div>
          </div>
          <div>
            <div>
              Role
            </div>
            <div style={{ color: '#437EB9', fontWeight: '500' }}>
              {props.assignment.role_name}
            </div>
          </div>
          <div>
            <div>
              <div>
                Automated Task Status
              </div>
              <div>
                <Tag>{props.assignment.status}</Tag>
              </div>
            </div>
          </div>
        </div>

        <Row>
          <Col span={24}>
            <Form.Item name="summary" label="Summary">
              <Input.TextArea
                rows={3}
              />
            </Form.Item>
          </Col>

        </Row>
        <Row gutter={6}>
          {props.selectedOption === 2 &&
            <Col span={10}>
              <Form.Item
                name="assignee"
                label="Assignee"
                rules={[
                  {
                    required: true,
                    message: 'Please choose an assignee',
                  }]}
              >
                <Select
                  showSearch
                  placeholder="Select an user..."
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {props.users.map(user => (
                    <Option value={user.id}>
                      {user.display_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          }
          <Col span={18}>
            <Form.Item
              name="estimate"
              label="Estimate (h)"
              value={props.assignment.estimate}
              rules={[
                {
                  required: true,
                  message: 'Please input the estimate',
                },
                {
                  type: 'number',
                  min: 0,
                  message: 'Estimate must not be negative',
                },
                {
                  validator: (rule, value) => {
                    if (props.selectedOption === 2) {
                      if (!(props.billingType && props.billingType === "Non-Billable" && !(props.build.billing_type === 'Non-Billable'))) {
                        if (value > parseFloat(props.assignment.not_allocated_budget)) {
                          return Promise.reject(`Estimate must not exceed ${props.assignment.not_allocated_budget}h`);
                        }
                      }
                    }
                    if (value && !/^\d+(\.\d{1,2})?$/.test(value)) {
                      return Promise.reject('Duration must be a number with up to 2 decimal places');
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <InputNumber
                step={0.25}
                parser={(value) => value.replace(',', '.')}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal >
  );
};

export default Create;