import { Button, Form, Select, Upload, message, Table, Card, Spin, Modal, Result } from 'antd';
import React, { useEffect, useState } from 'react';
import Editor from '../../components/Editor/Editor';
import "react-quill/dist/quill.snow.css";
import { DeleteOutlined, EditOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { useParams } from 'react-router';
import ServiceUsers from '../../services/users';
import ServiceComments from '../../services/comments';
import ServiceFiles from '../../services/files';
import ServiceBuilds from '../../services/builds';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from "react-responsive";
import CommentBox from '../../components/CommentBox/CommentBox';
import { Scrollbar } from 'react-scrollbars-custom';
import { useUser } from '../../UserContext';
import Tooltip from 'antd/lib/tooltip';
import { DateTime } from 'luxon';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Dot from "../../assets/rec.png";
import BuildDetails from '../../components/BuildDetails/BuildDetails';
import './BuildPage.css'
import EditBuildModal from '../../components/EditBuildModal/EditBuildModal';
import { v4 as uuidv4 } from 'uuid';

const { Option } = Select;

const statusOptions = [
    { value: "Draft", label: "Draft" },
    { value: "Ready", label: "Ready" },
    { value: "In Progress", label: "In Progress" },
    { value: "Closed", label: "Closed" }
];

const BuildPage = () => {

    const { user } = useUser();
    const { id } = useParams();
    const [form] = Form.useForm();
    const [build, setBuild] = useState({});
    const [activityAssignments, setActivityAssignments] = useState();
    const [credentials, setCredentials] = useState('');
    const [summary, setSummary] = useState('');
    const [documents, setDocuments] = useState('');
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});
    const [fileList, setFileList] = useState([]);
    const [data, setData] = useState([]);
    const [users, setUsers] = useState([]);
    const [isEditorModified, setIsEditorModified] = useState(false);
    const [comments, setComments] = useState([]);
    const navigate = useNavigate();
    const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
    const [showToolbar, setShowToolbar] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isBuildExist, setIsBuildExist] = useState(true);
    const [isUserAssigned, setIsUserAssigned] = useState(true);
    const [isEditBuildModalOpen, setIsEditBuildModalOpen] = useState(false);

    const grid = 8;

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver && "#D7E9FA",
        borderRadius: "6px",
        padding: grid,
        width: 500
    });

    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: "none",
        margin: `0 0 ${grid}px 0`,

        background: isDragging && "#F8F8F8",

        // styles we need to apply on draggables
        ...draggableStyle
    });

    useEffect(() => {
        if (!user) {
            return;
        }

        const fetchData = async () => {
            try {
                const response = await ServiceBuilds.getBuild(id);
                console.log('response', response);
                if (response.status === 200) {
                    document.title = response.data.build.sow_name;
                    setBuild(response.data.build);
                    console.log('build', response.data.build);
                    setActivityAssignments(response.data.activity_assignments);
                    form.setFieldsValue({
                        budget: response.data.build.budget,
                        administrator: response.data.build.administrator,
                        dueDate: DateTime.fromISO(response.data.build.due_date, { zone: Intl.DateTimeFormat().resolvedOptions().timeZone }).toFormat('yyyy-MM-dd'),
                        startDate: DateTime.fromISO(response.data.build.start_date, { zone: Intl.DateTimeFormat().resolvedOptions().timeZone }).toFormat('yyyy-MM-dd')
                    });
                }
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 404:
                            setIsBuildExist(false);
                            break;
                        case 403:
                            setIsUserAssigned(false);
                            break;
                        default:
                            console.error(error);
                    }
                    setIsLoading(false);
                } else {
                    console.error(error);
                }
            }

        };

        const fetchUsers = async () => {
            try {
                const response = await ServiceUsers.getAllUsers();
                setUsers(response.data);
            } catch (error) {
                console.error(error);
            }
        };

        const fetchComments = async () => {
            try {
                const comments = await ServiceComments.getAllComments(id, true);
                setComments(comments.data);
            } catch (error) {
                console.error(error);
            }
        }

        const fetchFiles = async () => {
            try {
                const files = await ServiceFiles.getBuildFiles(id);
                const formattedFiles = files.data.map(file => ({
                    key: file.id,
                    name: file.file_name,
                    size: countFileSize(file),
                    dateAdded: new Date(file.created_at).toLocaleString(),
                }));
                setData(formattedFiles);
                console.log('files', files);
            } catch (error) {
                console.error(error);
            }
        }

        const fetchAll = async () => {
            await fetchData();
            await fetchUsers();
            await fetchComments();
            await fetchFiles();
            setIsLoading(false);
        }

        fetchAll();

    }, [form, user, id, navigate]);

    const countFileSize = (file) => {
        let fileSize = file.size;
        let sizeString = fileSize + 'B';

        if (fileSize >= 1024) {
            fileSize = (fileSize / (1024 * 1024)).toFixed(2);
            sizeString = fileSize + 'Mb';
        }
        return sizeString;
    }

    const handleCredentialsChange = (value) => {
        setCredentials(value);
    };

    const handleSummaryChange = (value) => {
        setSummary(value);
    };

    const handleDocumentsChange = (value) => {
        setDocuments(value);
    };

    const handleChange = (pagination, filters, sorter) => {
        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };

    const actionButtons = (text, record) => (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <a href={`${process.env.REACT_APP_BACKEND_URL}/api/files/${record.name}`} download>
                    Download
                </a>
                {!(user.role_id === 1) &&
                    <Button type="text" size='small' icon={<DeleteOutlined />} onClick={() => handleRemoveFile(record)}></Button>
                }
            </div>
        </>
    );

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            filteredValue: filteredInfo.name || null,
            onFilter: (value, record) => record.name.toString() === value,
            sorter: (a, b) => a.name.localeCompare(b.name),
            sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
            ellipsis: true,
            width: isMobile ? 60 : 80
        },
        {
            title: "Size",
            dataIndex: "size",
            key: "size",
            width: 40
        },
        {
            title: "Date Added",
            dataIndex: "dateAdded",
            key: "dateAdded",
            width: 70
        },
        {
            dataIndex: '',
            key: 'x',
            render: actionButtons,
            width: 40
        },
    ];

    const handleBeforeUpload = (file) => {
        const formData = new FormData();
        let fileSize = file.size;
        let sizeString = fileSize + 'B';

        if (fileSize >= 1024) {
            fileSize = (fileSize / (1024 * 1024)).toFixed(2);
            sizeString = fileSize + 'Mb';
        }
        formData.append('file', file);
        formData.append('user_id', user.id);
        formData.append('build_id', build.id);
        ServiceFiles.addFile(formData)
            .then((response) => {
                const newFile = {
                    key: uuidv4(),
                    name: response.data[0].file_name,
                    size: sizeString,
                    dateAdded: new Date().toLocaleString(),
                    uid: file.uid
                };
                setData((prevData) => [...prevData, newFile]);
                setFileList((prevList) => [...prevList, file]);
                message.success('File uploaded successfully!');
            })
            .catch((error) => {
                message.error('Failed to upload file.');
            });
        return false;
    };

    const handleRemoveFile = (file) => {
        ServiceFiles.deleteFile(file.name)
            .then((response) => {
                message.success('File deleted successfully!');
            })
        const newData = data.filter((d) => d.key !== file.key);
        setData(newData);
    };

    const uploadProps = {
        multiple: true,
        listType: 'picture',
        beforeUpload: handleBeforeUpload,
        onRemove: handleRemoveFile
    };

    const deleteBuildHandler = async () => {
        Modal.confirm({
            title: 'Confirm deletion',
            content: 'By clicking OK, you confirm the deletion of the build and everything related to it.',
            onOk() {
                ServiceBuilds.deleteBuild(id)
                    .then((response) => {
                        if (response.data) {
                            message.success(response.data.message);
                            navigate('/');
                        }
                    })
            },
            centered: true
        });
    }

    const editorHandler = () => {
        setIsEditorModified(false);
        setShowToolbar('');
        console.log('credentials', credentials);
        const updatedBuild = {
            ...build,
            credentials_description: credentials,
            summary_description: summary,
            documents_description: documents
        }
        ServiceBuilds.updateBuild(updatedBuild)
            .then((response) => {
                console.log('res', response);
                if (response.data) {
                    message.success('Description was updated successfully');
                } else {
                    message.error('Error updating');
                }
            })
    }

    const editBuild = () => {
        setIsEditBuildModalOpen(true);
    }

    const closeEditBuildModal = () => {
        setIsEditBuildModalOpen(false);
    }

    const handleBuildChange = (build) => {
        setBuild(build)
    }

    const cancelHandler = () => {
        setIsEditorModified(false);
        setShowToolbar('');
    }

    const handleContainerClick = () => {
        setShowToolbar('ShowToolbar');
        setIsEditorModified(true);
    };

    if (isLoading) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <Spin size="large" />
            </div>
        );
    }

    if (!isBuildExist) {
        return (
            <Result
                status="404"
                title="404"
                subTitle="Sorry, the build not found."
                extra={
                    <Button type="primary" href="/">
                        Go to Hub
                    </Button>
                }
            />
        )
    }

    if (!isUserAssigned) {
        return (
            <Result
                status="403"
                title="403"
                subTitle="Sorry, you don't have access to this page."
                extra={
                    <Button type="primary" href="/">
                        Go to Hub
                    </Button>
                }
            />
        )
    }

    return (
        <>
            <div style={{ overflowY: 'hidden' }}>
                <div className='buildData'>
                    <Scrollbar style={{ height: 'calc(100vh - 100px)', minWidth: '700px' }}>
                        <div className='leftSide'>
                            <div className='buildHeader'>
                                <div className='buildName'>
                                    <Tooltip title={build.sow_name}>
                                        <div>{build.sow_name.length > 40 ? build.sow_name.slice(0, 40) + '...' : build.sow_name}</div>
                                    </Tooltip>
                                </div>
                                <div>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ display: 'flex' }}>
                                            {user.role_id === 3 && (
                                                <div style={{display: 'flex', justifyContent: 'space-between', gap: '4px'}}>
                                                    {isEditorModified && <Button onClick={cancelHandler}>Cancel</Button>}
                                                    {isEditorModified && <Button onClick={editorHandler} icon={<SaveOutlined />}>Save</Button>}
                                                    <Button icon={<EditOutlined />} onClick={editBuild}></Button>
                                                    <Button onClick={deleteBuildHandler} icon={<DeleteOutlined />}></Button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ margin: '8px 0 8px 0' }}>
                                <div>
                                    <BuildDetails
                                        build={build}
                                        users={users}
                                    />
                                </div>
                            </div>
                            <div>
                                <div
                                    className={`buildEditor${showToolbar}`}
                                    onClick={user.role_id === 3 || user.role_id === 2 ? handleContainerClick : null}
                                >
                                    <Editor
                                        isLoading={isLoading}
                                        fileList={fileList}
                                        credentials={build.credentials_description}
                                        summary={build.summary_description}
                                        documents={build.documents_description}
                                        onCredentialsChange={handleCredentialsChange}
                                        onSummaryChange={handleSummaryChange}
                                        onDocumentsChange={handleDocumentsChange}
                                        user={user}
                                        administrator={build.administrator}
                                    />
                                </div>

                            </div>
                            <div className='filesTable'>
                                <div className='filesTableHeader'>
                                    <div className='attLabel'>Attachments</div>
                                    {
                                        (user.role_id === 2 || user.role_id === 3) &&
                                        <div className='uploader'>
                                            <Upload {...uploadProps} showUploadList={false}>
                                                <Button size='small' icon={<PlusOutlined />}></Button>
                                            </Upload>
                                        </div>
                                    }
                                </div>
                                <div className='tableWithFiles'>
                                    <Table dataSource={data} columns={columns} onChange={handleChange} size="small" />
                                </div>
                            </div>
                            <div style={{ marginTop: '16px' }}>
                                <CommentBox
                                    user={user}
                                    users={users}
                                    isBuild={true}
                                    id={build.id}
                                    comments={comments}
                                    administrator={build.administrator}
                                    name={build.sow_name}
                                />
                            </div>
                        </div>
                    </Scrollbar>
                    <div style={{ marginLeft: '16px', minWidth: '580px' }}>

                        <div>
                            <div style={{ fontSize: '18px', marginBottom: '6px' }}>Timeline</div>
                            <div style={{ fontSize: '12px', marginBottom: '6px' }}>Hint: shows a dedicated estimate for each activity</div>
                            <Scrollbar style={{ height: 'calc(92vh - 100px)' }}>
                                <div>
                                    <DragDropContext>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <div style={{ marginRight: '8px', marginTop: '8px' }}>
                                                {Array(activityAssignments.length).fill().map((_, index) => (
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <img style={{ width: '12px' }} src={Dot} alt={index} />
                                                        {index !== activityAssignments.length - 1 && (
                                                            <div
                                                                style={{
                                                                    width: '1px',
                                                                    height: '96px',
                                                                    backgroundColor: '#DCDCDC',
                                                                }}
                                                            >
                                                            </div>
                                                        )}
                                                    </div>

                                                ))}
                                            </div>
                                            <Droppable droppableId="activityAssignments" className="activityAssignments" style={{ flex: 1 }}>
                                                {(provided, snapshot) => (
                                                    <div ref={provided.innerRef} {...provided.droppableProps} style={getListStyle(snapshot.isDraggingOver)}>
                                                        {activityAssignments.map((assignment, index) => (
                                                            <Draggable
                                                                isDragDisabled={true}
                                                                key={assignment.id}
                                                                draggableId={String(assignment.id)}
                                                                index={index}
                                                            >
                                                                {(provided, snapshot) => (
                                                                    <Card
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        ref={provided.innerRef}
                                                                        style={getItemStyle(
                                                                            snapshot.isDragging,
                                                                            provided.draggableProps.style
                                                                        )}
                                                                    >
                                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                            <div style={{ fontSize: '14px', fontWeight: '600' }}>{assignment.name}</div>
                                                                        </div>
                                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                            <div style={{ display: 'flex' }}>
                                                                                <div style={{ fontWeight: '500', color: '#437EB9', marginRight: '8px' }}>{assignment.role_name}</div>
                                                                                <div>{assignment.estimate}h</div>
                                                                            </div>
                                                                            <div style={{ marginLeft: '32px' }}>
                                                                                <span style={{ color: assignment.not_allocated_budget > 0 ? 'green' : 'red' }}>{assignment.not_allocated_budget}h</span>
                                                                                <span style={{ fontSize: '12px', paddingLeft: '4px' }}>hours can be used</span>
                                                                            </div>
                                                                        </div>
                                                                        <Tooltip title={assignment.summary}>
                                                                            <div
                                                                                style={{
                                                                                    whiteSpace: 'nowrap',
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis',
                                                                                    maxWidth: '100%',
                                                                                }}
                                                                            >
                                                                                {assignment.summary}
                                                                            </div>
                                                                        </Tooltip>
                                                                    </Card>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </div>
                                    </DragDropContext>
                                </div>
                            </Scrollbar>
                        </div>
                    </div>
                </div>
            </div >
            <EditBuildModal
                build={build}
                isOpen={isEditBuildModalOpen}
                handleEditBuildModalChange={closeEditBuildModal}
                users={users}
                onBuildChange={handleBuildChange}
            />
        </>
    );
};

export default BuildPage;