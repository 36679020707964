import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Modal, Select, message } from "antd";
import { useUser } from '../../UserContext';
import ServiceTasks from '../../services/tickets';

const { Option } = Select;

const EditTaskModal = (props) => {
    const { user, loading } = useUser();

    const [form] = Form.useForm();
    const [task, setTask] = useState(props.task);
    const [assignments, setAssignments] = useState();
    const [buttonLoading, setButtonLoading] = useState(false);

    useEffect(() => {
        setTask(props.task);
        console.log('task', props.task);
        setAssignments(props.assignments);

        const initialValues = {
            summary: props.task.summary,
        };

        props.assignments.forEach((assignment) => {
            initialValues[`assignee_${assignment.id}`] = assignment.user_id;
        });

        console.log('initialValues', initialValues);

        form.setFieldsValue(initialValues);
    }, [form, props.task]);

    const handleCloseModal = () => {
        props.handleEditTaskModalChange(false);
    };

    const onFinish = (values) => {
        setButtonLoading(true);
        console.log('assignments', assignments);
        ServiceTasks.updateTask({ ...task, summary: values.summary, assignments: assignments })
            .then((response) => {
                if (response.data) {
                    message.success('Task was updated successfully');
                    // const user = props.users.find((user) => user.id === values.administrator);
                    // const display_name = user ? user.display_name : '';
                    props.onTaskChange({ ...task, summary: values.summary });
                    console.log('new', assignments);
                    props.onAssignmentsChange(assignments);

                    props.handleEditTaskModalChange(false);
                    setButtonLoading(false);
                } else {
                    setButtonLoading(false);
                    message.error('Error updating');
                }
            });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    function handleAssigneeChange(value, assignemt) {
        const updatedAssignments = assignments.map((item) => {
            if (item.id === assignemt.id) {
                const user = props.users.find((user) => user.id === value);
                const display_name = user ? user.display_name : '';
                return { ...item, user_id: value, display_name: display_name, is_changed: true }
            } else {
                return item;
            }
        })
        setAssignments(updatedAssignments)
        console.log(value);
        console.log(assignemt);
    }

    return (
        <Modal
            open={props.isOpen}
            width={450}
            onCancel={handleCloseModal}
            footer={[
                <Button key="cancel" onClick={handleCloseModal}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" loading={buttonLoading} onClick={() => form.submit()}>
                    Submit
                </Button>
            ]}
            title="Task configuration"
            centered
        >
            <div>
                <Form
                    name="basic"
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    form={form}
                >

                    <Col>
                        <Form.Item
                            label="Summary"
                            name="summary"
                            rules={[{ required: true, message: 'Please enter a summary' }]}
                        >
                            <Input />
                        </Form.Item>
                        {task.status !== 'Done' && assignments && assignments.map((assignment) => {
                                return (
                                    <Form.Item
                                        label={assignment.is_mute ? <div>{assignment.name} (muted)</div> : <div>{assignment.name}</div>}
                                        key={`assignee_${assignment.id}`}
                                        name={`assignee_${assignment.id}`}
                                    >
                                        <Select
                                            showSearch
                                            style={{ width: 255 }}
                                            placeholder="Search Users"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            disabled={assignment.is_mute}
                                            onChange={(value) => handleAssigneeChange(value, assignment)}
                                        >
                                            {props.users && props.users.map((user) => (
                                                <Option key={user.id} value={user.id}>
                                                    {user.display_name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                );
                            })}
                    </Col>
                </Form>
            </div>
        </Modal>
    );
}

export default EditTaskModal;