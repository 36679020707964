import React, { useState, useEffect } from 'react';
import { Table, Input, Space, DatePicker, Pagination, Button, message, Spin } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import ServiceWorklogs from '../../services/worklogs';
import { Link } from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';
import { useUser } from '../../UserContext';
import moment from "moment";
import 'moment/locale/en-gb'

const WorklogsTable = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    });
    const [editingCommentId, setEditingCommentId] = useState(null);
    const [editedComment, setEditedComment] = useState('');
    const [editingTimeId, setEditingTimeId] = useState(null);
    const [editedTime, setEditedTime] = useState(null);
    const [isSpinActive, setIsSpinActive] = useState(true);
    const { user } = useUser();

    const updateComment = async (log, newComment) => {
        setLoading(true);
        try {
            const updatedLog = {
                id: log.id,
                task: log.task_key,
                timeSpentHours: log.time_spent_hours,
                createdDate: log.created_at,
                comment: newComment,
                activity: log.activity_id
            }
            console.log('updatedLog', updatedLog)
            await ServiceWorklogs.updateLog(updatedLog)
                .then(() => {
                    const updatedData = data.map(item => {
                        if (item.id === log.id) {
                            return { ...item, comment: newComment };
                        }
                        return item;
                    });
                    setEditingCommentId(null);
                    setData(updatedData);
                    message.success('Log was updated successfully.');
                    setLoading(false);
                })
        } catch (error) {
            console.error('Error updating comment:', error);
        }
    };

    const updateTimeComment = async (log, newTime) => {
        setLoading(true);
        try {
            const updatedLog = {
                id: log.id,
                task: log.task_key,
                timeSpentHours: newTime,
                createdDate: log.created_at,
                comment: log.comment,
                activity: log.activity_id,
            };
            await ServiceWorklogs.updateLog(updatedLog).then(() => {
                const updatedData = data.map((item) => {
                    if (item.id === log.id) {
                        return { ...item, time_spent_hours: newTime };
                    }
                    return item;
                });
                setEditingTimeId(null);
                setData(updatedData);
                message.success('Log was updated successfully.');
                setLoading(false);
            });
        } catch (error) {
            console.error('Error updating time:', error);
        }
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'display_name',
            key: 'display_name',
            render: (record) => (
                <div>
                    <span style={{ fontWeight: '600' }}>{record}</span>
                </div>
            ),
            width: '15%'
        },
        {
            title: 'Hours',
            dataIndex: 'time_spent_hours',
            key: 'time_spent_hours',
            width: '5%',
            onCell: (record) => ({
                onClick: () => handleEditTime(record),
            }),
            render: (text, record) => (
                <div>
                    {editingTimeId === record.id ? (
                        <div>
                            <div style={{ width: '80px' }}>
                                <Input
                                    value={editedTime}
                                    onChange={(e) => setEditedTime(e.target.value)}
                                    onPressEnter={() => {
                                        handleSaveTime(record);
                                        setEditingTimeId(null); // Add this line to reset the editing state
                                    }}
                                />

                            </div>
                        </div>
                    ) : (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {text}
                        </div>
                    )}
                </div>
            ),
        },
        {
            title: 'Task',
            dataIndex: 'summary',
            key: 'summary',
            render: (text, record) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                        <Link to={`/task/${record.task_key}`}>
                            <span style={{ fontWeight: '500' }}>{text}</span>
                        </Link>
                    </div>
                </div>
            ),
            width: '20%'
        },
        {
            title: 'Comment',
            dataIndex: 'comment',
            key: 'comment',
            render: (text, record) => (
                <div
                    style={{
                        color: text ? 'black' : 'red',
                    }}
                >
                    {editingCommentId === record.id ? (
                        <div>
                            <div>
                                <TextArea
                                    rows={3}
                                    value={editedComment}
                                    onChange={(e) => setEditedComment(e.target.value)}
                                />

                            </div>
                            <div>
                                <Button
                                    style={{ marginTop: '8px', }}
                                    onClick={() => {
                                        updateComment(record, editedComment || 'There is no comment.');
                                    }}
                                >
                                    Save
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <span onClick={() => {
                            setEditingCommentId(record.id);
                            setEditedComment(text === 'There is no comment.' ? '' : text);
                        }}
                            style={{ cursor: 'pointer' }}>
                            {text || 'There is no comment.'}
                        </span>
                    )}
                </div>
            ),
            width: '60%',
        },
        {
            title: 'Created',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (created_at) => new Date(created_at).toLocaleDateString('en-US'),
            width: '10%'
        },
    ];

    useEffect(() => {
        document.title = 'Time Dashboard';
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await ServiceWorklogs.getAllLogs();
                console.log('response', response.data);
                setData(response.data);
            } catch (error) {
                console.error('Error fetching worklogs:', error);
            } finally {
                setLoading(false);
                setIsSpinActive(false);
            }
        };

        fetchData();
    }, []);

    const { current, pageSize } = pagination;
    const paginatedData = data.slice(
        (current - 1) * pageSize,
        current * pageSize
    );

    const handlePageChange = (page, pageSize) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            current: page,
            pageSize,
        }));
    };

    const handleDatesSearch = async (dates) => {
        try {
            setLoading(true);
            let response;
            if (dates) {
                response = await ServiceWorklogs.search(moment(new Date(dates[0])).format('YYYY-MM-DD'), moment(new Date(dates[1])).format('YYYY-MM-DD'));
            } else {
                response = await ServiceWorklogs.getAllLogs();
            }
            setData(response.data);
            setPagination((prevPagination) => ({
                ...prevPagination,
                current: 1,
            }));
            setLoading(false);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleEditTime = (log) => {
        setEditingTimeId(log.id);
        setEditedTime(log.time_spent_hours);
    };

    const handleSaveTime = (log) => {
        updateTimeComment(log, editedTime);
        setEditingTimeId(null); // Add this line to reset the editing state
    };

    if (isSpinActive) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <Spin size="large" />
            </div>
        );
    }

    return (
        <div style={{ height: '92vh', overflowY: 'auto', overflowX: 'hidden' }}>
            <div style={{ padding: '20px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', gap: '6px' }}>
                        <div>
                            <DatePicker.RangePicker
                                onChange={(dates) => {
                                    handleDatesSearch(dates)
                                }}
                            />

                            <div style={{ padding: '8px 0 8px 0' }}>
                                Tip: to change a comment, <span style={{ fontWeight: '500' }}>click</span> on it.
                            </div>
                        </div>
                        {/* {user.role_id === 3 &&

                            <div>
                                <Link to={'/report'}>
                                    <Button>
                                        <span style={{ fontWeight: '500' }}>Log Report</span>
                                    </Button>
                                </Link>
                            </div>
                        }
                        {user.role_id === 3 &&

                            <div>
                                <Link to={'/ctl'}>
                                    <Button>
                                        <span style={{ fontWeight: '500' }}>CTL Board</span>
                                    </Button>
                                </Link>
                            </div>
                        } */}
                    </div>
                    <div>
                        <div style={{ fontWeight: '500' }}>Metabase</div>
                        <div><Link target='_blank' to={'https://bi.twistellar.com/'}>Reporting & Analysis</Link>
                        </div>

                    </div>

                </div>
                <Table
                    dataSource={paginatedData} // Apply pagination to dataSource
                    columns={columns}
                    loading={loading}
                    pagination={false} // Disable default Table pagination
                    size='small'
                />
                <Pagination
                    style={{ marginTop: '20px' }}
                    current={current}
                    pageSize={pageSize}
                    total={data.length}
                    onChange={handlePageChange}
                    showSizeChanger
                    showTotal={(total) => `Total: ${total}`}
                />
            </div>
        </div>
    );
};

export default WorklogsTable;