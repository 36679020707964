import React, { useState, useEffect } from 'react';
import { Table, Input, Button, Pagination, Spin } from 'antd';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { Scrollbar } from 'react-scrollbars-custom';
import { Media } from 'react-responsive';
import ServiceUsers from '../../services/users';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);

    const fetchUsers = async () => {
        const response = await ServiceUsers.getAllUsers();
        console.log('response', response.data);
        setUsers(response.data);
        setIsLoading(false);
    };

    useEffect(() => {
        document.title = 'Users';
        fetchUsers();
    }, []);

    const deactivateUser = async (user) => {
        setLoading(true);
        user.active = false;
        const response = await ServiceUsers.updateUser(user);
        setUsers(users.map((existingUser) => {
            if (existingUser.id === response.data.id) {
                return user;
            }
            return existingUser;
        }));
        setLoading(false);
    }

    const activateUser = async (user) => {
        setLoading(true);
        user.active = true;
        ServiceUsers.updateUser(user);
        const response = await ServiceUsers.updateUser(user);
        setUsers(users.map((existingUser) => {
            if (existingUser.id === response.data.id) {
                return user;
            }
            return existingUser;
        }));
        setLoading(false);
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'display_name',
            key: 'display_name',
            width: '20%',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: '20%',
        },
        {
            title: 'Role',
            dataIndex: 'role_name',
            key: 'role_name',
            width: '20%',
        },
        {
            title: 'Active',
            dataIndex: 'active',
            key: 'active',
            width: '10%',
            render: (active, user) => (
                <div>
                    {active ?
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div>
                                <CheckCircleTwoTone twoToneColor="#52c41a" />
                            </div>
                            <Button
                                size='small'
                                onClick={() => deactivateUser(user)}
                            >
                                Deactivate
                            </Button>
                        </div>
                        :
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div>
                                <CloseCircleTwoTone twoToneColor="#FA5F55" />
                            </div>
                            <Button
                                size='small'
                                onClick={() => activateUser(user)}
                            >
                                Activate
                            </Button>
                        </div>
                    }
                </div>
            ),
        },
    ];

    const { current, pageSize } = pagination;
    const paginatedData = users.slice(
        (current - 1) * pageSize,
        current * pageSize
    );

    const handlePageChange = (page, pageSize) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            current: page,
            pageSize,
        }));
    };

    const handleSearch = async (searchText) => {
        try {
            setLoading(true);
            const response = await ServiceUsers.search(searchText);
            const data = await response.data;
            setUsers(data);
            setPagination((prevPagination) => ({
                ...prevPagination,
                current: 1,
            }));
            setLoading(false);
        } catch (error) {
            console.error('Error searching users:', error);
        }
    };

    if (isLoading) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <Spin size="large" />
            </div>
        );
    }

    return (

        <Scrollbar style={{ height: 'calc(100vh - 64px)' }}>
            <div style={{ padding: '10px' }}>
                <div style={{ marginBottom: '8px', width: '200px' }}>
                    <Input
                        placeholder="Search"
                        allowClear
                        // value={searchText}
                        onChange={(e) => handleSearch(e.target.value)}
                    />
                </div>
                <div>
                    <Table
                        columns={columns}
                        dataSource={paginatedData.map(user => ({ ...user, key: user.id }))}
                        pagination={false}
                        size="small"
                        loading={loading}
                    />
                    <Pagination
                        style={{ marginTop: '16px', textAlign: 'right' }}
                        current={current}
                        pageSize={pageSize}
                        total={users.length}
                        showSizeChanger
                        onChange={handlePageChange}
                    />
                </div>
            </div>
        </Scrollbar>
    );
};

export default Users;