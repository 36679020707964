import axiosInstance from "../config/axios";

class ServiceBuilds {
  // async isUserAssignedToAnyTask(id) {
  //   return await axiosInstance.get(`/builds/${id}/user`);
  // }

  async createAccount(account) {
    return await axiosInstance.post('/builds/account', account);
  }

  async getAccounts() {
    return await axiosInstance.get('/builds/account');
  }

  async getCards() {
    return await axiosInstance.get('/cards');
  }

  async createBuild(build) {
    return await axiosInstance.put('/builds/', build);
  }

  async getBuilds(filters) {
    return await axiosInstance.post('/builds/', filters);
  }

  async getBuildsForAdministrator() {
    return await axiosInstance.get('/builds/admin');
  }

  async getBuild(id) {
    return await axiosInstance.get(`/builds/${id}/details`);
  }

  async getActivityAssignments(id) {
    return await axiosInstance.get(`/builds/${id}/activity_assignments`);
  }

  async updateBuild(build) {
    return await axiosInstance.put('/builds/', build);
  }

  async deleteBuild(id) {
    return await axiosInstance.delete(`/builds/${id}`);
  }

  async applyFilter(field, value) {
    return await axiosInstance.get('/builds/filter', {
      params: { field, value },
    });
  }

  async applySorter(order) {
    return await axiosInstance.get('/builds/sort', {
      params: { order },
    });
  }

  async getAccountsWithBuilds(filters) {
    return await axiosInstance.post('/builds/accounts', filters);
  }
}

export default new ServiceBuilds();