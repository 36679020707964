import axiosInstance from "../config/axios";

class ServiceTickets {

  async createTask(values) {
    return await axiosInstance.post('/tickets/', values);
  }

  async getTask(key) {
    return await axiosInstance.get(`/tickets/${key}/details`);
  }

  async updateTask(task) {
    return await axiosInstance.put('/tickets/', task);
  }

  async deleteTask(key) {
    return await axiosInstance.delete(`/tickets/${key}`);
  }

  // retrieve tasks where user is assigned
  async getUserTasks() {
    return await axiosInstance.get(`/tickets/user`);
  }

  async launchTimeline(key) {
    return await axiosInstance.post(`/tickets/${key}/timeline`)
  }

  async getInitTickets() {
    return await axiosInstance.get('/tickets');
  }

  async getAllTickets() {
    return await axiosInstance.get('/tickets/all-tickets');
  }

  async getAllMyTickets(id) {
    return await axiosInstance.get(`/tickets/all-my-tickets/${id}`);
  }

  async getAll() {
    return await axiosInstance.get('/tickets/all');
  }

  async updateTicketDescription(ticketId, values) {
    return await axiosInstance.put(`/tickets/update-description/${ticketId}`, values);
  }

  async sendEmailWithUpdates(recipientEmail, subject, content) {
    return await axiosInstance.post('/sendEmailNotification', {
      recipientEmail: 'recipient@example.com',
      subject: 'Ticket Updated',
      content: '<p>The ticket has been updated. Please check the changes.</p>',
    })
  }

  async getAllMyManagedTickets(id) {
    return await axiosInstance.get(`/tickets/all-my-managed-tickets/${id}`);
  }

  async search(text) {
    return await axiosInstance.get(`/tickets/search?searchText=${text}`);
  }

  async searchManaged(id, text) {
    return await axiosInstance.get(`/tickets/search-managed/${id}?searchText=${text}`);
  }  

  async checkIfUserIsAssignedToTicket(id) {
    return await axiosInstance.get(`/tickets/check-user-assignee/${id}`)
  }

  async getActivityAssignments(key) {
    return await axiosInstance.get(`/tickets/activities/${key}`)
  }
}

export default new ServiceTickets();