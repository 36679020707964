// UserProfile.js
import { React } from 'react';
import { Card, Divider, Space, Tag } from 'antd';
import { useUser } from '../../UserContext';
import './Profile.css'

const Profile = () => {
    const { user } = useUser();


    return (
        <div className='profile'>
            <div className='profile-left-part'>
                <Card >
                    <div className='profile-avatar'>
                        <img
                            className='profile-avatar-icon'
                            src="https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png"  // Placeholder image, replace with your logic to fetch user's photo
                            alt="Profile"
                        />
                    </div>
                    <div className='profile-info'>
                        <div className='profile-name'>
                            {user.display_name}
                        </div>
                        <div>
                            {user.role_name}
                        </div>
                    </div>
                    <Divider />
                    <span>
                        Technologies:
                    </span>
                    <div className='profile-technologies'>
                        <Space size={[0, 8]} wrap>
                            <Tag>
                                Salesforce
                            </Tag>
                            {/* <Tag>
                                MC
                            </Tag>
                            <Tag>
                                LWC
                            </Tag>
                            <Tag>
                                Apex
                            </Tag>
                            <Tag>
                                JS
                            </Tag>
                            <Tag>
                                React
                            </Tag>
                            <Tag>
                                React Native
                            </Tag>
                            <Tag>
                                TypeScript
                            </Tag>
                            <Tag>
                                Node
                            </Tag>
                            <Tag>
                                HTML
                            </Tag>
                            <Tag>
                                CSS
                            </Tag>
                            <Tag>
                                AntD
                            </Tag>
                            <Tag>
                                MUI
                            </Tag>
                            <Tag>
                                Bootstrap
                            </Tag>
                            <Tag>
                                Docker
                            </Tag> */}
                        </Space>
                    </div>
                </Card>
            </div>

            <div className='profile-right-part'>
            </div>
        </div>
    );
};

export default Profile;
