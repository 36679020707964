import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faCalendar } from '@fortawesome/free-solid-svg-icons';
import { format, startOfWeek, endOfWeek, addWeeks, eachDayOfInterval } from 'date-fns';
import { Button, Statistic } from 'antd';
import {
    CalendarOutlined
} from '@ant-design/icons';
import TimeLoggingHeader from '../TimeLoggingHeader/TimeLoggingHeader';
import './WeekSwitcher.css'

function WeekSwitcher(props, { weekOffset = 0 }) {
    useEffect(() => {
        const range = getWeekRange(0);
        props.onData(range);
    }, []);

    const [currentWeek, setCurrentWeek] = useState(weekOffset);
    const now = new Date();
    const startOfWeekDate = startOfWeek(addWeeks(now, currentWeek), { weekStartsOn: 1 });
    const endOfWeekDate = endOfWeek(addWeeks(now, currentWeek), { weekStartsOn: 1 });

    const formattedStartDate = format(startOfWeekDate, 'dd MMM');
    const formattedEndDate = format(endOfWeekDate, 'dd MMM, yyyy');

    const formattedWeekRange = `${formattedStartDate} - ${formattedEndDate}`;
    const handleNextWeek = () => {
        const newCurrentWeek = currentWeek + 1;
        setCurrentWeek(newCurrentWeek);
        console.log('Week days:', JSON.stringify(getWeekRange(newCurrentWeek)));
        props.onData(getWeekRange(newCurrentWeek));
    };

    const handlePrevWeek = () => {
        const newCurrentWeek = currentWeek - 1;
        console.log(newCurrentWeek);
        setCurrentWeek(newCurrentWeek);
        console.log('Week days:', JSON.stringify(getWeekRange(newCurrentWeek)));
        props.onData(getWeekRange(newCurrentWeek));
    };

    const handleResetWeek = () => {
        setCurrentWeek(0);
        console.log('Week days:', JSON.stringify(getWeekRange(0)));
        props.onData(getWeekRange(0));
    };

    const getWeekRange = (weekOffset) => {
        const startOfWeekDate = startOfWeek(addWeeks(now, weekOffset), { weekStartsOn: 1 });
        const endOfWeekDate = endOfWeek(addWeeks(now, weekOffset), { weekStartsOn: 1 });
        const weekDays = eachDayOfInterval({ start: startOfWeekDate, end: endOfWeekDate });
        const formattedWeekDays = weekDays.map(day => ({ date: format(day, 'dd MMM, yyyy') }));
        return formattedWeekDays;
    };

    return (
        <>
            {/* <div style={{marginBottom: '16px'}}>
                <TimeLoggingHeader user={JSON.parse(localStorage.getItem('user'))} />
            </div> */}
            <div style={{
                display: 'flex',
                alignItems: 'center'
            }}>

                <CalendarOutlined />
                <div style={{
                    marginLeft: '10px',
                    fontSize: '14px'
                }}>
                    {formattedWeekRange}
                </div>
                <div style={{
                    marginLeft: '10px'
                }}>
                    <Button type='text' onClick={handlePrevWeek}>
                        <FontAwesomeIcon icon={faArrowLeft} style={{
                            cursor: 'pointer'
                        }} />
                    </Button>
                </div>
                <div style={{
                    marginLeft: '10px'
                }}>
                    <Button type='text' onClick={handleNextWeek}>
                        <FontAwesomeIcon icon={faArrowRight} style={{
                            cursor: 'pointer'
                        }} />
                    </Button>
                </div>
                <div
                    className='todayButton'
                    style={{
                        marginLeft: '10px'
                    }}>
                    <Button onClick={handleResetWeek}>
                        <span style={{
                            fontWeight: 550,
                        }}>
                            Today
                        </span>
                    </Button>
                </div>
            </div>
        </>

    )
}

export default WeekSwitcher;
