import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./assets/global.css";
import { UserProvider } from './UserContext';
import { ConfigProvider } from 'antd';
import enUs from 'antd/locale/en_US';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <UserProvider>
      <ConfigProvider
        locale={enUs}
        theme={{
          token: {
            fontFamily: "Anuphan"
          },
          components: {
            Layout: {
              triggerBg: '#fafafa',
              triggerColor: 'black',
            }
          }
        }}>
        <App />
        /</ConfigProvider>
    </UserProvider>
  </BrowserRouter>
);
