import React, { useState, useEffect } from 'react';
import { Table, Pagination, Input, Tag, Spin } from 'antd';
import { Link } from 'react-router-dom';
import debounce from 'lodash/debounce'; // Import lodash's debounce function
import ServiceTickets from '../../services/tickets';
import { useUser } from '../../UserContext';
import { DateTime } from 'luxon';

const MyWorkPage = () => {
    const { user } = useUser();
    const [tickets, setTasks] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    });
    const [isLoading, setIsLoading] = useState(true);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        document.title = 'My Work';
        const fetchData = async () => {
            try {
                if (user && user.id) {
                    const tasks = await ServiceTickets.getUserTasks();
                    console.log('tasks', tasks);
                    setTasks(tasks.data);
                    setIsLoading(false);
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [user]);

    const { current, pageSize } = pagination;
    const paginatedData = tickets.slice((current - 1) * pageSize, current * pageSize);

    // Create a debounced search function
    const debouncedSearch = debounce(async (searchText) => {
        try {
            setLoading(true);
            const response = await ServiceTickets.search(searchText);
            setTasks(response.data);
            setPagination((prevPagination) => ({
                ...prevPagination,
                current: 1,
            }));
            setLoading(false);
        } catch (error) {
            console.error('Error searching tickets:', error);
        }
    }, 300); // Adjust the debounce delay (in milliseconds) as needed

    const handleSearch = (e) => {
        const searchText = e.target.value;
        debouncedSearch(searchText); // Call the debounced search function
    };

    if (isLoading) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <Spin size="large" />
            </div>
        );
    }

    const columns = [
        {
            title: 'Summary',
            dataIndex: 'summary',
            key: 'summary',
            width: '25%',
            render: (text, record) => (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                        <Link to={`/task/${record.key}`}>
                            <span style={{ fontWeight: '500' }}>
                                {text}
                            </span>
                        </Link>
                    </div>
                    <div style={{ fontSize: '12px' }}>
                        <span style={{ fontWeight: '500' }}>{record.activities.length}</span> activity
                    </div>
                </div>
            )
        },
        {
            title: 'Administrator',
            dataIndex: 'display_name',
            key: 'display_name',
            width: '15%',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '10%',
            render: (status) => <div><Tag color="geekblue">{status}</Tag></div>
        },
        {
            title: 'Priority',
            dataIndex: 'priority',
            key: 'priority',
            width: '10%',
        },
        {
            title: 'Task Type',
            dataIndex: 'task_type',
            key: 'task_type',
            width: '10%',
        },
        {
            title: 'Start Date',
            dataIndex: 'start_date',
            key: 'start_date',
            width: '10%',
            render: (text) => DateTime.fromISO(text, { zone: Intl.DateTimeFormat().resolvedOptions().timeZone }).toFormat('yyyy-MM-dd'),
        },
        {
            title: 'Due Date',
            dataIndex: 'due_date',
            key: 'due_date',
            width: '10%',
            render: (text) => DateTime.fromISO(text, { zone: Intl.DateTimeFormat().resolvedOptions().timeZone }).toFormat('yyyy-MM-dd'),
        },
    ];

    const handlePageChange = (page, pageSize) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            current: page,
            pageSize,
        }));
    };

    if (isLoading) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <Spin size="large" />
            </div>
        );
    }


    return (
        <div style={{ height: '92vh', overflowY: 'auto', overflowX: 'hidden' }}>
            <div style={{ padding: '10px' }}>
                <div
                    style={{
                        display: 'flex',
                    }}
                >
                    <Input
                        placeholder="Search..."
                        style={{ marginBottom: '10px', width: '200px' }}
                        onChange={handleSearch}
                    />
                </div>
                <Table
                    // rowSelection={{ ...rowSelection }}
                    dataSource={paginatedData}
                    loading={loading}
                    columns={columns}
                    pagination={false}
                    size='small'
                />
                <Pagination
                    style={{ marginTop: '20px' }}
                    current={current}
                    pageSize={pageSize}
                    total={tickets.length}
                    onChange={handlePageChange}
                    showSizeChanger
                    showTotal={(total) => `Total: ${total}`}
                />
            </div>
        </div >
    );
};

export default MyWorkPage;