import React, { useEffect, useState } from 'react';
import { Descriptions, Tag, Collapse, List, Skeleton, Progress } from 'antd';
import './BuildDetails.css'
import { CheckCircleTwoTone, ClockCircleTwoTone } from '@ant-design/icons';

const { Panel } = Collapse;

const BuildDetails = (props) => {
    const [build, setBuild] = useState(props.build || {});

    useEffect(() => {
        console.log('props', props);
        setBuild(props.build);
    }, [props.build])

    return (
        <div>
            <div>
                <Collapse size='small' defaultActiveKey={['1']}>
                    <Panel
                        header="Build Details"
                        key="1"
                    >
                        <div>
                            <Descriptions column={2} size='small'>
                                <Descriptions.Item label="Budget">{build.budget}</Descriptions.Item>
                                <Descriptions.Item label="Administrator">{build.display_name}</Descriptions.Item>
                                <Descriptions.Item label="Remaining Budget">
                                    <span style={{ fontWeight: '500' }}>{build.remaining_budget}</span>
                                </Descriptions.Item>
                                <Descriptions.Item label="Billing Type">{build.billing_type}</Descriptions.Item>
                                <Descriptions.Item label="Not Allocated Budget">
                                    <span style={{ color: build.not_allocated_budget > 0 ? 'green' : 'red', fontWeight: '500' }}>
                                        {build.not_allocated_budget}
                                    </span>
                                </Descriptions.Item>
                                <Descriptions.Item label="Status"><Tag>{build.status}</Tag></Descriptions.Item>
                                <Descriptions.Item label="Start Date">{new Date(build.start_date).toLocaleDateString()}</Descriptions.Item>
                                <Descriptions.Item label="Due Date">{new Date(build.due_date).toLocaleDateString()}</Descriptions.Item>
                                <Descriptions.Item label="Owner Email">{build.product_owner_email}</Descriptions.Item>
                            </Descriptions>
                        </div>
                    </Panel>
                </Collapse>
            </div>
            <div className='build-tasks-section'>
                <Collapse size='small' defaultActiveKey={['1']}>
                    <Panel
                        header="Related Tasks"
                        key="1"
                    >
                        <div>
                            <List
                                className="demo-loadmore-list"
                                itemLayout="horizontal"
                                dataSource={props.build.tasks}
                                renderItem={(item) => (
                                    <List.Item>
                                        <List.Item.Meta
                                            title={<a href={`/task/${item.key}`} rel='noreferrer' target="_blank">{item?.summary} - {item.status}</a>}
                                            description={
                                                <div className="task-activities">
                                                    {item.activities?.map((activity, index) => {
                                                        const estimate = parseFloat(activity.estimate) || 0;
                                                        const remaining = parseFloat(activity.remaining_estimate) || 0;
                                                        const progress = estimate > 0 ? ((1 - remaining / estimate) * 100).toFixed(2) : 0;

                                                        return (
                                                            <div className='activity'>
                                                                <div key={index} className="activity-item">
                                                                    {!activity.is_mute && (
                                                                        activity.is_done ? (
                                                                            <CheckCircleTwoTone twoToneColor="#52c41a" />
                                                                        ) : (
                                                                            <ClockCircleTwoTone twoToneColor="#2BB1D6" />
                                                                        )
                                                                    )}
                                                                    <span className={!activity.is_mute ? '' : 'line-through'}>
                                                                        {activity.name}
                                                                    </span>
                                                                </div>
                                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '8px' }}>
                                                                    {!activity.is_mute && estimate > 0 && (
                                                                        <Progress
                                                                            percent={Number(progress)}
                                                                            size="small"
                                                                            status="active"
                                                                        />
                                                                    )}
                                                                    <div style={{ width: '100%' }}><b>{remaining}h / {estimate}h</b> - {activity.not_allocated_budget}h max.</div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            }
                                        />
                                    </List.Item>
                                )}
                            />
                        </div>
                    </Panel>
                </Collapse>
            </div>
        </div>
    );
};

export default BuildDetails;
