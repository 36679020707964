import axiosInstance from "../config/axios";

class ServiceComments {
  async createComment(data) {
    return await axiosInstance.post('/comments/create-comment', data);
  }

  async getAllComments(id, isBuild) {
    return await axiosInstance.get(`/comments/${id}?isBuild=${isBuild}`)
  }
}

export default new ServiceComments();