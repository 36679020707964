import React, { useEffect, useState } from 'react';
import TimeLoggingHeader from '../../components/TimeLoggingHeader/TimeLoggingHeader';
import WeekSwitcher from '../../components/WeekSwitcher/WeekSwitcher';
import WeekDays from '../../components/WeekDays/WeekDays';
import './TimeLogging.css'
import { Divider, Spin } from 'antd';
import ServiceWorklogs from '../../services/worklogs';
import ServiceAuth from '../../services/auth';
import { useUser } from '../../UserContext';
import { Scrollbar } from 'react-scrollbars-custom';

function TimeLogging() {

    const { user } = useUser();
    const [worklogs, setWorklogs] = useState([]);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        document.title = 'My Time';
        const fetchData = async () => {
            try {
                if (user && user.id) {
                    ServiceWorklogs.getUserWorklogs(user.id)
                        .then(response => {
                            console.log('qq', response.data);
                            setWorklogs(response.data);
                            setIsLoading(false);
                        })
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [user]);

    function handleDataFromChild(dataFromChild) {
        setWorklogs(prevWorklogs => [...prevWorklogs, dataFromChild.worklog]);
    }

    function handleUpdatedDataFromChild(data) {
        const { log } = data;

        setWorklogs(prevWorklogs => {
            return prevWorklogs.map(prevLog => {
                if (prevLog.id === log.id) {
                    return { ...prevLog, ...log };
                } else {
                    return prevLog;
                }
            });
        });
    }


    const handleDeleteLog = (id) => {
        ServiceWorklogs.deleteLog(id)
            .then(() => {
                setWorklogs((prevWorklogs) => prevWorklogs.filter((log) => log.id !== id));
                console.log('Log removed successfully');
            })
            .catch((error) => {
                console.error('Failed to remove log:', error);
            });
    };

    const handleData = (childData) => {
        setData(childData);
    };

    if (isLoading) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <Spin size="large" />
            </div>
        );
    }

    return (
        <div className="timeLogging" >
            <div style={{ marginTop: '14px' }}>
                <WeekSwitcher onData={handleData} user={user} />
            </div>
            {/* <Divider>Time Logging</Divider> */}
            <Divider></Divider>
            <div style={{
                marginTop: '10px'
            }}>
                <WeekDays days={data} logs={worklogs} onUpdatedData={handleUpdatedDataFromChild} onData={handleDataFromChild} onDeleteLog={handleDeleteLog}></WeekDays>
            </div>
        </div>
    );
}

export default TimeLogging;