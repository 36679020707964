import { Card, Pagination, Tag, Button, Spin } from 'antd'; // Import Card instead of List
import React, { useEffect, useState } from 'react';
import './Notifications.css';
import { DeleteOutlined, FileDoneOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import ServiceNotifications from '../../services/notifications';
import { Scrollbar } from 'react-scrollbars-custom';

const Notifications = ({ notifications, onNotifications }) => {
    // const [currentPage, setCurrentPage] = useState(1);
    const [isSpinning, setIsSpinning] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'TIMS - Notifications'
    }, [notifications]);

    const handleClick = (item) => {
        console.log(item);
        if (item.task_key) {
            navigate(`/task/${item.task_key}`);
        } else {
            navigate(`/build/${item.build_id}`);
        }
        ServiceNotifications.updateNotification(item.id, { isChecked: true });
    };

    const markAllAsRead = () => {
        setIsSpinning(true);
        ServiceNotifications.markAllAsRead()
            .then((response) => {
                if (response.data.status === 'Success') {
                    const updatedData = notifications.map(item => {
                        return { ...item, is_checked: true };
                    });
                    onNotifications(updatedData);
                    setIsSpinning(false)
                }
            })
    }

    const deleteRead = () => {
        setIsSpinning(true);
        ServiceNotifications.deleteRead()
            .then((response) => {
                if (response.data.status === 'Success') {
                    const updatedData = notifications.filter(item => {
                        return !item.is_checked;
                    });
                    onNotifications(updatedData);
                    setIsSpinning(false)
                }
            })
    }

    // const itemsPerPage = 8;
    // const totalPages = Math.ceil(notifications.length / itemsPerPage);

    // const paginatedNotifications = notifications.slice(
    //     (currentPage - 1) * itemsPerPage,
    //     currentPage * itemsPerPage
    // );

    return (
        <div style={{ margin: '8px' }}>
            <div style={{ display: 'flex', gap: '8px', marginBottom: '8px' }}>
                <div>
                    <Button onClick={() => markAllAsRead()} icon={<FileDoneOutlined />}>Mark all as read</Button>
                </div>
                <div>
                    <Button onClick={() => deleteRead()} icon={<DeleteOutlined />}>Delete read</Button>
                </div>
            </div>

            <div style={{ width: '60%' }}>
                <div>
                    <Spin spinning={isSpinning} >

                        <Scrollbar style={{ height: 'calc(96vh - 100px)' }}>

                            <div>
                                {notifications.map((item, index) => (
                                    <Card
                                        key={item.id}
                                        onClick={() => handleClick(item)}
                                        className='card'
                                        style={{ background: !item.is_checked ? '#f1f8fd' : null, marginRight: '8px' }}
                                    >
                                        <div>
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div style={{ fontWeight: '550' }}>
                                                        {item.title}
                                                    </div>
                                                    <div style={{ marginLeft: '16px' }}>
                                                        {!item.is_checked && <Tag color="#108ee9">Unread</Tag>}
                                                    </div>
                                                </div>
                                                {new Date(item.created_at).toISOString().split('T')[0]}
                                            </div>
                                            from <span style={{ color: '#558DD8' }}>{item.sender_name}</span>
                                            <div>{item.message}</div>
                                        </div>

                                    </Card>
                                ))}
                            </div>
                        </Scrollbar>
                    </Spin>
                </div>
                {/* <Pagination
                    current={currentPage}
                    total={notifications.length}
                    pageSize={itemsPerPage}
                    onChange={(page) => setCurrentPage(page)}
                /> */}
            </div>
        </div>
    );
};

export default Notifications;
