import React, { useEffect, useState } from "react";
import { Button, Col, ConfigProvider, DatePicker, Form, Input, InputNumber, Modal, Row, Select, Space } from "antd";
import { BookOutlined, ClockCircleOutlined, CopyOutlined, EditOutlined, SettingFilled } from "@ant-design/icons";
import './EditModal.css';
import dayjs from "dayjs";
import moment from "moment";
import 'moment/locale/en-gb';

const { Option } = Select;

function EditTaskModal(props) {

    const [form] = Form.useForm();
    const [label, setLabel] = useState(null);
    const [isEditModal, setIsEditModal] = useState(false);
    const [tasks, setTasks] = useState(props.tasks);
    const [selectedTicketStatus, setSelectedTicketStatus] = useState(null);
    const [selectedTicketKey, setSelectedTicketKey] = useState(null); // Added selectedTicketKey state
    const [activityOptions, setActivityOptions] = useState([]);
    const formRef = React.useRef(null);

    const handleCloseModal = () => {
        props.isOpenChange(false);
    }


    useEffect(() => {
        setLabel(props.label);
        setTasks(props.tasks);
        setIsEditModal(props.isEditModal);
        try {
            const selectedTicket = props.tasks.find((task) => task.key === props.log.task_key);
            if (selectedTicket) {
                const activities = selectedTicket.activities.map((activity) => (
                    <Option key={activity.id} value={activity.id}>
                        {activity.name}
                    </Option>
                ));
                setActivityOptions(activities);
            }
        } catch (error) {
            console.error(error);
        }
    }, [props]);

    useEffect(() => {
        form.setFieldsValue({
            task: props.log.task_key,
            comment: props.log.comment,
            timeSpentHours: props.log.time_spent_hours,
            createdDate: dayjs(new Date(props.date)),
            activity: parseFloat(props.log.activity_id)
        });
    }, [props, form])

    const onFinish = (values) => {
        values.createdDate = moment(new Date(values.createdDate)).format('YYYY-MM-DD');
        values.id = props.log.id;
        values.user_id = props.log.user_id;
        values.timeSpentHours = parseFloat(values.timeSpentHours);

        if (!isEditModal)
            props.onData(values);
        else
            props.onEditLog(values);
        handleCloseModal();
    }

    const handleSelect = (value) => {
        const selectedTask = tasks.find((task) => task.key === value);
        setSelectedTicketKey(selectedTask ? selectedTask.key : null);
        setSelectedTicketStatus(selectedTask ? selectedTask.status : null);
        const newAssignmentsOptions = [];
        if (selectedTask) {
            for (const assignment of selectedTask.activities) {
                newAssignmentsOptions.push(
                    <Option key={assignment.id} value={assignment.id}>
                        {assignment.name}
                    </Option>
                );
            }
        }
        setActivityOptions(newAssignmentsOptions);
        formRef.current?.resetFields(['activity']);
    };

    function handleSearch(value) { }

    return (
        <ConfigProvider
            theme={{
                token: {
                    fontFamily: 'Anuphan'
                },
            }}
        >
            <Modal
                open={props.isOpen}
                width={450}
                onCancel={handleCloseModal}
                {...props}
                footer={[]}
            >
                <div className="editHeader">
                    <div>
                        {
                            isEditModal ? (<EditOutlined />) : (<CopyOutlined />)
                        }
                    </div>
                    <div className="editTitle">
                        {label}
                    </div>
                </div>
                <div>
                    <Form
                        form={form}
                        onFinish={onFinish}
                        layout="vertical"
                    >
                        <Row>
                            <Space>
                                <Col>
                                    <Form.Item
                                        label="Task"
                                        name="task"
                                    >
                                        <Select
                                            showSearch
                                            onSearch={handleSearch}
                                            onSelect={handleSelect}
                                            style={{ width: 200 }}
                                            placeholder="Select..."
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {tasks.map((task) => (
                                                <Option key={task.key} value={task.key}>
                                                    {task.summary}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col>
                                    <Form.Item
                                        label="Activity"
                                        name="activity"
                                        rules={[{ required: true, message: 'Please select an activity' }]}
                                    >
                                        <Select
                                            showSearch
                                            onSearch={handleSearch}
                                            style={{ width: 195 }}
                                            placeholder="Select..."
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {activityOptions}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Space>
                        </Row>

                        <Row gutter={8}>
                            <Col span={9}>
                                <Form.Item
                                    label="Date"
                                    name="createdDate"
                                    required
                                    rules={[
                                        {
                                            validator: (rule, value) => {
                                                console.log('date', value);
                                                if (value === null) {
                                                    return Promise.reject('Date must be filled in');
                                                }
                                                return Promise.resolve();
                                            },
                                        },
                                    ]}
                                >
                                    <DatePicker
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={9}>
                                <Form.Item
                                    label="Duration"
                                    name="timeSpentHours"
                                    required
                                    rules={[
                                        {
                                            validator: (rule, value) => {
                                                if (value <= 0) {
                                                    return Promise.reject('Duration must be a positive number');
                                                }
                                                if (!/^\d+(\.\d{1,2})?$/.test(value)) {
                                                    return Promise.reject('Duration must be a number with up to 2 decimal places');
                                                }
                                                return Promise.resolve();
                                            },
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        step={0.25}
                                        parser={(value) => value.replace(',', '.')}
                                    />
                                </Form.Item>
                            </Col>


                        </Row>

                        <Form.Item
                            label="Comment"
                            name="comment"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item>
                            <Row justify="end">
                                {
                                    isEditModal ?
                                        (
                                            <Button type="primary" htmlType="submit">
                                                Edit
                                            </Button>
                                        )
                                        :
                                        (
                                            <Button type="primary" htmlType="submit">
                                                Clone
                                            </Button>
                                        )
                                }

                            </Row>
                        </Form.Item>
                    </Form>

                </div>
            </Modal>
        </ConfigProvider>
    );
}

export default EditTaskModal;