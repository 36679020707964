import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Table, Tabs } from "antd";
import ServiceBuilds from "../../services/builds";
import './HistoryPage.css'

const { TabPane } = Tabs;

const HistoryTable = () => {
    const { id, key } = useParams();
    const [history, setHistory] = useState([]);

    useEffect(() => {
        if (id) {
            fetchHistory('build', id);
        } else if (key) {
            fetchHistory('task', key);
        }
    }, [id, key]);

    const fetchHistory = async (type, value) => {
        try {
            const response = await ServiceBuilds.getHistory(type, value);
            console.log("response", response.data);
            setHistory(response.data);
        } catch (error) {
            console.error("Error fetching history:", error);
        }
    };

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleString();
    };

    const credentialsColumns = [
        {
            title: "Username",
            dataIndex: "username",
            key: "username",
            width: 100
        },
        {
            title: "Change Timestamp",
            dataIndex: "change_timestamp",
            key: "change_timestamp",
            render: (text) => formatTimestamp(text),
            width: 100
        },
        {
            title: "Credentials (Previous)",
            dataIndex: "credentials_previous",
            key: "credentials_previous",
            render: (text) => <div dangerouslySetInnerHTML={{ __html: text }}></div>,
            width: 500
        },
        {
            title: "Credentials (Current)",
            dataIndex: "credentials_current",
            key: "credentials_current",
            render: (text) => <div dangerouslySetInnerHTML={{ __html: text }}></div>,
            width: 500
        },
    ];

    const summaryColumns = [
        {
            title: "Username",
            dataIndex: "username",
            key: "username",
            width: 100
        },
        {
            title: "Change Timestamp",
            dataIndex: "change_timestamp",
            key: "change_timestamp",
            render: (text) => formatTimestamp(text),
            width: 100
        },
        {
            title: "Summary (Previous)",
            dataIndex: "summary_previous",
            key: "summary_previous",
            render: (text) => <div dangerouslySetInnerHTML={{ __html: text }}></div>,
            width: 500
        },
        {
            title: "Summary (Current)",
            dataIndex: "summary_current",
            key: "summary_current",
            render: (text) => <div dangerouslySetInnerHTML={{ __html: text }}></div>,
            width: 500
        },
    ];

    const documentsColumns = [
        {
            title: "Username",
            dataIndex: "username",
            key: "username",
            width: 100
        },
        {
            title: "Change Timestamp",
            dataIndex: "change_timestamp",
            key: "change_timestamp",
            render: (text) => formatTimestamp(text),
            width: 100
        },
        {
            title: "Documents (Previous)",
            dataIndex: "documents_previous",
            key: "documents_previous",
            render: (text) => <div dangerouslySetInnerHTML={{ __html: text }}></div>,
            width: 500
        },
        {
            title: "Documents (Current)",
            dataIndex: "documents_current",
            key: "documents_current",
            render: (text) => <div dangerouslySetInnerHTML={{ __html: text }}></div>,
            width: 500
        },
    ];

    const credentialsData = history.filter(
        (item) =>
            item.credentials_previous &&
            item.credentials_current &&
            item.credentials_previous !== item.credentials_current
    );

    const summaryData = history.filter(
        (item) =>
            item.summary_previous &&
            item.summary_current &&
            item.summary_previous !== item.summary_current
    );

    const documentsData = history.filter(
        (item) =>
            item.documents_previous &&
            item.documents_current &&
            item.documents_previous !== item.documents_current
    );

    return (
        <div style={{ margin: '8px' }}>
            <h2>{history[0]?.sow_name} {history[0]?.summary}</h2>
            <Tabs defaultActiveKey="1" type="line">
                <TabPane tab="Credentials Changes" key="1">
                    <Table
                        columns={credentialsColumns}
                        dataSource={credentialsData}
                        rowKey="id"
                        pagination={false}
                        bordered
                        scroll={{
                            y: '75vh'
                        }}
                    />
                </TabPane>

                <TabPane tab="Summary Changes" key="2">
                    <Table
                        columns={summaryColumns}
                        dataSource={summaryData}
                        rowKey="id"
                        pagination={false}
                        bordered
                        scroll={{
                            y: '75vh'
                        }}
                    />
                </TabPane>

                <TabPane tab="Documents Changes" key="3">
                    <Table
                        columns={documentsColumns}
                        dataSource={documentsData}
                        rowKey="id"
                        pagination={false}
                        bordered
                        scroll={{
                            y: '75vh'
                        }}
                    />
                </TabPane>
            </Tabs>
        </div>
    );
};

export default HistoryTable;
