import React, { useEffect, useState } from "react";
import { Button, Col, ConfigProvider, DatePicker, Form, Input, Modal, Row, Space, InputNumber, Select } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import './CreateModal.css'
import moment from "moment";
import 'moment/locale/en-gb';
import { useUser } from '../../UserContext';

const { Option } = Select;

dayjs.extend(customParseFormat);

function CreateModal(props) {
    const { user, loading } = useUser();

    const [form] = Form.useForm();
    const [tasks, setTasks] = useState(props.tasks);
    const [selectedTaskStatus, setSelectedTicketStatus] = useState(null);
    const [selectedTaskKey, setSelectedTicketKey] = useState(null);
    const [activityOptions, setActivityOptions] = useState([]);

    useEffect(() => {
        setTasks(props.tasks);
        if (props.taskKey) {
            const selectedTask = props.tasks.find((task) => task.key === props.taskKey);
            const newAssignmentsOptions = [];
            console.log(selectedTask);
            if (selectedTask) {
                for (const assignment of selectedTask.activities) {
                    newAssignmentsOptions.push(
                        <Option key={assignment.id} value={assignment.id}>
                            {assignment.name}
                        </Option>
                    );
                }
                setSelectedTicketStatus(selectedTask.status);
                form.setFieldsValue({
                    createdDate: dayjs(new Date(props.date)),
                    task: props.taskKey,
                    timeSpentHours: 0.25
                });
            }
            setActivityOptions(newAssignmentsOptions);
        } else {
            form.setFieldsValue({
                createdDate: dayjs(new Date(props.date)),
                timeSpentHours: 0.25
            });
        }
    }, [form, tasks, props.isOpen]);

    const formRef = React.useRef(null);
    const dateFormat = 'YYYY-MM-DD';

    const handleCloseModal = () => {
        props.isOpenChange(false);
        formRef.current?.resetFields(['timeSpentHours', 'comment', 'task', 'activity']);
        setActivityOptions([]);
    };

    const handleSelect = (value) => {
        const selectedTask = tasks.find((task) => task.key === value);
        setSelectedTicketKey(selectedTask ? selectedTask.key : null);
        setSelectedTicketStatus(selectedTask ? selectedTask.status : null);
        const newAssignmentsOptions = [];
        if (selectedTask) {
            for (const assignment of selectedTask.activities) {
                newAssignmentsOptions.push(
                    <Option key={assignment.id} value={assignment.id}>
                        {assignment.name}
                    </Option>
                );
            }
        }
        setActivityOptions(newAssignmentsOptions);
        formRef.current?.resetFields(['activity']);
    };

    const onFinish = (values) => {
        values.createdDate = moment(new Date(values.createdDate)).format('YYYY-MM-DD');
        if (selectedTaskStatus === 'Done' || selectedTaskStatus === 'To Do') {
            Modal.warning({
                title: 'Task is not available.',
                content: 'The status does not correspond to reality.',
            });
            formRef.current?.resetFields(['task', 'activity']);
        } else {
            props.onData(values);
            formRef.current?.resetFields(['timeSpentHours', 'comment', 'task', 'activity', 'createdDate']);
            handleCloseModal();
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        // formRef.current?.resetFields(['timeSpentHours', 'comment']);
    };

    function handleSearch(value) { }

    return (
        <ConfigProvider
            theme={{
                token: {
                    fontFamily: 'Inter'
                },
            }}
        >
            <Modal
                open={props.isOpen}
                width={450}
                onCancel={handleCloseModal}
                footer={[]}
            >
                <div className="createHeader">
                    <div>
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                    </div>
                    <div className="createTitle">
                        Create Log
                    </div>
                </div>
                <div>
                    <Form
                        name="basic"
                        layout="vertical"
                        ref={formRef}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        form={form}
                    >
                        <Row>
                            <Space>
                                <Col>
                                    <Form.Item
                                        label="Task"
                                        name="task"
                                        rules={[{ required: true, message: 'Please select a task' }]}
                                    >
                                        <Select
                                            showSearch
                                            onSearch={handleSearch}
                                            onSelect={handleSelect}
                                            style={{ width: 200 }}
                                            placeholder="Select..."
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {tasks.map((task) => (
                                                <Option key={task.key} value={task.key}>
                                                    {task.summary}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col>
                                    <Form.Item
                                        label="Activity"
                                        name="activity"
                                        rules={[{ required: true, message: 'Please select an activity' }]}
                                    >
                                        <Select
                                            showSearch
                                            onSearch={handleSearch}
                                            style={{ width: 195 }}
                                            placeholder="Select..."
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {activityOptions}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Space>
                        </Row>

                        <Row gutter={8}>
                            <Col span={9}>
                                <Form.Item
                                    label="Date"
                                    name="createdDate"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select a date',
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        format={dateFormat}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={9}>
                                <Form.Item
                                    label="Duration"
                                    name="timeSpentHours"
                                    required={true}
                                    rules={[
                                        {
                                            validator: (rule, value) => {
                                                if (value <= 0) {
                                                    return Promise.reject('Duration must be a positive number');
                                                }
                                                if (!/^\d+(\.\d{1,2})?$/.test(value)) {
                                                    return Promise.reject('Duration must be a number with up to 2 decimal places');
                                                }
                                                return Promise.resolve();
                                            },
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        step={0.25}
                                        maxLength={8}
                                        parser={(value) => value.replace(',', '.')}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item
                            label="Comment"
                            name="comment"
                            rules={[
                                {
                                    validator: (rule, value) => {
                                        if (/^\s*$/.test(value)) {
                                            return Promise.reject('Comment cannot consist of only spaces');
                                        } else if (value.length < 15) {
                                            return Promise.reject('Comment must be at least 15 characters long');
                                        }
                                        return Promise.resolve();
                                    },
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item>
                            <Row justify="end">
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Row>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </ConfigProvider >
    );
}

export default CreateModal;